import React, { useEffect, useState } from 'react';
import Header from '../../components/header/Header';
import Menu from '../../components/menu/Menu';
import IUser, { Role } from '../../services/User/User.interface';
import { Row, Col, Button, FormSelect } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as Lang from '../../i18n/constants';
import Loader from '../../components/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IZone from '../../services/Zone/Zone.interface';
import * as Zone from '../../services/Zone/Zone';
import IPanel from '../../services/Panel/Panel.interface';
import * as Panel from '../../services/Panel/Panel';
import PanelModal from './components/PanelModal';
import PanelsTable from './components/PanelsTable';
import ICamera from '../../services/Camera/Camera.interface';
import * as Camera from '../../services/Camera/Camera';
import { useNavigate } from 'react-router-dom';
import * as URL from '../../routes/URL';

interface PanelsLayoutProps {
    user?: IUser;
}

const PanelsLayout = ({ user }: PanelsLayoutProps): JSX.Element => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [zones, setZones] = useState<IZone[]>([]);
    const [zoneSelected, setZoneSelected] = useState<string>('');
    const [panels, setPanels] = useState<IPanel[]>([]);
    const [showPanelModal, setShowPanelModal] = useState(false);
    const [panelModal, setPanelModal] = useState<IPanel | false>();
    const [fetchPanels, setFetchPanels] = useState(true);
    const [cameras, setCameras] = useState<ICamera[]>([]);

    const triggerPanelFetch = () => setFetchPanels((t) => !t);

    const handleOpenPanelModal = (
        event: React.MouseEvent<HTMLButtonElement>
    ): void => {
        const button: HTMLButtonElement = event.currentTarget;

        const id = button.value;

        if (id) {
            const panel = panels.find((panel) => panel._id === id);
            setPanelModal(panel);
        } else setPanelModal(undefined);

        setShowPanelModal(true);
    };

    const handleClosePanelModal = (): void => {
        setPanelModal(false);
        setShowPanelModal(false);
    };

    const handleChange = (
        event: React.ChangeEvent<HTMLSelectElement>
    ): void => {
        setZoneSelected(event.target.value);
        triggerPanelFetch();
    };

    useEffect(() => {
        if (user?.role !== Role.admin) navigate(URL.BASE);
    }, []);

    useEffect(() => {
        const load = async () => {
            const zones = await Zone.getZones();

            if (zones && zones.length) {
                setZones(zones);

                if (!zoneSelected && zones[0]._id)
                    setZoneSelected(zones[0]._id);

                const zoneId = zoneSelected || zones[0]._id || '';

                const cameras = await Camera.getCameras(zoneId);

                if (cameras) setCameras(cameras);

                const panels = await Panel.getPanels(zoneId);

                if (panels) setPanels(panels);
            }
            setLoading(false);
        };

        load();
    }, [fetchPanels]);

    return (
        <>
            <Header user={user} />
            <Row className="h-100">
                <Col xs="3" xl="2" className="p-0 sidebar d-none d-xl-block">
                    {user ? (
                        <Menu currentPage={t(Lang.PANELS)} user={user} />
                    ) : (
                        ''
                    )}
                </Col>
                <Col xs="12" xl="10" className="p-3 pe-xl-4">
                    <Row>
                        {zones.length ? (
                            <Col xs="12" xl="4">
                                <FormSelect onChange={handleChange}>
                                    {zones.map((zone) => {
                                        return (
                                            <option
                                                key={zone._id}
                                                value={zone._id}
                                            >
                                                {zone.name}
                                            </option>
                                        );
                                    })}
                                </FormSelect>
                            </Col>
                        ) : (
                            ''
                        )}
                    </Row>
                    <Row>
                        <Col>
                            <Button
                                variant="link"
                                className="btn-add"
                                onClick={handleOpenPanelModal}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'plus']}
                                    size="2x"
                                    fixedWidth
                                />
                            </Button>
                            {zoneSelected ? (
                                <PanelModal
                                    show={showPanelModal}
                                    handleClose={handleClosePanelModal}
                                    reloadPanels={triggerPanelFetch}
                                    panel={panelModal}
                                    zoneId={zoneSelected}
                                    cameras={cameras}
                                />
                            ) : (
                                ''
                            )}
                        </Col>
                    </Row>
                    {panels.length ? (
                        <Row>
                            <Col>
                                <PanelsTable
                                    panels={panels}
                                    handleEditPanel={handleOpenPanelModal}
                                    reloadPanels={triggerPanelFetch}
                                    cameras={cameras}
                                />
                            </Col>
                        </Row>
                    ) : (
                        <Loader loading={loading} size={100} color="#0d6efd" />
                    )}
                </Col>
            </Row>
        </>
    );
};

export default PanelsLayout;
