import { useEffect, useState } from 'react';
import { Pagination } from 'react-bootstrap';

interface CustomPaginationProps {
    totalPages: number;
    currentPage: number;
    pageClicked: (pageNumber: number) => void;
}

const CustomPagination = ({
    totalPages,
    currentPage,
    pageClicked,
}: CustomPaginationProps): JSX.Element => {
    const [pageArray, setPageArray] = useState<Array<number | string>>([]);
    useEffect(() => {
        let pageArr: Array<number | string> = [];
        if (totalPages > 1) {
            if (totalPages <= 9) {
                for (let i = 1; i <= totalPages; i++) pageArr.push(i);
            } else {
                if (currentPage <= 5)
                    pageArr = [1, 2, 3, 4, 5, 6, 7, 8, '', totalPages];
                else if (totalPages - currentPage <= 4)
                    pageArr = [
                        1,
                        '',
                        totalPages - 7,
                        totalPages - 6,
                        totalPages - 5,
                        totalPages - 4,
                        totalPages - 3,
                        totalPages - 2,
                        totalPages - 1,
                        totalPages,
                    ];
                else
                    pageArr = [
                        1,
                        '',
                        currentPage - 3,
                        currentPage - 2,
                        currentPage - 1,
                        currentPage,
                        currentPage + 1,
                        currentPage + 2,
                        currentPage + 3,
                        '',
                        totalPages,
                    ];
            }
        }
        setPageArray(pageArr);
    }, [totalPages]);

    return (
        <Pagination>
            {pageArray.map((page, index) => {
                const toReturn = [];

                if (index === 0) {
                    toReturn.push(
                        <Pagination.First
                            key={'firstPage'}
                            onClick={
                                currentPage === 1
                                    ? () => {
                                          /* */
                                      }
                                    : () => pageClicked(1)
                            }
                        />
                    );

                    toReturn.push(
                        <Pagination.Prev
                            key={'prevPage'}
                            onClick={
                                currentPage === 1
                                    ? () => {
                                          /* */
                                      }
                                    : () => pageClicked(currentPage - 1)
                            }
                        />
                    );
                }

                if (page === '')
                    toReturn.push(<Pagination.Ellipsis key={index} />);
                else
                    toReturn.push(
                        <Pagination.Item
                            key={index}
                            active={currentPage === page}
                            onClick={
                                currentPage === page
                                    ? () => {
                                          /* */
                                      }
                                    : () => {
                                          pageClicked(page as number);
                                      }
                            }
                        >
                            {page}
                        </Pagination.Item>
                    );

                if (index === pageArray.length - 1) {
                    toReturn.push(
                        <Pagination.Next
                            key={'nextPage'}
                            onClick={
                                currentPage === page
                                    ? () => {
                                          /* */
                                      }
                                    : () => {
                                          pageClicked(currentPage + 1);
                                      }
                            }
                        />
                    );

                    toReturn.push(
                        <Pagination.Last
                            key={'lastPage'}
                            onClick={
                                currentPage === page
                                    ? () => {
                                          /* */
                                      }
                                    : () => {
                                          pageClicked(page as number);
                                      }
                            }
                        />
                    );
                }

                return toReturn;
            })}
        </Pagination>
    );
};

export default CustomPagination;
