import React from 'react';
import { Modal, Button, Form, InputGroup, FormControl } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import * as Lang from '../../../i18n/constants';
import * as Auth from '../../../services/Authentication';
import { toast } from 'react-toastify';

interface RecoveryProps {
    show: boolean;
    handleClose: () => void;
}

interface RecoveryFormElements extends HTMLFormControlsCollection {
    recoveryUsername: HTMLInputElement;
}

interface RecoveryFormElement extends HTMLFormElement {
    readonly elements: RecoveryFormElements;
}

const RecoveryModal = ({ show, handleClose }: RecoveryProps): JSX.Element => {
    const { t } = useTranslation();

    const handleRecoverySubmit = async (
        event: React.FormEvent<RecoveryFormElement>
    ): Promise<void> => {
        event.preventDefault();
        const username = event.currentTarget.elements.recoveryUsername.value;
        const userExists = await Auth.startRecovery(username);

        if (userExists) toast.success(t(Lang.RECOVERY_EMAIL_SENT));
        else toast.error(t(Lang.USER_ACCOUNT_NOT_FOUND_OR_BLOCKED));

        handleClose();
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton className="border-0">
                <Modal.Title>{t(Lang.FORGOT_PASSWORD)}</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleRecoverySubmit}>
                <Modal.Body>
                    <p>{t(Lang.MESSAGE_RECOVERY)}</p>

                    <InputGroup className="mb-3">
                        <InputGroup.Text id="recoveryUsername">
                            <FontAwesomeIcon
                                icon={['fas', 'user']}
                                viewBox="0 0 512 512"
                            />
                        </InputGroup.Text>
                        <FormControl
                            name="recoveryUsername"
                            placeholder={t(Lang.USERNAME_OR_EMAIL)}
                            aria-label="Username"
                            aria-describedby="username"
                            required
                        />
                    </InputGroup>
                </Modal.Body>
                <Modal.Footer className="border-0">
                    <Button variant="secondary" onClick={handleClose}>
                        {t(Lang.CANCEL)}
                    </Button>
                    <Button type="submit" variant="primary">
                        {t(Lang.SEND)}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default RecoveryModal;
