import React, { useEffect, useState } from 'react';
import Header from '../../components/header/Header';
import Menu from '../../components/menu/Menu';
import IUser from '../../services/User/User.interface';
import { Row, Col, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as Lang from '../../i18n/constants';
import Loader from '../../components/Loader';
import IWarning from '../../services/Warning/Warning.interface';
import * as Warning from '../../services/Warning/Warning';
import ICamera from '../../services/Camera/Camera.interface';
import * as Camera from '../../services/Camera/Camera';
import IRaspberry from '../../services/Raspberry/Raspberry.interface';
import * as Raspberry from '../../services/Raspberry/Raspberry';
import IPanel from '../../services/Panel/Panel.interface';
import * as Panel from '../../services/Panel/Panel';
import ISensor from '../../services/Sensor/Sensor.interface';
import * as Sensor from '../../services/Sensor/Sensor';
import WarningsTable from './components/WarningsTable';
import Pagination from '../../components/CustomPagination';

interface WarningsLayoutProps {
    user?: IUser;
}

const WarningsLayout = ({ user }: WarningsLayoutProps): JSX.Element => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [warnings, setWarnings] = useState<IWarning[]>([]);
    const [totalWarnings, setTotalWarnings] = useState<number>();
    const [fetchWarnings, setFetchWarnings] = useState(true);
    const [cameras, setCameras] = useState<ICamera[]>([]);
    const [raspberries, setRaspberries] = useState<IRaspberry[]>([]);
    const [panels, setPanels] = useState<IPanel[]>([]);
    const [sensors, setSensors] = useState<ISensor[]>([]);
    const [limit, setLimit] = useState(50);
    const [page, setPage] = useState(1);

    const triggerWarningFetch = () => setFetchWarnings((t) => !t);

    const handleChangeLimit = (
        event: React.ChangeEvent<HTMLSelectElement>
    ): void => {
        setLimit(parseInt(event.target.value));
        setPage(1);
        triggerWarningFetch();
    };

    const handleClickPage = (nPage: number) => {
        setPage(nPage);
        triggerWarningFetch();
    };

    useEffect(() => {
        const load = async () => {
            const warningsCount = await Warning.getWarningsCount();

            if (warningsCount) setTotalWarnings(warningsCount);

            const warnings = await Warning.getWarnings(undefined, page, limit);

            if (warnings) setWarnings(warnings);

            const cameras = await Camera.getCameras();

            if (cameras) setCameras(cameras);

            /*const raspberries = await Raspberry.getRaspberries();

            if (raspberries) setRaspberries(raspberries);*/

            const panels = await Panel.getPanels();

            if (panels) setPanels(panels);

            const sensors = await Sensor.getSensors();

            if (sensors) setSensors(sensors);

            setLoading(false);
        };
        load();
    }, [fetchWarnings]);

    return (
        <>
            <Header user={user} />
            <Row className="h-100">
                <Col xs="3" xl="2" className="p-0 sidebar d-none d-xl-block">
                    {user ? (
                        <Menu currentPage={t(Lang.WARNINGS)} user={user} />
                    ) : (
                        ''
                    )}
                </Col>
                <Col xs="12" xl="10" className="p-3 pe-xl-4">
                    {warnings.length ? (
                        <>
                            <Row className="mb-3">
                                <Col>
                                    <WarningsTable
                                        warnings={warnings}
                                        cameras={cameras}
                                        raspberries={raspberries}
                                        panels={panels}
                                        sensors={sensors}
                                        reloadWarnings={triggerWarningFetch}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="6" xl="2" className="mb-3">
                                    <Form.Label>
                                        {t(Lang.ITEMS_PER_PAGE, {
                                            item: t(Lang.REGISTRY),
                                        })}
                                    </Form.Label>
                                </Col>
                                <Col xs="6" xl="1">
                                    <Form.Select
                                        defaultValue={limit}
                                        onChange={handleChangeLimit}
                                    >
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="50">50</option>
                                    </Form.Select>
                                </Col>
                                {totalWarnings && totalWarnings > limit ? (
                                    <Col xs="12" xl="9">
                                        <Pagination
                                            totalPages={Math.ceil(
                                                totalWarnings / limit
                                            )}
                                            currentPage={page}
                                            pageClicked={handleClickPage}
                                        />
                                    </Col>
                                ) : (
                                    ''
                                )}
                            </Row>
                        </>
                    ) : (
                        <Loader loading={loading} size={100} color="#0d6efd" />
                    )}
                </Col>
            </Row>
        </>
    );
};

export default WarningsLayout;
