import axios, { AxiosResponse } from 'axios';
import * as Auth from '../Authentication';
import baseURL from '../BaseURL';
import IPanel from './Panel.interface';

const apiURL = `${baseURL}/panels`;

export const createPanel = async (panel: IPanel): Promise<IPanel | false> => {
    try {
        const response: AxiosResponse = await axios.post(apiURL, panel, {
            headers: Auth.authHeader(),
        });

        if (response.data) {
            const createdPanel: IPanel = response.data;
            return createdPanel;
        } else return false;
    } catch (error) {
        console.error(error);
        return false;
    }
};

export const getPanels = async (zone?: string): Promise<IPanel[] | false> => {
    try {
        const response: AxiosResponse = await axios.get(apiURL, {
            headers: Auth.authHeader(),
            params: { zone },
        });

        if (response.data) {
            const panels: IPanel[] = response.data;
            return panels;
        } else return false;
    } catch (error) {
        console.error(error);
        return false;
    }
};

export const getPanel = async (id: string): Promise<IPanel | false> => {
    try {
        const response: AxiosResponse = await axios.get(`${apiURL}/${id}`, {
            headers: Auth.authHeader(),
        });

        if (response.data) {
            const panel: IPanel = response.data;
            return panel;
        } else return false;
    } catch (error) {
        console.error(error);
        return false;
    }
};

export const updatePanel = async (panel: IPanel): Promise<IPanel | false> => {
    try {
        const response: AxiosResponse = await axios.put(
            `${apiURL}/${panel._id}`,
            panel,
            { headers: Auth.authHeader() }
        );

        if (response.data) {
            const updatedPanel: IPanel = response.data;
            return updatedPanel;
        } else return false;
    } catch (error) {
        console.error(error);
        return false;
    }
};

export const deletePanel = async (id: string): Promise<IPanel | false> => {
    try {
        const response: AxiosResponse = await axios.delete(`${apiURL}/${id}`, {
            headers: Auth.authHeader(),
        });

        if (response.data) {
            const deletedPanel: IPanel = response.data;
            return deletedPanel;
        } else return false;
    } catch (error) {
        console.error(error);
        return false;
    }
};
