import { CSVLink } from 'react-csv';
import IEvent from '../../../services/Event/Event.interface';
import * as Lang from '../../../i18n/constants';
import { useTranslation } from 'react-i18next';
import ICamera from '../../../services/Camera/Camera.interface';
import moment from 'moment';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ICSV {
    [key: string]: string | undefined;
}

interface SanctionsExportCSVProps {
    events: IEvent[];
    cameras: ICamera[];
}

const SanctionsExportCSV = ({
    events,
    cameras,
}: SanctionsExportCSVProps): JSX.Element => {
    const { t } = useTranslation();

    const langObj: { [key: string]: string } = {};

    for (const key of Object.keys(Lang)) langObj[key] = key;

    const headers = [
        { label: 'plate', key: 'plate' },
        { label: 'camera', key: 'camera' },
        { label: 'direction', key: 'direction' },
        { label: 'date', key: 'createdAt' },
        { label: 'sentSanctionDate', key: 'sentSanctionDate' },
        { label: 'environmentLabel', key: 'environmentLabel' },
        { label: 'restrictionMode', key: 'restrictionMode' },
        { label: 'imagePlateURL', key: 'imagePlateURL' },
        { label: 'imageContextURL', key: 'imageContextURL' },
        { label: 'imageOCRURL', key: 'imageOCRURL' },
    ];

    const filename = `sanctions_${moment(new Date()).format(
        'DD-MM-YYYY HH:mm'
    )}.csv`;

    const data = [];

    for (const event of events) {
        const camera = cameras.filter((camera) => camera._id === event.camera);

        const dataEvent: ICSV = {
            plate: event.plate,
            camera: camera.length ? camera[0].name : '',
            direction: t(langObj[event.direction.toUpperCase()]),
            date: moment(event.createdAt).format('DD/MM/YYYY HH:mm:ss'),
            sentSanctionDate: moment(event.sentSanctionDate).format(
                'DD/MM/YYYY HH:mm:ss'
            ),
            environmentLabel: event.environmentLabel
                ? t(langObj[event.environmentLabel?.toUpperCase()])
                : event.environmentLabel,
            restrictionMode: event.restrictionMode,
            imagePlateURL: event.imagePlateURL,
            imageContextURL: event.imageContextURL,
            imageOCRURL: event.imageOCRURL,
        };

        data.push(dataEvent);
    }

    return (
        <CSVLink
            headers={headers}
            data={data}
            filename={filename}
            separator=";"
        >
            <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip>{t(Lang.EXPORT_CSV_FILE)}</Tooltip>}
            >
                <Button variant="link" className="btn-add">
                    <FontAwesomeIcon
                        icon={['fas', 'file-csv']}
                        size="2x"
                        fixedWidth
                    />
                </Button>
            </OverlayTrigger>
        </CSVLink>
    );
};

export default SanctionsExportCSV;
