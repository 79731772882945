import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import TranslationEN from './translations/en.json';
import TranslationES from './translations/es.json';
import TranslationVA from './translations/va.json';

i18n.use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources: {
            en: {
                translation: TranslationEN,
            },
            es: {
                translation: TranslationES,
            },
            va: {
                translation: TranslationVA,
            },
        },
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
    });
