import { Table, Row, Col, Button, FormCheck } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as Lang from '../../../i18n/constants';
import IUser from '../../../services/User/User.interface';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as User from '../../../services/User/User';
import { toast } from 'react-toastify';
import { useState } from 'react';
import Loader from '../../../components/Loader';

interface UsersTableProps {
    users: IUser[];
    handleEditUser: (event: React.MouseEvent<HTMLButtonElement>) => void;
    reloadUsers: () => void;
}

const UsersTable = ({
    users,
    handleEditUser,
    reloadUsers,
}: UsersTableProps): JSX.Element => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState('');

    const handleDeleteUser = async (
        event: React.MouseEvent<HTMLButtonElement>
    ): Promise<void> => {
        event.preventDefault();
        const button: HTMLButtonElement = event.currentTarget;

        const id = button.value;

        setLoading(id);

        const deletedUser = await User.deleteUser(id);

        if (deletedUser) {
            setLoading('');
            toast.success(
                t(Lang.USER_DELETED_SUCCESSFULLY, { user: deletedUser })
            );
            reloadUsers();
        } else {
            setLoading('');
            toast.error(t(Lang.ERROR_DELETING_USER, { user: deletedUser }));
        }
    };

    const handleChange = async (
        event: React.ChangeEvent<HTMLInputElement>
    ): Promise<void> => {
        const user = await User.getUser(event.target.value);

        if (!user) return;

        if (event.target.checked) user.loginAttempts = 0;
        else user.loginAttempts = 6;

        const updatedUser = await User.updateUser({
            _id: user._id,
            username: user.username,
            email: user.email,
            firstname: user.firstname,
            role: user.role,
            twoFactorEnabled: user.twoFactorEnabled,
            loginAttempts: user.loginAttempts,
        });

        if (updatedUser) {
            if (event.target.checked)
                toast.success(t(Lang.USER_UNLOCKED_SUCCESSFULLY, { user }));
            else toast.success(t(Lang.USER_LOCKED_SUCCESSFULLY, { user }));

            reloadUsers();
        } else {
            if (event.target.checked)
                toast.error(t(Lang.ERROR_UNLOCKING_USER, { user }));
            else toast.error(t(Lang.ERROR_LOCKING_USER, { user }));
        }
    };

    return (
        <Row>
            <Col>
                <Table responsive striped hover variant="primary">
                    <thead>
                        <tr>
                            <th></th>
                            <th>{t(Lang.USERNAME)}</th>
                            <th>{t(Lang.EMAIL)}</th>
                            <th>{t(Lang.FIRSTNAME)}</th>
                            <th>{t(Lang.LASTNAME)}</th>
                            <th>{t(Lang.ROLE)}</th>
                            <th>{t(Lang.PHONE)}</th>
                            <th>{t(Lang.ACCOUNT_STATUS)}</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((user: IUser) => {
                            return (
                                <tr key={user._id}>
                                    <td></td>
                                    <td>{user.username}</td>
                                    <td>{user.email}</td>
                                    <td>{user.firstname}</td>
                                    <td>{user.lastname}</td>
                                    <td>{user.role}</td>
                                    <td>{user.phone}</td>
                                    <td>
                                        {user.loginAttempts !== undefined ? (
                                            <FormCheck
                                                type="switch"
                                                value={user._id}
                                                checked={user.loginAttempts > 5}
                                                onChange={handleChange}
                                                className={
                                                    user.loginAttempts > 5
                                                        ? 'danger'
                                                        : 'success'
                                                }
                                                label={
                                                    user.loginAttempts > 5
                                                        ? t(Lang.LOCKED)
                                                        : t(Lang.UNLOCKED)
                                                }
                                            />
                                        ) : (
                                            ''
                                        )}
                                    </td>
                                    <td>
                                        <Button
                                            variant="link"
                                            value={user._id}
                                            onClick={handleEditUser}
                                        >
                                            <FontAwesomeIcon
                                                icon={['fas', 'pen-to-square']}
                                                size="2x"
                                                fixedWidth
                                            />
                                        </Button>
                                    </td>
                                    <td>
                                        <Button
                                            variant="link"
                                            className="btn-delete"
                                            value={user._id}
                                            onClick={handleDeleteUser}
                                        >
                                            {loading === user._id ? (
                                                <Loader
                                                    loading={true}
                                                    size={25}
                                                    color="#dc3545"
                                                />
                                            ) : (
                                                <FontAwesomeIcon
                                                    icon={['fas', 'trash-can']}
                                                    size="2x"
                                                    fixedWidth
                                                />
                                            )}
                                        </Button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </Col>
        </Row>
    );
};

export default UsersTable;
