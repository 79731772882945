import {
    Modal,
    Form,
    InputGroup,
    FormControl,
    Tooltip,
    OverlayTrigger,
    Button,
    Row,
    Col,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import * as Lang from '../../../i18n/constants';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import ErrorField from '../../../components/form/ErrorField';
import Loader from '../../../components/Loader';
import { toast } from 'react-toastify';
import IZone from '../../../services/Zone/Zone.interface';
import * as Zone from '../../../services/Zone/Zone';

interface ZoneModalProps {
    show: boolean;
    handleClose: () => void;
    reloadZones: () => void;
    zone?: IZone | false;
}

const ZoneModal = ({
    show,
    handleClose,
    reloadZones,
    zone,
}: ZoneModalProps): JSX.Element => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm<IZone>();

    const handleCreateZone = async (data: IZone): Promise<void> => {
        setLoading(true);

        const zone: IZone = {
            name: data.name,
            description: data.description,
            color: data.color,
            coordinates: data.coordinates,
            environmentalRestrictions: data.environmentalRestrictions,
        };

        const createdZone = await Zone.createZone(zone);

        if (createdZone) {
            toast.success(t(Lang.ZONE_CREATED_SUCCESSFULLY, { zone }));
            reloadZones();
        } else toast.error(t(Lang.ERROR_CREATING_ZONE, { zone }));

        setLoading(false);

        handleClose();
    };

    const handleUpdateZone = async (data: IZone): Promise<void> => {
        setLoading(true);

        const zone: IZone = {
            _id: data._id,
            name: data.name,
            description: data.description,
            color: data.color,
            coordinates: data.coordinates,
            environmentalRestrictions: data.environmentalRestrictions,
        };

        const updatedZone = await Zone.updateZone(zone);

        if (updatedZone) {
            toast.success(t(Lang.ZONE_UPDATED_SUCCESSFULLY, { zone }));
            reloadZones();
        } else toast.error(t(Lang.ERROR_UPDATING_ZONE, { zone }));

        setLoading(false);
        handleClose();
    };

    useEffect(() => {
        if (zone) reset(zone);
        else
            reset({
                _id: '',
                name: '',
                description: '',
                color: '#0000FF',
                coordinates: '',
            });
    }, [zone]);

    return (
        <Modal show={show} onHide={handleClose}>
            <Form
                onSubmit={
                    zone
                        ? handleSubmit(handleUpdateZone)
                        : handleSubmit(handleCreateZone)
                }
            >
                <Modal.Header closeButton className="border-0">
                    <Modal.Title>
                        {zone ? t(Lang.EDIT_ZONE, { zone }) : t(Lang.ADD_ZONE)}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        <small>{t(Lang.REQUIRED_MESSAGE)}</small>
                    </p>
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.NAME)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.name
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'font']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <FormControl
                            id="name"
                            type="text"
                            placeholder={'* ' + t(Lang.NAME)}
                            aria-label={t(Lang.NAME)}
                            aria-describedby={t(Lang.NAME)}
                            className={
                                errors.name ? 'border-2 border-danger' : ''
                            }
                            {...register('name', {
                                required: t(Lang.REQUIRED_FIELD, {
                                    field: t(Lang.NAME),
                                }) as string,
                                maxLength: {
                                    value: 128,
                                    message: t(Lang.MAX_LENGTH_FIELD, {
                                        field: t(Lang.NAME),
                                        value: 128,
                                    }),
                                },
                            })}
                        />
                    </InputGroup>
                    {errors.name && (
                        <ErrorField message={errors.name.message} />
                    )}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.DESCRIPTION)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.description
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'align-left']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <FormControl
                            id="description"
                            as="textarea"
                            rows={5}
                            placeholder={t(Lang.DESCRIPTION)}
                            aria-label={t(Lang.DESCRIPTION)}
                            aria-describedby={t(Lang.DESCRIPTION)}
                            className={
                                errors.description
                                    ? 'border-2 border-danger'
                                    : ''
                            }
                            {...register('description', {
                                maxLength: {
                                    value: 512,
                                    message: t(Lang.MAX_LENGTH_FIELD, {
                                        field: t(Lang.DESCRIPTION),
                                        value: 512,
                                    }),
                                },
                            })}
                        />
                    </InputGroup>
                    {errors.description && (
                        <ErrorField message={errors.description.message} />
                    )}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.COLOR)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.color
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'palette']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <FormControl
                            id="color"
                            type="color"
                            maxLength={16}
                            placeholder={t(Lang.COLOR)}
                            aria-label={t(Lang.COLOR)}
                            aria-describedby={t(Lang.COLOR)}
                            className={
                                errors.color ? 'border-2 border-danger' : ''
                            }
                            {...register('color', {
                                maxLength: {
                                    value: 16,
                                    message: t(Lang.MAX_LENGTH_FIELD, {
                                        field: t(Lang.COLOR),
                                        value: 16,
                                    }),
                                },
                            })}
                        />
                    </InputGroup>
                    {errors.color && (
                        <ErrorField message={errors.color.message} />
                    )}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.COORDINATES)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.color
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'location-crosshairs']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <FormControl
                            id="coordinates"
                            type="text"
                            maxLength={4096}
                            placeholder={t(Lang.COORDINATES)}
                            aria-label={t(Lang.COORDINATES)}
                            aria-describedby={t(Lang.COORDINATES)}
                            className={
                                errors.coordinates
                                    ? 'border-2 border-danger'
                                    : ''
                            }
                            {...register('coordinates', {
                                maxLength: {
                                    value: 4096,
                                    message: t(Lang.MAX_LENGTH_FIELD, {
                                        field: t(Lang.COORDINATES),
                                        value: 4096,
                                    }),
                                },
                            })}
                        />
                    </InputGroup>
                    {errors.coordinates && (
                        <ErrorField message={errors.coordinates.message} />
                    )}
                    <Form.Group className="mb-3">
                        <Form.Label>
                            {t(Lang.RESTRICTED_ENVIRONMENTAL_LABELS)}
                        </Form.Label>
                        <Row className="border rounded py-3 mx-0">
                            <Col sm="6" xs="12">
                                <Form.Check
                                    type="checkbox"
                                    id="zeroLabel"
                                    label={t(Lang.ZERO)}
                                    {...register(
                                        'environmentalRestrictions.zero'
                                    )}
                                />
                                <Form.Check
                                    type="checkbox"
                                    id="EcoLabel"
                                    label={t(Lang.ECO)}
                                    {...register(
                                        'environmentalRestrictions.eco'
                                    )}
                                />
                                <Form.Check
                                    type="checkbox"
                                    id="BLabel"
                                    label={t(Lang.B)}
                                    {...register('environmentalRestrictions.b')}
                                />
                            </Col>
                            <Col sm="6" xs="12">
                                <Form.Check
                                    type="checkbox"
                                    id="CLabel"
                                    label={t(Lang.C)}
                                    {...register('environmentalRestrictions.c')}
                                />
                                <Form.Check
                                    type="checkbox"
                                    id="withoutLabel"
                                    label={t(Lang.WITHOUT)}
                                    {...register(
                                        'environmentalRestrictions.without'
                                    )}
                                />
                                <Form.Check
                                    type="checkbox"
                                    id="foreignLabel"
                                    label={t(Lang.FOREIGN)}
                                    {...register(
                                        'environmentalRestrictions.foreign'
                                    )}
                                />
                            </Col>
                        </Row>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t(Lang.CANCEL)}
                    </Button>
                    <Button
                        type="submit"
                        variant="primary"
                        id="_id"
                        className="btn-modal"
                        {...register('_id')}
                    >
                        {loading ? (
                            <Loader
                                loading={loading}
                                size={25}
                                color="#FFFFFF"
                            />
                        ) : (
                            t(Lang.SAVE)
                        )}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default ZoneModal;
