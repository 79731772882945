import { Table, Row, Col, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as Lang from '../../../i18n/constants';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from '../../../components/Loader';
import ICamera from '../../../services/Camera/Camera.interface';
import * as Camera from '../../../services/Camera/Camera';

interface CamerasTableProps {
    cameras: ICamera[];
    handleEditCamera: (event: React.MouseEvent<HTMLButtonElement>) => void;
    reloadCameras: () => void;
}

const CamerasTable = ({
    cameras,
    handleEditCamera,
    reloadCameras,
}: CamerasTableProps): JSX.Element => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState('');

    const langObj: { [key: string]: string } = {};

    for (const key of Object.keys(Lang)) langObj[key] = key;

    const handleDeleteCamera = async (
        event: React.MouseEvent<HTMLButtonElement>
    ): Promise<void> => {
        event.preventDefault();
        const button: HTMLButtonElement = event.currentTarget;
        const id = button.value;

        setLoading(id);

        const deletedCamera = await Camera.deleteCamera(id);

        if (deletedCamera) {
            toast.success(
                t(Lang.CAMERA_DELETED_SUCCESSFULLY, { camera: deletedCamera })
            );
            reloadCameras();
        } else
            toast.error(
                t(Lang.ERROR_DELETING_CAMERA, { camera: deletedCamera })
            );
        setLoading('');
    };

    return (
        <Row>
            <Col>
                <Table responsive striped hover variant="primary">
                    <thead>
                        <tr>
                            <th></th>
                            <th>{t(Lang.NAME)}</th>
                            <th>{t(Lang.DESCRIPTION)}</th>
                            <th>{t(Lang.LOCATION)}</th>
                            <th>{t(Lang.COORDINATES)}</th>
                            <th>{t(Lang.DIRECTION)}</th>
                            <th>{t(Lang.DETECTION_TYPE)}</th>
                            <th>{t(Lang.MANUFACTURER)}</th>
                            <th>{t(Lang.MODEL)}</th>
                            <th>{t(Lang.HOST)}</th>
                            <th>{t(Lang.PORT)}</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {cameras.map((camera: ICamera) => {
                            return (
                                <tr key={camera._id}>
                                    <td></td>
                                    <td>{camera.name}</td>
                                    <td className="text-truncate">
                                        {camera.description}
                                    </td>
                                    <td>{camera.location}</td>
                                    <td>{camera.coordinates}</td>
                                    <td>
                                        {t(
                                            langObj[
                                                camera.direction.toUpperCase()
                                            ]
                                        )}
                                    </td>
                                    <td>
                                        {t(
                                            langObj[
                                                camera.detection.toUpperCase()
                                            ]
                                        )}
                                    </td>
                                    <td>{camera.manufacturer}</td>
                                    <td>{camera.model}</td>
                                    <td>{camera.host}</td>
                                    <td>{camera.port}</td>
                                    <td>
                                        <Button
                                            variant="link"
                                            value={camera._id}
                                            onClick={handleEditCamera}
                                        >
                                            <FontAwesomeIcon
                                                icon={['fas', 'pen-to-square']}
                                                size="2x"
                                                fixedWidth
                                            />
                                        </Button>
                                    </td>
                                    <td>
                                        <Button
                                            variant="link"
                                            className="btn-delete"
                                            value={camera._id}
                                            onClick={handleDeleteCamera}
                                        >
                                            {loading === camera._id ? (
                                                <Loader
                                                    loading={true}
                                                    size={25}
                                                    color="#dc3545"
                                                />
                                            ) : (
                                                <FontAwesomeIcon
                                                    icon={['fas', 'trash-can']}
                                                    size="2x"
                                                    fixedWidth
                                                />
                                            )}
                                        </Button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </Col>
        </Row>
    );
};

export default CamerasTable;
