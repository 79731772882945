import {
    Modal,
    Form,
    InputGroup,
    FormControl,
    Tooltip,
    OverlayTrigger,
    Button,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import * as Lang from '../../i18n/constants';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import ErrorField from '../form/ErrorField';
import Loader from '../Loader';
import { toast } from 'react-toastify';
import IList, { TypeList } from '../../services/List/List.interface';
import * as List from '../../services/List/List';
import IZone from '../../services/Zone/Zone.interface';
import IOption from '../../services/Option/Option.interface';

interface ListModalProps {
    show: boolean;
    handleClose: () => void;
    reloadLists: () => void;
    list?: IList | false;
    zones: IZone[];
    descriptions: IOption[];
    type: TypeList;
}

interface IListForm extends Omit<IList, 'emailRecipients'> {
    emailRecipients: string;
}

const ListModal = ({
    show,
    handleClose,
    reloadLists,
    list,
    zones,
    descriptions,
    type,
}: ListModalProps): JSX.Element => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm<IListForm>();

    const handleCreateList = async (data: IListForm): Promise<void> => {
        setLoading(true);

        const emailRecipients = data.emailRecipients
            .split(';')
            .map((email) => email.trim())
            .filter((email) => email !== '');

        const list: IList = {
            name: data.name,
            description: data.description,
            type,
            zones: data.zones,
            enabled: data.enabled,
            webAlert: data.webAlert,
            emailAlert: data.emailAlert,
        };

        if (emailRecipients[0] !== '') list.emailRecipients = emailRecipients;

        const createdList = await List.createList(list);

        if (createdList) {
            if (type === TypeList.white)
                toast.success(
                    t(Lang.WHITE_LIST_CREATED_SUCCESSFULLY, {
                        list: createdList,
                    })
                );
            else
                toast.success(
                    t(Lang.BLACK_LIST_CREATED_SUCCESSFULLY, {
                        list: createdList,
                    })
                );
            reloadLists();
        } else if (type === TypeList.white)
            toast.error(t(Lang.ERROR_CREATING_WHITE_LIST, { list }));
        else toast.error(t(Lang.ERROR_CREATING_BLACK_LIST, { list }));

        setLoading(false);
        handleClose();
    };

    const handleUpdateList = async (data: IListForm): Promise<void> => {
        setLoading(true);

        const emailRecipients = data.emailRecipients
            .split(';')
            .map((email) => email.trim());

        const list: IList = {
            _id: data._id,
            name: data.name,
            description: data.description,
            type,
            zones: data.zones,
            enabled: data.enabled,
            webAlert: data.webAlert,
            emailAlert: data.emailAlert,
        };

        if (emailRecipients[0] !== '') list.emailRecipients = emailRecipients;

        const updatedList = await List.updateList(list);

        if (updatedList) {
            if (type === TypeList.white)
                toast.success(
                    t(Lang.WHITE_LIST_UPDATED_SUCCESSFULLY, {
                        list: updatedList,
                    })
                );
            else
                toast.success(
                    t(Lang.BLACK_LIST_UPDATED_SUCCESSFULLY, {
                        list: updatedList,
                    })
                );
            reloadLists();
        } else if (type === TypeList.white)
            toast.error(t(Lang.ERROR_UPDATING_WHITE_LIST, { list }));
        else toast.error(t(Lang.ERROR_UPDATING_BLACK_LIST, { list }));

        setLoading(false);
        handleClose();
    };

    const checkName = async (value: string): Promise<boolean> => {
        if (value && !list) {
            const lists = await List.getLists();

            if (lists) {
                const foundList = lists.find((list) => list.name === value);

                if (foundList) return false;
            }
        }
        return true;
    };

    useEffect(() => {
        if (list) {
            const listForm: IListForm = {
                ...list,
                emailRecipients: list.emailRecipients?.join('; ') || '',
            };
            reset(listForm);
        } else
            reset({
                name: '',
                description: '',
                type,
                zones: [],
                enabled: false,
                webAlert: false,
                emailAlert: false,
                emailRecipients: '',
            });
    }, [list]);

    return (
        <Modal show={show} onHide={handleClose}>
            <Form
                onSubmit={
                    list
                        ? handleSubmit(handleUpdateList)
                        : handleSubmit(handleCreateList)
                }
            >
                <Modal.Header closeButton className="border-0">
                    <Modal.Title>
                        {list
                            ? type === TypeList.white
                                ? t(Lang.EDIT_WHITE_LIST, { list })
                                : t(Lang.EDIT_BLACK_LIST, { list })
                            : type === TypeList.white
                            ? t(Lang.ADD_WHITE_LIST)
                            : t(Lang.ADD_BLACK_LIST)}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        <small>{t(Lang.REQUIRED_MESSAGE)}</small>
                    </p>
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.NAME)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.name
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'font']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <FormControl
                            id="name"
                            type="text"
                            placeholder={'* ' + t(Lang.NAME)}
                            aria-label={t(Lang.NAME)}
                            aria-describedby={t(Lang.NAME)}
                            className={
                                errors.name ? 'border-2 border-danger' : ''
                            }
                            {...register('name', {
                                required: t(Lang.REQUIRED_FIELD, {
                                    field: t(Lang.NAME),
                                }) as string,
                                maxLength: {
                                    value: 128,
                                    message: t(Lang.MAX_LENGTH_FIELD, {
                                        field: t(Lang.NAME),
                                        value: 128,
                                    }),
                                },
                                validate: async (v) =>
                                    (await checkName(v)) ||
                                    (t(
                                        Lang.A_LIST_WITH_THIS_NAME_ALREADY_EXISTS
                                    ) as string),
                            })}
                        />
                    </InputGroup>
                    {errors.name && (
                        <ErrorField message={errors.name.message} />
                    )}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.DESCRIPTION)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.description
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'align-left']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <Form.Select
                            id="description"
                            className={
                                errors.description
                                    ? 'border-2 border-danger'
                                    : ''
                            }
                            {...register('description')}
                        >
                            {descriptions.map((description, index) => {
                                return (
                                    <option
                                        key={`description_${index}`}
                                        value={description.value}
                                    >
                                        {description.value}
                                    </option>
                                );
                            })}
                        </Form.Select>
                    </InputGroup>
                    {errors.description && (
                        <ErrorField message={errors.description.message} />
                    )}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.ZONES)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.zones
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'vector-square']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>

                        <Form.Select
                            id="zones"
                            multiple
                            className={
                                errors.zones ? 'border-2 border-danger' : ''
                            }
                            {...register('zones', {
                                required: t(Lang.REQUIRED_FIELD, {
                                    field: t(Lang.ZONES),
                                }) as string,
                            })}
                        >
                            {zones.map((zone: IZone) => {
                                return (
                                    <option key={zone._id} value={zone._id}>
                                        {zone.name}
                                    </option>
                                );
                            })}
                        </Form.Select>
                    </InputGroup>
                    <Form.Check
                        id="enabled"
                        type="checkbox"
                        label={t(Lang.ENABLED)}
                        inline
                        className="mb-3"
                        {...register('enabled')}
                    />
                    <Form.Check
                        id="webAlert"
                        type="checkbox"
                        label={t(Lang.WEB_ALERT)}
                        inline
                        className="mb-3"
                        {...register('webAlert')}
                    />
                    <Form.Check
                        id="emailAlert"
                        type="checkbox"
                        label={t(Lang.EMAIL_ALERT)}
                        inline
                        className="mb-3"
                        {...register('emailAlert')}
                    />
                    <InputGroup>
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                                <Tooltip>{t(Lang.EMAIL_RECIPIENTS)}</Tooltip>
                            }
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.emailRecipients
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'envelope']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <FormControl
                            id="emailRecipients"
                            type="text"
                            placeholder={t(Lang.EMAIL_RECIPIENTS)}
                            aria-label={t(Lang.EMAIL_RECIPIENTS)}
                            aria-describedby={t(Lang.EMAIL_RECIPIENTS)}
                            className={
                                errors.emailRecipients
                                    ? 'border-2 border-danger'
                                    : ''
                            }
                            {...register('emailRecipients')}
                        />
                    </InputGroup>
                    <Form.Text id="emailRecipientsHelp" muted className="mb-3">
                        {t(Lang.SEMICOLON_SEPARATED_EMAILS)}
                    </Form.Text>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t(Lang.CANCEL)}
                    </Button>
                    <Button
                        type="submit"
                        variant="primary"
                        id="_id"
                        className="btn-modal"
                        {...register('_id')}
                    >
                        {loading ? (
                            <Loader
                                loading={loading}
                                size={25}
                                color="#FFFFFF"
                            />
                        ) : (
                            t(Lang.SAVE)
                        )}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default ListModal;
