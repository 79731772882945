import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ErrorFieldProps {
    message: string | undefined;
}

const ErrorField = ({ message }: ErrorFieldProps): JSX.Element => {
    return (
        <p className="ms-3 text-danger">
            <FontAwesomeIcon
                icon={['fas', 'triangle-exclamation']}
                className="pe-1"
            />
            <span>{message}</span>
        </p>
    );
};

export default ErrorField;
