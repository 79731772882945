import { Table, Row, Col, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as Lang from '../../../i18n/constants';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from '../../../components/Loader';
import IAlert from '../../../services/Alert/Alert.interface';
import * as Alert from '../../../services/Alert/Alert';
import ICamera from '../../../services/Camera/Camera.interface';
import moment from 'moment';

interface AlertsTableProps {
    alerts: IAlert[];
    cameras: ICamera[];
    handleEditAlert: (event: React.MouseEvent<HTMLButtonElement>) => void;
    reloadAlerts: () => void;
}

const AlertsTable = ({
    alerts,
    cameras,
    handleEditAlert,
    reloadAlerts,
}: AlertsTableProps): JSX.Element => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState('');

    const langObj: { [key: string]: string } = {};

    for (const key of Object.keys(Lang)) langObj[key] = key;

    const handleDeleteAlert = async (
        event: React.MouseEvent<HTMLButtonElement>
    ): Promise<void> => {
        event.preventDefault();
        const button: HTMLButtonElement = event.currentTarget;
        const id = button.value;

        setLoading(id);

        const deletedAlert = await Alert.deleteAlert(id);

        if (deletedAlert) {
            toast.success(t(Lang.ALERT_DELETED_SUCCESSFULLY));
            reloadAlerts();
        } else toast.error(t(Lang.ERROR_DELETING_ALERT));

        setLoading('');
    };

    return (
        <Row>
            <Col>
                <Table responsive striped hover variant="primary">
                    <thead>
                        <tr>
                            <th></th>
                            <th>{t(Lang.PLATE)}</th>
                            <th>{t(Lang.CAMERA)}</th>
                            <th>{t(Lang.DIRECTION)}</th>
                            <th>{t(Lang.DATE)}</th>
                            <th>{t(Lang.REASON)}</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {alerts.map((alert: IAlert) => {
                            const camera = cameras.filter(
                                (camera) => camera._id === alert.camera
                            );

                            return (
                                <tr key={alert._id}>
                                    <td></td>
                                    <td>{alert.plate}</td>
                                    <td>
                                        {camera.length ? camera[0].name : ''}
                                    </td>
                                    <td>
                                        {t(
                                            langObj[
                                                alert.direction.toUpperCase()
                                            ]
                                        )}
                                    </td>
                                    <td>
                                        {moment(alert.createdAt).format(
                                            'DD/MM/YYYY HH:mm:ss'
                                        )}
                                    </td>
                                    <td>
                                        {t(langObj[alert.reason.toUpperCase()])}
                                    </td>
                                    <td>
                                        <Button
                                            variant="link"
                                            value={alert._id}
                                            onClick={handleEditAlert}
                                        >
                                            <FontAwesomeIcon
                                                icon={['fas', 'pen-to-square']}
                                                size="2x"
                                                fixedWidth
                                            />
                                        </Button>
                                    </td>
                                    <td>
                                        <Button
                                            variant="link"
                                            className="btn-delete"
                                            value={alert._id}
                                            onClick={handleDeleteAlert}
                                        >
                                            {loading === alert._id ? (
                                                <Loader
                                                    loading={true}
                                                    size={25}
                                                    color="#dc3545"
                                                />
                                            ) : (
                                                <FontAwesomeIcon
                                                    icon={['fas', 'trash-can']}
                                                    size="2x"
                                                    fixedWidth
                                                />
                                            )}
                                        </Button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </Col>
        </Row>
    );
};

export default AlertsTable;
