import IEvent from '../../../services/Event/Event.interface';
import * as Lang from '../../../i18n/constants';
import { useTranslation } from 'react-i18next';
import ICamera from '../../../services/Camera/Camera.interface';
import moment from 'moment';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Page,
    Text,
    View,
    Image,
    Document,
    StyleSheet,
    PDFDownloadLink,
} from '@react-pdf/renderer';
import config from '../../../configuration';

interface SanctionsExportPDFProps {
    events: IEvent[];
    cameras: ICamera[];
}

const SanctionsExportPDF = ({
    events,
    cameras,
}: SanctionsExportPDFProps): JSX.Element => {
    const { t } = useTranslation();

    const langObj: { [key: string]: string } = {};

    for (const key of Object.keys(Lang)) langObj[key] = key;

    const protocol = config.frontend.protocol;

    const generatePDF = (): JSX.Element => {
        const styles = StyleSheet.create({
            page: {
                flexDirection: 'row',
                backgroundColor: '#E4E4E4',
            },
            section: {
                margin: 10,
                padding: 10,
                flexGrow: 1,
            },
            paragraph: {
                marginBottom: 10,
            },
            title: {
                fontSize: 30,
                textAlign: 'center',
                marginBottom: 20,
            },
            text: {
                fontSize: 10,
            },
        });

        const pdf: JSX.Element = (
            <Document>
                {events.map((event) => {
                    const camera = cameras.filter(
                        (camera) => camera._id === event.camera
                    );

                    return (
                        <Page key={event._id} size="A4" style={styles.page}>
                            <View style={styles.section}>
                                <Image
                                    src="/img/logoZBE.png"
                                    style={{ marginBottom: 20, width: 500 }}
                                />
                                <Text style={[styles.paragraph, styles.title]}>
                                    {t(Lang.SANCTION)}
                                </Text>
                                <Text style={[styles.paragraph, styles.text]}>
                                    {t(Lang.PLATE)}: {event.plate}
                                </Text>
                                <Text style={[styles.paragraph, styles.text]}>
                                    {t(Lang.CAMERA)}:{' '}
                                    {camera.length ? camera[0].name : ''}
                                </Text>
                                <Text style={[styles.paragraph, styles.text]}>
                                    {t(Lang.DIRECTION)}:{' '}
                                    {t(langObj[event.direction.toUpperCase()])}
                                </Text>
                                <Text style={[styles.paragraph, styles.text]}>
                                    {t(Lang.LOCATION)}:{' '}
                                    {camera.length ? camera[0].location : ''}
                                </Text>
                                <Text style={[styles.paragraph, styles.text]}>
                                    {t(Lang.DATE)}:{' '}
                                    {moment(event.createdAt).format(
                                        'DD/MM/YYYY HH:mm:ss'
                                    )}
                                </Text>
                                <Text style={[styles.paragraph, styles.text]}>
                                    {t(Lang.SENT_SANCTION_DATE)}:{' '}
                                    {moment(event.sentSanctionDate).format(
                                        'DD/MM/YYYY HH:mm:ss'
                                    )}
                                </Text>
                                <Text style={[styles.paragraph, styles.text]}>
                                    {t(Lang.RESTRICTION_MODE)}:{' '}
                                    {event.restrictionMode
                                        ? t(
                                              langObj[
                                                  event.restrictionMode.toUpperCase()
                                              ]
                                          )
                                        : ''}
                                </Text>
                                <Image
                                    src={`${protocol}://${config.backend.host}:${config.backend.port}/${event.imagePlateURL}`}
                                    style={{ marginBottom: 10, width: 100 }}
                                />
                                {event.imageOCRURL ? (
                                    <Image
                                        src={`${protocol}://${config.backend.host}:${config.backend.port}/${event.imageOCRURL}`}
                                        style={{ marginBottom: 10, width: 250 }}
                                    />
                                ) : (
                                    ''
                                )}
                                <Image
                                    src={`${protocol}://${config.backend.host}:${config.backend.port}/${event.imageContextURL}`}
                                    style={{ width: 300 }}
                                />
                            </View>
                        </Page>
                    );
                })}
            </Document>
        );

        return pdf;
    };

    return (
        <PDFDownloadLink
            document={generatePDF()}
            fileName={`sanctions_${moment(new Date()).format(
                'DD-MM-YYYY HH:mm'
            )}.pdf`}
        >
            <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip>{t(Lang.EXPORT_PDF_FILE)}</Tooltip>}
            >
                <Button variant="link" className="btn-delete">
                    <FontAwesomeIcon
                        icon={['fas', 'file-pdf']}
                        size="2x"
                        fixedWidth
                    />
                </Button>
            </OverlayTrigger>
        </PDFDownloadLink>
    );
};

export default SanctionsExportPDF;
