import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import IAlert from '../../services/Alert/Alert.interface';
import * as Alert from '../../services/Alert/Alert';
import IWarning, { DeviceType } from '../../services/Warning/Warning.interface';
import * as Warning from '../../services/Warning/Warning';
import { Button, Alert as AlertBootstrap, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as Lang from '../../i18n/constants';
import moment from 'moment';
import { Link } from 'react-router-dom';
import * as URL from '../../routes/URL';
import ICamera from '../../services/Camera/Camera.interface';
import * as Camera from '../../services/Camera/Camera';
import IRaspberry from '../../services/Raspberry/Raspberry.interface';
import * as Raspberry from '../../services/Raspberry/Raspberry';
import IPanel from '../../services/Panel/Panel.interface';
import * as Panel from '../../services/Panel/Panel';
import ISensor from '../../services/Sensor/Sensor.interface';
import * as Sensor from '../../services/Sensor/Sensor';

interface NotificationProps {
    userId: string;
}

const Notification = ({ userId }: NotificationProps): JSX.Element => {
    const { t } = useTranslation();
    const [alerts, setAlerts] = useState<IAlert[]>([]);
    const [warnings, setWarnings] = useState<IWarning[]>([]);
    const [showAlerts, setShowAlerts] = useState(false);
    const [fetchAlerts, setFetchAlerts] = useState(true);
    const [fetchWarnings, setFetchWarnings] = useState(true);
    const [cameras, setCameras] = useState<ICamera[]>([]);
    const [raspberries, setRaspberries] = useState<IRaspberry[]>([]);
    const [panels, setPanels] = useState<IPanel[]>([]);
    const [sensors, setSensors] = useState<ISensor[]>([]);

    const triggerAlertFetch = () => setFetchAlerts((t) => !t);

    const triggerWarningFetch = () => setFetchWarnings((t) => !t);

    const langObj: { [key: string]: string } = {};

    for (const key of Object.keys(Lang)) langObj[key] = key;

    const handleOpenAlerts = () => {
        setShowAlerts(!showAlerts);
    };

    const handleClose = async (
        a: unknown,
        b: React.MouseEvent<HTMLButtonElement>
    ): Promise<void> => {
        const id = b.currentTarget.parentElement?.id;

        if (!id) return;

        const alert = await Alert.getAlert(id);

        if (alert) {
            alert.users = alert.users?.filter((idUser) => idUser !== userId);

            await Alert.updateAlert(alert);
            triggerAlertFetch();
            triggerWarningFetch();
        }
    };

    const handleHideAlerts = async () => {
        const promises = [];

        for (const alert of alerts) {
            alert.users = alert.users?.filter((idUser) => idUser !== userId);
            promises.push(Alert.updateAlert(alert));
        }

        await Promise.all(promises);

        triggerAlertFetch();
    };

    const handleHideWarnings = async () => {
        const promises = [];

        for (const warning of warnings) {
            warning.users = warning.users?.filter(
                (idUser) => idUser !== userId
            );
            promises.push(Warning.updateWarning(warning));
        }

        await Promise.all(promises);

        triggerWarningFetch();
    };

    useEffect(() => {
        const loadAlerts = async () => {
            const alerts = await Alert.getAlerts({ user: userId });

            if (alerts) setAlerts(alerts);
        };
        loadAlerts();
    }, [fetchAlerts]);

    useEffect(() => {
        const loadWarnings = async () => {
            const warnings = await Warning.getWarnings(userId);

            if (warnings) setWarnings(warnings);

            const cameras = await Camera.getCameras();

            if (cameras) setCameras(cameras);

            //const raspberries = await Raspberry.getRaspberries();

            //if (raspberries) setRaspberries(raspberries);

            const panels = await Panel.getPanels();

            if (panels) setPanels(panels);

            const sensors = await Sensor.getSensors();

            if (sensors) setSensors(sensors);
        };
        loadWarnings();
    }, [fetchWarnings]);

    return (
        <div>
            <Button variant="link" onClick={handleOpenAlerts}>
                <FontAwesomeIcon
                    icon={['fas', 'bell']}
                    size="2x"
                    className="text-primary"
                    fixedWidth
                />

                {alerts.length || warnings.length ? (
                    <span className="alert-bubble bg-danger text-white">
                        {alerts.length + warnings.length}
                    </span>
                ) : (
                    ''
                )}
            </Button>
            {(alerts.length || warnings.length) && showAlerts ? (
                <Row className="alerts w-100">
                    <Col className="text-center" xs={{ span: 3, offset: 9 }}>
                        {alerts.length ? (
                            <Row>
                                <Col>
                                    <Button
                                        variant="primary"
                                        className="mb-3"
                                        onClick={handleHideAlerts}
                                    >
                                        {t(Lang.HIDE_ALL_ALERTS)}
                                    </Button>
                                </Col>
                            </Row>
                        ) : (
                            ''
                        )}

                        {alerts.map((alert, index) => {
                            if (index < 5)
                                return (
                                    <Row key={alert._id}>
                                        <Col>
                                            <AlertBootstrap
                                                id={alert._id}
                                                variant="danger"
                                                dismissible
                                                onClose={handleClose}
                                            >
                                                {`${alert.plate} - ${t(
                                                    langObj[
                                                        alert.reason.toUpperCase()
                                                    ]
                                                )} - ${moment(
                                                    alert.createdAt
                                                ).format(
                                                    'DD/MM/YYYY HH:mm:ss'
                                                )}`}
                                            </AlertBootstrap>
                                        </Col>
                                    </Row>
                                );
                        })}

                        {alerts.length ? (
                            <Link
                                to={URL.ALERTS}
                                className="text-decoration-none"
                            >
                                <Button variant="secondary" className="mb-3">
                                    {t(Lang.VIEW_ALL_ALERTS)}
                                </Button>
                            </Link>
                        ) : (
                            ''
                        )}
                        {warnings.length ? (
                            <Row>
                                <Col>
                                    <Button
                                        variant="primary"
                                        className="mb-3"
                                        onClick={handleHideWarnings}
                                    >
                                        {t(Lang.HIDE_ALL_WARNINGS)}
                                    </Button>
                                </Col>
                            </Row>
                        ) : (
                            ''
                        )}

                        {warnings.map((warning, index) => {
                            let device:
                                | ICamera[]
                                | IRaspberry[]
                                | IPanel[]
                                | ISensor[]
                                | undefined = [];

                            if (warning.type === DeviceType.camera)
                                device = cameras?.filter(
                                    (camera) => camera._id === warning.device
                                );

                            if (warning.type === DeviceType.raspberry)
                                device = raspberries?.filter(
                                    (raspberry) =>
                                        raspberry._id === warning.device
                                );

                            if (warning.type === DeviceType.panel)
                                device = panels?.filter(
                                    (panel) => panel._id === warning.device
                                );

                            if (warning.type === DeviceType.sensor)
                                device = sensors?.filter(
                                    (sensor) => sensor._id === warning.device
                                );

                            if (index < 5)
                                return (
                                    <Row key={warning._id}>
                                        <Col>
                                            <AlertBootstrap
                                                id={warning._id}
                                                variant="danger"
                                                dismissible
                                                onClose={handleClose}
                                            >
                                                {`${
                                                    device.length
                                                        ? device[0].name
                                                        : ''
                                                } - ${t(
                                                    langObj[
                                                        warning.reason.toUpperCase()
                                                    ]
                                                )} - ${moment(
                                                    warning.createdAt
                                                ).format(
                                                    'DD/MM/YYYY HH:mm:ss'
                                                )}`}
                                            </AlertBootstrap>
                                        </Col>
                                    </Row>
                                );
                        })}
                        {warnings.length ? (
                            <Row>
                                <Col>
                                    <Link
                                        to={URL.WARNINGS}
                                        className="text-decoration-none"
                                    >
                                        <Button
                                            variant="secondary"
                                            className="mb-3"
                                        >
                                            {t(Lang.VIEW_ALL_WARNINGS)}
                                        </Button>
                                    </Link>
                                </Col>
                            </Row>
                        ) : (
                            ''
                        )}
                    </Col>
                </Row>
            ) : (
                ''
            )}
        </div>
    );
};

export default Notification;
