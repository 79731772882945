import React, { useEffect, useState } from 'react';
import Header from '../../components/header/Header';
import Menu from '../../components/menu/Menu';
import IUser, { Role } from '../../services/User/User.interface';
import { Row, Col, Button, FormSelect } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as Lang from '../../i18n/constants';
import Loader from '../../components/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IZone from '../../services/Zone/Zone.interface';
import * as Zone from '../../services/Zone/Zone';
import ISensor from '../../services/Sensor/Sensor.interface';
import * as Sensor from '../../services/Sensor/Sensor';
import { useNavigate } from 'react-router-dom';
import * as URL from '../../routes/URL';
import SensorModal from './components/SensorModal';
import ISensorType from '../../services/SensorType/SensorType.interface';
import * as SensorType from '../../services/SensorType/SensorType';
import SensorsTable from './components/SensorsTable';

interface SensorLayoutProps {
    user?: IUser;
}

const SensorLayout = ({ user }: SensorLayoutProps): JSX.Element => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [zones, setZones] = useState<IZone[]>([]);
    const [zoneSelected, setZoneSelected] = useState<string>('');
    const [sensors, setSensors] = useState<ISensor[]>([]);
    const [showSensorModal, setShowSensorModal] = useState(false);
    const [sensorModal, setSensorModal] = useState<ISensor | false>();

    const [sensorsType, setSensorsType] = useState<ISensorType[]>([]);

    const [fetchSensors, setFetchSensors] = useState(true);

    const triggerSensorFetch = () => setFetchSensors((t) => !t);

    const handleChange = (
        event: React.ChangeEvent<HTMLSelectElement>
    ): void => {
        setZoneSelected(event.target.value);
        triggerSensorFetch();
    };

    const handleOpenSensorModal = (
        event: React.MouseEvent<HTMLButtonElement>
    ): void => {
        const button: HTMLButtonElement = event.currentTarget;

        const id = button.value;

        if (id) {
            const sensor = sensors.find((sensor) => sensor._id === id);
            setSensorModal(sensor);
        } else setSensorModal(undefined);

        setShowSensorModal(true);
    };

    const handleCloseSensorModal = (): void => {
        setSensorModal(false);
        setShowSensorModal(false);
    };

    useEffect(() => {
        if (user?.role !== Role.admin) navigate(URL.BASE);
    }, []);

    useEffect(() => {
        const load = async () => {
            const zones = await Zone.getZones();

            if (zones && zones.length) {
                setZones(zones);

                if (!zoneSelected && zones[0]._id)
                    setZoneSelected(zones[0]._id);

                const zoneId = zoneSelected || zones[0]._id || '';

                const sensors = await Sensor.getSensors(zoneId);

                if (sensors) setSensors(sensors);
            }

            const sensorsType = await SensorType.getSensorTypes();

            if (sensorsType) setSensorsType(sensorsType);

            setLoading(false);
        };

        load();
    }, [fetchSensors]);

    return (
        <>
            <Header user={user} />
            <Row className="h-100">
                <Col xs="3" xl="2" className="p-0 sidebar d-none d-xl-block">
                    {user ? (
                        <Menu currentPage={t(Lang.SENSORS)} user={user} />
                    ) : (
                        ''
                    )}
                </Col>
                <Col xs="12" xl="10" className="p-3 pe-xl-4">
                    <Row>
                        {zones.length ? (
                            <Col xs="12" xl="4">
                                <FormSelect onChange={handleChange}>
                                    {zones.map((zone) => {
                                        return (
                                            <option
                                                key={zone._id}
                                                value={zone._id}
                                            >
                                                {zone.name}
                                            </option>
                                        );
                                    })}
                                </FormSelect>
                            </Col>
                        ) : (
                            ''
                        )}
                    </Row>
                    <Row>
                        <Col>
                            <Button
                                variant="link"
                                className="btn-add"
                                onClick={handleOpenSensorModal}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'plus']}
                                    size="2x"
                                    fixedWidth
                                />
                            </Button>
                            {zoneSelected ? (
                                <SensorModal
                                    show={showSensorModal}
                                    handleClose={handleCloseSensorModal}
                                    reloadSensors={triggerSensorFetch}
                                    sensor={sensorModal}
                                    zoneId={zoneSelected}
                                    sensorsType={sensorsType}
                                />
                            ) : (
                                ''
                            )}
                        </Col>
                    </Row>
                    {sensors.length && sensorsType.length ? (
                        <Row>
                            <Col>
                                <SensorsTable
                                    sensors={sensors}
                                    handleEditSensor={handleOpenSensorModal}
                                    reloadSensors={triggerSensorFetch}
                                    sensorsType={sensorsType}
                                />
                            </Col>
                        </Row>
                    ) : (
                        <Loader loading={loading} size={100} color="#0d6efd" />
                    )}
                </Col>
            </Row>
        </>
    );
};

export default SensorLayout;
