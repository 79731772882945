export const SIGN_IN = 'SIGN_IN';
export const USERNAME_OR_EMAIL = 'USERNAME_OR_EMAIL';
export const PASSWORD = 'PASSWORD';
export const REMEMBER_ME = 'REMEMBER_ME';
export const LOGIN = 'LOGIN';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const MESSAGE_RECOVERY = 'MESSAGE_RECOVERY';
export const SEND = 'SEND';
export const CANCEL = 'CANCEL';
export const INVALID_USERNAME_OR_PASSWORD = 'INVALID_USERNAME_OR_PASSWORD';
export const LOST_CONNECTION_TO_SERVER = 'LOST_CONNECTION_TO_SERVER';
export const HOME = 'HOME';
export const REAL_TIME = 'REAL_TIME';
export const ANALYTICS = 'ANALYTICS';
export const SANCTIONS = 'SANCTIONS';
export const LISTS = 'LISTS';
export const WHITE_LISTS = 'WHITE_LISTS';
export const BLACK_LISTS = 'BLACK_LISTS';
export const LOG = 'LOG';
export const SETTINGS = 'SETTINGS';
export const USERS = 'USERS';
export const ZONES = 'ZONES';
export const CAMERAS = 'CAMERAS';
export const RASPBERRIES = 'RASPBERRIES';
export const PANELS = 'PANELS';
export const SYSTEM = 'SYSTEM';
export const PROFILE = 'PROFILE';
export const LOGOUT = 'LOGOUT';
export const ENGLISH = 'ENGLISH';
export const SPANISH = 'SPANISH';
export const VALENCIAN = 'VALENCIAN';
export const USERNAME = 'USERNAME';
export const EMAIL = 'EMAIL';
export const FIRSTNAME = 'FIRSTNAME';
export const LASTNAME = 'LASTNAME';
export const ROLE = 'ROLE';
export const PHONE = 'PHONE';
export const SUCCESS = 'SUCCESS';
export const FAILED = 'FAILED';
export const USER_CREATED_SUCCESSFULLY = 'USER_CREATED_SUCCESSFULLY';
export const ERROR_CREATING_USER = 'ERROR_CREATING_USER';
export const USER_UPDATED_SUCCESSFULLY = 'USER_UPDATED_SUCCESSFULLY';
export const ERROR_UPDATING_USER = 'ERROR_UPDATING_USER';
export const USER_DELETED_SUCCESSFULLY = 'USER_DELETED_SUCCESSFULLY';
export const ERROR_DELETING_USER = 'ERROR_DELETING_USER';
export const ADD_USER = 'ADD_USER';
export const EDIT_USER = 'EDIT_USER';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const REPEAT_PASSWORD = 'REPEAT_PASSWORD';
export const SAVE = 'SAVE';
export const REQUIRED_MESSAGE = 'REQUIRED_MESSAGE';
export const REQUIRED_FIELD = 'REQUIRED_FIELD';
export const MAX_LENGTH_FIELD = 'MAX_LENGTH_FIELD';
export const MIN_LENGTH_FIELD = 'MIN_LENGTH_FIELD';
export const PASSWORDS_DO_NOT_MATCH = 'PASSWORDS_DO_NOT_MATCH';
export const ZONE_CREATED_SUCCESSFULLY = 'ZONE_CREATED_SUCCESSFULLY';
export const ERROR_CREATING_ZONE = 'ERROR_CREATING_ZONE';
export const ZONE_UPDATED_SUCCESSFULLY = 'ZONE_UPDATED_SUCCESSFULLY';
export const ERROR_UPDATING_ZONE = 'ERROR_UPDATING_ZONE';
export const ZONE_DELETED_SUCCESSFULLY = 'ZONE_DELETED_SUCCESSFULLY';
export const ERROR_DELETING_ZONE = 'ERROR_DELETING_ZONE';
export const ADD_ZONE = 'ADD_ZONE';
export const EDIT_ZONE = 'EDIT_ZONE';
export const NAME = 'NAME';
export const DESCRIPTION = 'DESCRIPTION';
export const CAMERA_CREATED_SUCCESSFULLY = 'CAMERA_CREATED_SUCCESSFULLY';
export const ERROR_CREATING_CAMERA = 'ERROR_CREATING_CAMERA';
export const CAMERA_UPDATED_SUCCESSFULLY = 'CAMERA_UPDATED_SUCCESSFULLY';
export const ERROR_UPDATING_CAMERA = 'ERROR_UPDATING_CAMERA';
export const CAMERA_DELETED_SUCCESSFULLY = 'CAMERA_DELETED_SUCCESSFULLY';
export const ERROR_DELETING_CAMERA = 'ERROR_DELETING_CAMERA';
export const ADD_CAMERA = 'ADD_CAMERA';
export const EDIT_CAMERA = 'EDIT_CAMERA';
export const LOCATION = 'LOCATION';
export const COORDINATES = 'COORDINATES';
export const DIRECTION = 'DIRECTION';
export const ENTRY = 'ENTRY';
export const EXIT = 'EXIT';
export const BOTH = 'BOTH';
export const ADMIN = 'ADMIN';
export const USER = 'USER';
export const DETECTION_TYPE = 'DETECTION_TYPE';
export const THROUGH = 'TROUGH';
export const CONTINUE = 'CONTINUE';
export const MANUFACTURER = 'MANUFACTURER';
export const MODEL = 'MODEL';
export const HOST = 'HOST';
export const PORT = 'PORT';
export const MAX_FIELD = 'MAX_FIELD';
export const MIN_FIELD = 'MIN_FIELD';
export const ZONE = 'ZONE';
export const RASPBERRY_CREATED_SUCCESSFULLY = 'RASPBERRY_CREATED_SUCCESSFULLY';
export const ERROR_CREATING_RASPBERRY = 'ERROR_CREATING_RASPBERRY';
export const RASPBERRY_UPDATED_SUCCESSFULLY = 'RASPBERRY_UPDATED_SUCCESSFULLY';
export const ERROR_UPDATING_RASPBERRY = 'ERROR_UPDATING_RASPBERRY';
export const RASPBERRY_DELETED_SUCCESSFULLY = 'RASPBERRY_DELETED_SUCCESSFULLY';
export const ERROR_DELETING_RASPBERRY = 'ERROR_DELETING_RASPBERRY';
export const ADD_RASPBERRY = 'ADD_RASPBERRY';
export const EDIT_RASPBERRY = 'EDIT_RASPBERRY';
export const PANEL_CREATED_SUCCESSFULLY = 'PANEL_CREATED_SUCCESSFULLY';
export const ERROR_CREATING_PANEL = 'ERROR_CREATING_PANEL';
export const PANEL_UPDATED_SUCCESSFULLY = 'PANEL_UPDATED_SUCCESSFULLY';
export const ERROR_UPDATING_PANEL = 'ERROR_UPDATING_PANEL';
export const PANEL_DELETED_SUCCESSFULLY = 'PANEL_DELETED_SUCCESSFULLY';
export const ERROR_DELETING_PANEL = 'ERROR_DELETING_PANEL';
export const ADD_PANEL = 'ADD_PANEL';
export const EDIT_PANEL = 'EDIT_PANEL';
export const NLINES = 'NLINES';
export const LINES = 'LINES';
export const PROTOCOL = 'PROTOCOL';
export const LINE = 'LINE';
export const REFRESH_TIME = 'REFRESH_TIME';
export const MAX_TIME_FAILURE = 'MAX_TIME_FAILURE';
export const EMAIL_TO_RECEIVE_TECHNICAL_ALERTS =
    'EMAIL_TO_RECEIVE_TECHNICAL_ALERTS';
export const SAVED_SUCCESSFULLY = 'SAVED_SUCCESSFULLY';
export const FAILED_TO_SAVE = 'FAILED_TO_SAVE';
export const LIST_DESCRIPTIONS = 'LIST_DESCRIPTIONS';
export const DESCRIPTION_CREATED_SUCCESSFULLY =
    'DESCRIPTION_CREATED_SUCCESSFULLY';
export const ERROR_CREATING_DESCRIPTION = 'ERROR_CREATING_DESCRIPTION';
export const DESCRIPTION_UPDATED_SUCCESSFULLY =
    'DESCRIPTION_UPDATED_SUCCESSFULLY';
export const ERROR_UPDATING_DESCRIPTION = 'ERROR_UPDATING_DESCRIPTION';
export const DESCRIPTION_DELETED_SUCCESSFULLY =
    'DESCRIPTION_DELETED_SUCCESSFULLY';
export const ERROR_DELETING_DESCRIPTION = 'ERROR_DELETING_DESCRIPTION';
export const ADD_DESCRIPTION = 'ADD_DESCRIPTION';
export const EDIT_DESCRIPTION = 'EDIT_DESCRIPTION';
export const ENVIRONMENTAL = 'ENVIRONMENTAL';
export const PERMANENT = 'PERMANENT';
export const TIMECONTROL = 'TIMECONTROL';
export const TIMECONTROL_ENVIRONMENTAL = 'TIMECONTROL_ENVIRONMENTAL';
export const RESTRICTION_MODE = 'RESTRICTION_MODE';
export const WHITE_LIST_CREATED_SUCCESSFULLY =
    'WHITE_LIST_CREATED_SUCCESSFULLY';
export const ERROR_CREATING_WHITE_LIST = 'ERROR_CREATING_WHITE_LIST';
export const WHITE_LIST_UPDATED_SUCCESSFULLY =
    'WHITE_LIST_UPDATED_SUCCESSFULLY';
export const ERROR_UPDATING_WHITE_LIST = 'ERROR_UPDATING_WHITE_LIST';
export const WHITE_LIST_DELETED_SUCCESSFULLY =
    'WHITE_LIST_DELETED_SUCCESSFULLY';
export const ERROR_DELETING_WHITE_LIST = 'ERROR_DELETING_WHITE_LIST';
export const ADD_WHITE_LIST = 'ADD_WHITE_LIST';
export const EDIT_WHITE_LIST = 'EDIT_WHITE_LIST';
export const ENABLED = 'ENABLED';
export const DISABLED = 'DISABLED';
export const WEB_ALERT = 'WEB_ALERT';
export const EMAIL_ALERT = 'EMAIL_ALERT';
export const EMAIL_RECIPIENTS = 'EMAIL_RECIPIENTS';
export const SEMICOLON_SEPARATED_EMAILS = 'SEMICOLON_SEPARATED_EMAILS';
export const BLACK_LIST_CREATED_SUCCESSFULLY =
    'BLACK_LIST_CREATED_SUCCESSFULLY';
export const ERROR_CREATING_BLACK_LIST = 'ERROR_CREATING_BLACK_LIST';
export const BLACK_LIST_UPDATED_SUCCESSFULLY =
    'BLACK_LIST_UPDATED_SUCCESSFULLY';
export const ERROR_UPDATING_BLACK_LIST = 'ERROR_UPDATING_BLACK_LIST';
export const BLACK_LIST_DELETED_SUCCESSFULLY =
    'BLACK_LIST_DELETED_SUCCESSFULLY';
export const ERROR_DELETING_BLACK_LIST = 'ERROR_DELETING_BLACK_LIST';
export const ADD_BLACK_LIST = 'ADD_BLACK_LIST';
export const EDIT_BLACK_LIST = 'EDIT_BLACK_LIST';
export const PLATE_ADDED_SUCCESSFULLY = 'PLATE_ADDED_SUCCESSFULLY';
export const ERROR_ADDING_PLATE = 'ERROR_ADDING_PLATE';
export const PLATE_UPDATED_SUCCESSFULLY = 'PLATE_UPDATED_SUCCESSFULLY';
export const ERROR_UPDATING_PLATE = 'ERROR_UPDATING_PLATE';
export const PLATE_REMOVED_SUCCESSFULLY = 'PLATE_REMOVED_SUCCESSFULLY';
export const ERROR_REMOVING_PLATE = 'ERROR_REMOVING_PLATE';
export const ADD_PLATE = 'ADD_PLATE';
export const EDIT_PLATE = 'EDIT_PLATE';
export const PLATE = 'PLATE';
export const OBSERVATIONS = 'OBSERVATIONS';
export const BRAND = 'BRAND';
export const COLOR = 'COLOR';
export const OWNER = 'OWNER';
export const MONDAY = 'MONDAY';
export const TUESDAY = 'TUESDAY';
export const WEDNESDAY = 'WEDNESDAY';
export const THURSDAY = 'THURSDAY';
export const FRIDAY = 'FRIDAY';
export const SATURDAY = 'SATURDAY';
export const SUNDAY = 'SUNDAY';
export const CALENDAR = 'CALENDAR';
export const AM = 'AM';
export const PM = 'PM';
export const DATE_OF_EXPIRY = 'DATE_OF_EXPIRY';
export const LIST = 'LIST';
export const EXPORT_CSV_FILE = 'EXPORT_CSV_FILE';
export const IMPORT_CSV_FILE = 'IMPORT_CSV_FILE';
export const CHOOSE_FILE = 'CHOOSE_FILE';
export const SUCCESSFULLY_IMPORTED_CSV_FILE = 'SUCCESSFULLY_IMPORTED_CSV_FILE';
export const ERROR_IMPORTING_CSV_FILE = 'ERROR_IMPORTING_CSV_FILE';
export const CAMERA = 'CAMERA';
export const FEMALE_NONE = 'FEMALE_NONE';
export const REGISTRY = 'REGISTRY';
export const FEMALE_ALL = 'FEMALE_ALL';
export const IN = 'IN';
export const OUT = 'OUT';
export const START = 'START';
export const END = 'END';
export const TYPE_OF_REGISTRY = 'TYPE_OF_REGISTRY';
export const AUTHORIZED = 'AUTHORIZED';
export const UNAUTHORIZED = 'UNAUTHORIZED';
export const POSSIBLEINFRINGEMENT = 'POSSIBLEINFRINGEMENT';
export const INVALIDATED = 'INVALIDATED';
export const SANCTIONED = 'SANCTIONED';
export const SENT_SANCTION_DATE = 'SENT_SANCTION_DATE';
export const DATE = 'DATE';
export const EVENT_CREATED_SUCCESSFULLY = 'EVENT_CREATED_SUCCESSFULLY';
export const ERROR_CREATING_EVENT = 'ERROR_CREATING_EVENT';
export const EVENT_UPDATED_SUCCESSFULLY = 'EVENT_UPDATED_SUCCESSFULLY';
export const ERROR_UPDATING_EVENT = 'ERROR_UPDATING_EVENT';
export const EVENT_DELETED_SUCCESSFULLY = 'EVENT_DELETED_SUCCESSFULLY';
export const ERROR_DELETING_EVENT = 'ERROR_DELETING_EVENT';
export const ADD_EVENT = 'ADD_EVENT';
export const EDIT_EVENT = 'EDIT_EVENT';
export const COUNTRY = 'COUNTRY';
export const CONFIDENCE = 'CONFIDENCE';
export const CAR_STATE = 'CAR_STATE';
export const NEW = 'NEW';
export const RECURRENT = 'RECURRENT';
export const ENVIRONMENT_LABEL = 'ENVIRONMENT_LABEL';
export const ZERO = 'ZERO';
export const ECO = 'ECO';
export const B = 'B';
export const C = 'C';
export const WITHOUT = 'WITHOUT';
export const FOREIGN = 'FOREIGN';
export const FAIL = 'FAIL';
export const IMAGE_PLATE_URL = 'IMAGE_PLATE_URL';
export const IMAGE_CONTEXT_URL = 'IMAGE_CONTEXT_URL';
export const FILTER = 'FILTER';
export const RESTRICTED_ENVIRONMENTAL_LABELS =
    'RESTRICTED_ENVIRONMENTAL_LABELS';
export const GMAIL_EMAIL_CONFIGURATION = 'GMAIL_EMAIL_CONFIGURATION';
export const ALERTS = 'ALERTS';
export const ALERT_CREATED_SUCCESSFULLY = 'ALERT_CREATED_SUCCESSFULLY';
export const ERROR_CREATING_ALERT = 'ERROR_CREATING_ALERT';
export const ALERT_UPDATED_SUCCESSFULLY = 'ALERT_UPDATED_SUCCESSFULLY';
export const ERROR_UPDATING_ALERT = 'ERROR_UPDATING_ALERT';
export const ALERT_DELETED_SUCCESSFULLY = 'ALERT_DELETED_SUCCESSFULLY';
export const ERROR_DELETING_ALERT = 'ERROR_DELETING_ALERT';
export const REASON = 'REASON';
export const OUTOFTIME = 'OUTOFTIME';
export const WHITELIST = 'WHITELIST';
export const BLACKLIST = 'BLACKLIST';
export const VIEW_ALERT = 'VIEW_ALERT';
export const WARNINGS = 'WARNINGS';
export const DEVICE = 'DEVICE';
export const DEVICE_TYPE = 'DEVICE_TYPE';
export const WARNING_TYPE = 'WARNING_TYPE';
export const CONNECTED = 'CONNECTED';
export const DISCONNECTED = 'DISCONNECTED';
export const WARNING_CREATED_SUCCESSFULLY = 'WARNING_CREATED_SUCCESSFULLY';
export const ERROR_CREATING_WARNING = 'ERROR_CREATING_WARNING';
export const WARNING_UPDATED_SUCCESSFULLY = 'WARNING_UPDATED_SUCCESSFULLY';
export const ERROR_UPDATING_WARNING = 'ERROR_UPDATING_WARNING';
export const WARNING_DELETED_SUCCESSFULLY = 'WARNING_DELETED_SUCCESSFULLY';
export const ERROR_DELETING_WARNING = 'ERROR_DELETING_WARNING';
export const RASPBERRY = 'RASPBERRY';
export const PANEL = 'PANEL';
export const IMPORTED_CSV_FILE_IS_NOT_A_WHITELIST =
    'IMPORTED_CSV_FILE_IS_NOT_A_WHITELIST';
export const IMPORTED_CSV_FILE_IS_NOT_A_BLACKLIST =
    'IMPORTED_CSV_FILE_IS_NOT_A_BLACKLIST';
export const A_LIST_WITH_THIS_NAME_ALREADY_EXISTS =
    'A_LIST_WITH_THIS_NAME_ALREADY_EXISTS';
export const VIEW_ALL_ALERTS = 'VIEW_ALL_ALERTS';
export const HIDE_ALL_ALERTS = 'HIDE_ALL_ALERTS';
export const VIEW_ALL_WARNINGS = 'VIEW_ALL_WARNINGS';
export const HIDE_ALL_WARNINGS = 'HIDE_ALL_WARNINGS';
export const CONFIRM_SAVE_PLATE = 'CONFIRM_SAVE_PLATE';
export const TIME_BETWEEEN_EVENTS = 'TIME_BETWEEN_EVENTS';
export const MODIFY_PERSONAL_DATA = 'MODIFY_PERSONAL_DATA';
export const OLD_PASSWORD = 'OLD_PASSWORD';
export const NEW_PASSWORD = 'NEW_PASSWORD';
export const ITEMS_PER_PAGE = 'ITEMS_PER_PAGE';
export const VIEW_SANCTION = 'VIEW_SANCTION';
export const MARK_AS = 'MARK_AS';
export const INVALIDATED_REASON = 'INVALIDATED_REASON';
export const SEND_SANCTIONS = 'SEND_SANCTIONS';
export const SANCTIONS_UPDATED_SUCCESSFULLY = 'SANCTIONS_UPDATED_SUCCESSFULLY';
export const ERROR_UPDATING_SANCTIONS = 'ERROR_UPDATING_SANCTIONS';
export const NO_SANCTION_SELECTED = 'NO_SANCTION_SELECTED';
export const SELECT_ALL_SANCTIONED = 'SELECT_ALL_SANCTIONED';
export const NO_SANCTION_TYPE_SANCTIONED_SELECTED =
    'NO_SANCTION_TYPE_SANCTIONED_SELECTED';
export const SANCTIONS_SENT_SUCCESSFULLY = 'SANCTIONS_SENT_SUCCESSFULLY';
export const ERROR_SENDING_SANCTIONS = 'ERROR_SENDING_SANCTIONS';
export const SANCTION_SENT_CANNOT_BE_MODIFIED =
    'SANCTION_SENT_CANNOT_BE_MODIFIED';
export const NUMBER_OF_VEHICLES_DETECTED_LAST_MONTH =
    'NUMBER_OF_VEHICLES_DETECTED_LAST_MONTH';
export const NUMBER_OF_VEHICLES_DETECTED_LAST_WEEK =
    'NUMBER_OF_VEHICLES_DETECTED_LAST_WEEK';
export const NUMBER_OF_VEHICLES_DETECTED_LAST_DAY =
    'NUMBER_OF_VEHICLES_DETECTED_LAST_DAY';
export const NUMBER_OF_VEHICLES_DETECTED_LAST_HOUR =
    'NUMBER_OF_VEHICLES_DETECTED_LAST_HOUR';
export const NUMBER_OF_VEHICLES = 'NUMBER_OF_VEHICLES';
export const DOWNLOAD_PDF = 'DOWNLOAD_PDF';
export const DOWNLOAD_CSV = 'DOWNLOAD_CSV';
export const DOWNLOAD_JPEG = 'DOWNLOAD_JPEG';
export const DOWNLOAD_PNG = 'DOWNLOAD_PNG';
export const DOWNLOAD_SVG = 'DOWNLOAD_SVG';
export const DOWNLOAD_XLS = 'DOWNLOAD_XLS';
export const PRINT_CHART = 'PRINT_CHART';
export const VIEW_FULL_SCREEN = 'VIEW_FULL_SCREEN';
export const EXIT_FULL_SCREEN = 'EXIT_FULL_SCREEN';
export const VIEW_DATA = 'VIEW_DATA';
export const HIDE_DATA = 'HIDE_DATA';
export const PROPORTION_VEHICLES_ENVIRONMENT_LABEL =
    'PROPORTION_VEHICLES_ENVIRONMENT_LABEL';
export const FROM = 'FROM';
export const TO = 'TO';
export const ANALYZE = 'ANALYZE';
export const DETECTIONS = 'DETECTIONS';
export const NUMBER_OF_DETECTIONS = 'NUMBER_OF_DETECTIONS';
export const ENTRANCES = 'ENTRANCES';
export const EXITS = 'EXITS';
export const NUMBER_OF_REGISTRIES = 'NUMBER_OF_REGISTRIES';
export const RUSH_HOUR = 'RUSH_HOUR';
export const RUSH_HOUR_ENTRANCE = 'RUSH_HOUR_ENTRANCE';
export const RUSH_HOUR_EXIT = 'RUSH_HOUR_EXIT';
export const NUMBER_OF_ALERTS = 'NUMBER_OF_ALERTS';
export const PERCENTAGE = 'PERCENTAGE';
export const CATEGORY = 'CATEGORY';
export const EVENTS = 'EVENTS';
export const OPTIONS = 'OPTIONS';
export const PLATES = 'PLATES';
export const ALL = 'ALL';
export const ACTION = 'ACTION';
export const CREATE = 'CREATE';
export const GET = 'GET';
export const UPDATE = 'UPDATE';
export const DELETE = 'DELETE';
export const COMPARISON = 'COMPARISON';
export const OLD_ELEMENT = 'OLD_ELEMENT';
export const NEW_ELEMENT = 'NEW_ELEMENT';
export const USERNAME_NOT_EXISTS = 'USERNAME_NOT_EXISTS';
export const MAX_LOGIN_ATTEMPTS_EXCEEDED = 'MAX_LOGIN_ATTEMPTS_EXCEEDED';
export const INVALID_PASSWORD = 'INVALID_PASSWORD';
export const EXPIRED_PASSWORD = 'EXPIRED_PASSWORD';
export const RECOVERY_EMAIL_SENT = 'RECOVERY_EMAIL_SENT';
export const RECOVERY_PASSWORD = 'RECOVERY_PASSWORD';
export const PASSWORD_RECOVERED_SUCCESSFULLY =
    'PASSWORD_RECOVERED_SUCCESSFULLY';
export const ERROR_RECOVERING_PASSWORD = 'ERROR_RECOVERING_PASSWORD';
export const PASSWORD_ALREADY_USED = 'PASSWORD_ALREADY_USED';
export const TWO_FACTOR_ENABLED = 'TWO_FACTOR_ENABLED';
export const TWO_FACTOR_CODE = 'TWO_FACTOR_CODE';
export const TYPE = 'TYPE';
export const RESTRICTION = 'RESTRICTION';
export const DETECTION = 'DETECTION';
export const VEHICLE_TRACEABILITY = 'VEHICLE_TRACEABILITY';
export const MAP_COORDINATES = 'MAP_COORDINATES';
export const INVALID_2FA_CODE = 'INVALID_2FA_CODE';
export const STREETS = 'STREETS';
export const HYBRID = 'HYBRID';
export const SATELLITE = 'SATELLITE';
export const TERRAIN = 'TERRAIN';
export const LAST_CONNECTION = 'LAST_CONNECTION';
export const APPLY_ALL = 'APPLY_ALL';
export const SENSOR_TYPES = 'SENSOR_TYPES';
export const ADD_SENSOR_TYPE = 'ADD_SENSOR_TYPE';
export const EDIT_SENSOR_TYPE = 'EDIT_SENSOR_TYPE';
export const PARAMETERS = 'PARAMETERS';
export const SENSOR_TYPE_CREATED_SUCCESSFULLY =
    'SENSOR_TYPE_CREATED_SUCCESSFULLY';
export const ERROR_CREATING_SENSOR_TYPE = 'ERROR_CREATING_SENSOR_TYPE';
export const SENSOR_TYPE_UPDATED_SUCCESSFULLY =
    'SENSOR_TYPE_UPDATED_SUCCESSFULLY';
export const ERROR_UPDATING_SENSOR_TYPE = 'ERROR_UPDATING_SENSOR_TYPE';
export const SENSOR_TYPE_DELETED_SUCCESSFULLY =
    'SENSOR_TYPE_DELETED_SUCCESSFULLY';
export const ERROR_DELETING_SENSOR_TYPE = 'ERROR_DELETING_SENSOR_TYPE';
export const SENSORS = 'SENSORS';
export const ADD_SENSOR = 'ADD_SENSOR';
export const EDIT_SENSOR = 'EDIT_SENSOR';
export const SENSOR_CREATED_SUCCESSFULLY = 'SENSOR_CREATED_SUCCESSFULLY';
export const ERROR_CREATING_SENSOR = 'ERROR_CREATING_SENSOR';
export const SENSOR_UPDATED_SUCCESSFULLY = 'SENSOR_UPDATED_SUCCESSFULLY';
export const ERROR_UPDATING_SENSOR = 'ERROR_UPDATING_SENSOR';
export const SENSOR_DELETED_SUCCESSFULLY = 'SENSOR_DELETED_SUCCESSFULLY';
export const ERROR_DELETING_SENSOR = 'ERROR_DELETING_SENSOR';
export const SENSOR_TYPE = 'SENSOR_TYPE';
export const NONE = 'NONE';
export const TERMS_OF_USE = 'TERMS_OF_USE';
export const ACCEPT = 'ACCEPT';
export const UNITS = 'UNITS';
export const ADD_PARAMETER = 'ADD_PARAMETER';
export const REMOVE_PARAMETER = 'REMOVE_PARAMETER';
export const PASSWORD_EXPIRATION_TIME = 'PASSWORD_EXPIRATION_TIME';
export const USER_ACCOUNT_NOT_FOUND_OR_BLOCKED =
    'USER_ACCOUNT_NOT_FOUND_OR_BLOCKED';
export const ACCOUNT_STATUS = 'ACCOUNT_STATUS';
export const LOCKED = 'LOCKED';
export const UNLOCKED = 'UNLOCKED';
export const DANGEROUSGOODS = 'DANGEROUSGOODS';
export const OPPOSITEDIRECTION = 'OPPOSITEDIRECTION';
export const OPPOSITEDANGEROUS = 'OPPOSITEDANGEROUS';
export const UNKNOWN = 'UNKNOWN';
export const USER_UNLOCKED_SUCCESSFULLY = 'USER_UNLOCKED_SUCCESSFULLY';
export const USER_LOCKED_SUCCESSFULLY = 'USER_LOCKED_SUCCESSFULLY';
export const ERROR_UNLOCKING_USER = 'ERROR_UNLOCKING_USER';
export const ERROR_LOCKING_USER = 'ERROR_LOCKING_USER';
export const IMAGE_OCR_URL = 'IMAGE_OCR_URL';
export const CUSTOM_TEXT = 'CUSTOM_TEXT';
export const AVAILABILITY_AND_STATUS_OF_EQUIPMENT =
    'AVAILABILITY_AND_STATUS_OF_EQUIPMENT';
export const NUMBER_OF_DEVICES = 'NUMBER_OF_DEVICES';
export const SANCTIONS_BY_TYPES_AND_VALIDATIONS =
    'SANCTIONS_BY_TYPES_AND_VALIDATIONS';
export const EVENTS_DELETION_TIME = 'EVENTS_DELETION_TIME';
export const SANCTIONS_DELETION_TIME = 'SANCTIONS_DELETION_TIME';
export const EXPORT_PDF_FILE = 'EXPORT_PDF_FILE';
export const SANCTION = 'SANCTION';
export const IMAGES_PIXELATION = 'IMAGES_PIXELATION';
export const FACES = 'FACES';
export const FRAME = 'FRAME';
export const TOP_MARGIN = 'TOP_MARGIN';
export const RIGHT_MARGIN = 'RIGHT_MARGIN';
export const BOTTOM_MARGIN = 'BOTTOM_MARGIN';
export const LEFT_MARGIN = 'LEFT_MARGIN';
export const IMAGE_PIXELATED_URL = 'IMAGE_PIXELATED_URL';
export const IDENTIFIER = 'IDENTIFIER';
export const SENSOR = 'SENSOR';
export const SENSOR_EVENTS = 'SENSOR_EVENTS';
