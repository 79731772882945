import React, { useEffect, useState } from 'react';
import Header from '../../components/header/Header';
import Menu from '../../components/menu/Menu';
import IUser from '../../services/User/User.interface';
import { Row, Col, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as Lang from '../../i18n/constants';
import Loader from '../../components/Loader';
import IEvent from '../../services/Event/Event.interface';
import * as Event from '../../services/Event/Event';
import IZone from '../../services/Zone/Zone.interface';
import * as Zone from '../../services/Zone/Zone';
import ICamera, { Direction } from '../../services/Camera/Camera.interface';
import * as Camera from '../../services/Camera/Camera';
import { EventsFilterForm } from '../Events/components/EventsFilter';
import EventsTable from '../Events/components/EventsTable';
import EventsModal from '../Events/components/EventsModal';
import RealTimeCamera from './components/RealTimeCamera';
import * as Option from '../../services/Option/Option';

interface RealTimeLayoutProps {
    user?: IUser;
}

const RealTimeLayout = ({ user }: RealTimeLayoutProps): JSX.Element => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [zones, setZones] = useState<IZone[]>([]);
    const [zoneSelected, setZoneSelected] = useState<string | undefined>();
    const [cameras, setCameras] = useState<ICamera[]>([]);
    const [cameraSelected, setCameraSelected] = useState<string | undefined>();
    const [directionSelected, setDirectionSelected] = useState<Direction>();
    const [events, setEvents] = useState<IEvent[]>([]);
    const [fetchEvents, setFetchEvents] = useState(true);
    const [eventModal, setEventModal] = useState<IEvent | false>();
    const [showEventModal, setShowEventModal] = useState(false);

    const langObj: { [key: string]: string } = {};

    for (const key of Object.keys(Lang)) langObj[key] = key;

    const triggerEventFetch = () => setFetchEvents((t) => !t);

    const handleChangeZone = (
        event: React.ChangeEvent<HTMLSelectElement>
    ): void => {
        setZoneSelected(event.target.value);
        setCameraSelected(undefined);
        triggerEventFetch();
    };

    const handleChangeDirection = (
        event: React.ChangeEvent<HTMLSelectElement>
    ): void => {
        setDirectionSelected(event.target.value as Direction);
        setCameraSelected(undefined);
        triggerEventFetch();
    };

    const handleChangeCamera = (
        event: React.ChangeEvent<HTMLSelectElement>
    ): void => {
        setCameraSelected(event.target.value);
        triggerEventFetch();
    };

    const handleOpenEventModal = (
        event: React.MouseEvent<HTMLButtonElement>
    ): void => {
        const button: HTMLButtonElement = event.currentTarget;

        const id = button.value;

        if (id) {
            const event = events.find((event) => event._id === id);
            setEventModal(event);
        } else setEventModal(undefined);

        setShowEventModal(true);
    };

    const handleOpenEventModalCamera = (event: IEvent): void => {
        if (event) {
            setEventModal(event);
            setShowEventModal(true);
        }
    };

    const handleCloseEventModal = (): void => {
        setEventModal(false);
        setShowEventModal(false);
    };

    useEffect(() => {
        const load = async () => {
            const zones = await Zone.getZones();

            if (zones && zones.length) {
                setZones(zones);

                if (!zoneSelected) setZoneSelected(zones[0]._id);

                const zoneId = zoneSelected || zones[0]._id || '';

                let cameras = await Camera.getCameras(zoneId);

                if (cameras) {
                    const filter: EventsFilterForm = { limit: 5 };

                    if (directionSelected) {
                        cameras = cameras.filter(
                            (camera) => camera.direction === directionSelected
                        );
                    }

                    setCameras(cameras);

                    if (cameraSelected) filter['camera'] = cameraSelected;
                    else
                        filter['cameras'] = cameras.map(
                            (camera) => camera._id as string
                        );

                    const events = await Event.getEvents({
                        ...filter,
                        zone: zoneId,
                    });

                    if (events) setEvents(events);

                    setLoading(false);
                }
            }
            setLoading(false);
        };
        load();
    }, [fetchEvents]);

    useEffect(() => {
        const loadInterval = async () => {
            const options = await Option.getOptions();

            if (options) {
                const refreshTime = options.find(
                    (option) => option.name === 'refreshTime'
                );

                if (refreshTime) {
                    setInterval(() => {
                        triggerEventFetch();
                    }, parseInt(refreshTime.value) * 1000);
                }
            }
        };
        loadInterval();
    }, []);

    return (
        <>
            <Header user={user} />
            <Row className="h-100">
                <Col xs="3" xl="2" className="p-0 sidebar d-none d-lg-block">
                    {user ? (
                        <Menu currentPage={t(Lang.REAL_TIME)} user={user} />
                    ) : (
                        ''
                    )}
                </Col>
                <Col xs="12" xl="10" className="p-4 p-xl-3 pe-xl-4">
                    <Row className="mb-5">
                        {zones.length ? (
                            <Col xs="12" xl="3" className="mb-3">
                                <Form.Group as={Row}>
                                    <Form.Label column xs="2">
                                        {t(Lang.ZONE)}
                                    </Form.Label>
                                    <Col xs="10">
                                        <Form.Select
                                            onChange={handleChangeZone}
                                        >
                                            {zones.map((zone) => {
                                                return (
                                                    <option
                                                        key={zone._id}
                                                        value={zone._id}
                                                    >
                                                        {zone.name}
                                                    </option>
                                                );
                                            })}
                                        </Form.Select>
                                    </Col>
                                </Form.Group>
                            </Col>
                        ) : (
                            ''
                        )}
                        <Col xs="12" xl="3" className="mb-3">
                            <Form.Group as={Row}>
                                <Form.Label column xs="3">
                                    {t(Lang.DIRECTION)}
                                </Form.Label>
                                <Col xs="9">
                                    <Form.Select
                                        onChange={handleChangeDirection}
                                    >
                                        <option value="">
                                            {t(Lang.FEMALE_ALL)}
                                        </option>
                                        {Object.keys(Direction).map(
                                            (direction) => {
                                                return (
                                                    <option
                                                        key={direction}
                                                        value={direction}
                                                    >
                                                        {t(
                                                            langObj[
                                                                direction.toUpperCase()
                                                            ]
                                                        )}
                                                    </option>
                                                );
                                            }
                                        )}
                                    </Form.Select>
                                </Col>
                            </Form.Group>
                        </Col>
                        {cameras.length ? (
                            <Col xs="12" xl="4">
                                <Form.Group as={Row}>
                                    <Form.Label column xs="2">
                                        {t(Lang.CAMERA)}
                                    </Form.Label>
                                    <Col xs="10">
                                        <Form.Select
                                            onChange={handleChangeCamera}
                                        >
                                            <option value="">
                                                {t(Lang.FEMALE_ALL)}
                                            </option>
                                            {cameras.map((camera) => {
                                                return (
                                                    <option
                                                        key={camera._id}
                                                        value={camera._id}
                                                    >
                                                        {camera.name}
                                                    </option>
                                                );
                                            })}
                                        </Form.Select>
                                    </Col>
                                </Form.Group>
                            </Col>
                        ) : (
                            ''
                        )}
                    </Row>
                    {cameras.length && zoneSelected ? (
                        <EventsModal
                            show={showEventModal}
                            handleClose={handleCloseEventModal}
                            reloadEvents={triggerEventFetch}
                            event={eventModal}
                            cameras={cameras}
                            zoneId={zoneSelected}
                        />
                    ) : (
                        ''
                    )}
                    {events.length && cameras.length && zoneSelected ? (
                        <>
                            <Row>
                                <Col>
                                    <EventsTable
                                        events={events}
                                        cameras={cameras}
                                        handleEditEvent={handleOpenEventModal}
                                        reloadEvents={triggerEventFetch}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                {cameras.map((camera) => {
                                    return (
                                        <Col
                                            key={camera._id}
                                            xs="6"
                                            className="p-4"
                                        >
                                            <RealTimeCamera
                                                camera={camera}
                                                zoneId={zoneSelected}
                                                handleEditEvent={
                                                    handleOpenEventModalCamera
                                                }
                                                fetchEvent={fetchEvents}
                                            />
                                        </Col>
                                    );
                                })}
                            </Row>
                        </>
                    ) : (
                        <Loader loading={loading} size={100} color="#0d6efd" />
                    )}
                </Col>
            </Row>
        </>
    );
};

export default RealTimeLayout;
