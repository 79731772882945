import {
    Modal,
    Form,
    InputGroup,
    FormControl,
    Tooltip,
    OverlayTrigger,
    Button,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import * as Lang from '../../../i18n/constants';
import { useForm } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import ErrorField from '../../../components/form/ErrorField';
import Loader from '../../../components/Loader';
import { toast } from 'react-toastify';
import IOption from '../../../services/Option/Option.interface';
import * as Option from '../../../services/Option/Option';

interface SettingModalProps {
    show: boolean;
    handleClose: () => void;
    reloadOptions: () => void;
    option?: IOption | false;
}

const SettingModal = ({
    show,
    handleClose,
    reloadOptions,
    option,
}: SettingModalProps): JSX.Element => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm<IOption>();

    const handleCreateOption = async (data: IOption): Promise<void> => {
        setLoading(true);

        const option: IOption = {
            name: 'descriptionLists',
            value: data.value,
        };

        const createdOption = await Option.createOption(option);

        if (createdOption) {
            toast.success(t(Lang.DESCRIPTION_CREATED_SUCCESSFULLY));
            reloadOptions();
        } else toast.error(t(Lang.ERROR_CREATING_DESCRIPTION));

        setLoading(false);

        handleClose();
    };

    const handleUpdateOption = async (data: IOption): Promise<void> => {
        setLoading(true);

        const option: IOption = {
            _id: data._id,
            name: 'descriptionLists',
            value: data.value,
        };

        const updatedOption = await Option.updateOption(option);

        if (updatedOption) {
            toast.success(t(Lang.DESCRIPTION_UPDATED_SUCCESSFULLY));
            reloadOptions();
        } else toast.error(t(Lang.ERROR_UPDATING_DESCRIPTION));

        setLoading(false);
        handleClose();
    };

    useEffect(() => {
        if (option) reset(option);
        else
            reset({
                name: 'descriptionLists',
                value: '',
            });
    }, [option]);

    return (
        <Modal show={show} onHide={handleClose}>
            <Form
                onSubmit={
                    option
                        ? handleSubmit(handleUpdateOption)
                        : handleSubmit(handleCreateOption)
                }
            >
                <Modal.Header closeButton className="border-0">
                    <Modal.Title>
                        {option
                            ? t(Lang.EDIT_DESCRIPTION)
                            : t(Lang.ADD_DESCRIPTION)}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.DESCRIPTION)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.value
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'align-justify']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <FormControl
                            id="descriptionLists"
                            type="text"
                            maxLength={4096}
                            placeholder={t(Lang.DESCRIPTION)}
                            aria-label={t(Lang.DESCRIPTION)}
                            aria-describedby={t(Lang.DESCRIPTION)}
                            className={
                                errors.value ? 'border-2 border-danger' : ''
                            }
                            {...register('value', {
                                maxLength: {
                                    value: 4096,
                                    message: t(Lang.MAX_LENGTH_FIELD, {
                                        field: t(Lang.DESCRIPTION),
                                        value: 4096,
                                    }),
                                },
                            })}
                        />
                    </InputGroup>
                    {errors.value && (
                        <ErrorField message={errors.value.message} />
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t(Lang.CANCEL)}
                    </Button>
                    <Button
                        type="submit"
                        variant="primary"
                        id="_id"
                        className="btn-modal"
                        {...register('_id')}
                    >
                        {loading ? (
                            <Loader
                                loading={loading}
                                size={25}
                                color="#FFFFFF"
                            />
                        ) : (
                            t(Lang.SAVE)
                        )}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default SettingModal;
