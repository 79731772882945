import { Table, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as Lang from '../../../i18n/constants';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ILog from '../../../services/Log/Log.interface';
import IUser from '../../../services/User/User.interface';
import moment from 'moment';
import LogModal from './LogModal';

interface LogTableProps {
    logs: ILog[];
    users: IUser[];
}

const LogTable = ({ logs, users }: LogTableProps): JSX.Element => {
    const { t } = useTranslation();

    const [showLogModal, setShowLogModal] = useState(false);
    const [oldElement, setOldElement] = useState<object>({});
    const [newElement, setNewElement] = useState<object>({});

    const langObj: { [key: string]: string } = {};

    for (const key of Object.keys(Lang)) langObj[key] = key;

    const handleOpenLogModal = (
        oldObject?: object,
        newObject?: object
    ): void => {
        if (!oldObject || !newObject) return;

        setOldElement(oldObject);
        setNewElement(newObject);
        setShowLogModal(true);
    };

    const handleCloseLogModal = (): void => {
        setOldElement({});
        setNewElement({});
        setShowLogModal(false);
    };

    return (
        <>
            <Table responsive striped hover variant="primary">
                <thead>
                    <tr>
                        <th></th>
                        <th>IP</th>
                        <th>{t(Lang.USER)}</th>
                        <th>{t(Lang.DATE)}</th>
                        <th>{t(Lang.ACTION)}</th>
                        <th>{t(Lang.CATEGORY)}</th>
                        <th>{t(Lang.DESCRIPTION)}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {logs.map((log: ILog) => {
                        return (
                            <tr key={log._id}>
                                <td></td>
                                <td>
                                    {log.ip
                                        .replace('::1', '127.0.0.1')
                                        .replace('::ffff:', '')}
                                </td>
                                <td>{log.user}</td>
                                <td>
                                    {moment(log.createdAt).format(
                                        'DD/MM/YYYY HH:mm:ss'
                                    )}
                                </td>
                                <td>{t(langObj[log.action.toUpperCase()])}</td>
                                <td>
                                    {t(langObj[log.category.toUpperCase()])}
                                </td>
                                <td>{log.description}</td>
                                <td>
                                    {log.oldElement && log.newElement ? (
                                        <Button
                                            variant="link"
                                            onClick={() =>
                                                handleOpenLogModal(
                                                    log.oldElement,
                                                    log.newElement
                                                )
                                            }
                                        >
                                            <FontAwesomeIcon
                                                icon={['fas', 'code-compare']}
                                                size="2x"
                                                fixedWidth
                                            />
                                        </Button>
                                    ) : (
                                        ''
                                    )}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
            <LogModal
                show={showLogModal}
                oldElement={oldElement}
                newElement={newElement}
                users={users}
                handleClose={handleCloseLogModal}
            />
        </>
    );
};

export default LogTable;
