import IUser from '../User/User.interface';

export enum LogCategory {
    alerts = 'alerts',
    cameras = 'cameras',
    events = 'events',
    lists = 'lists',
    options = 'options',
    panels = 'panels',
    plates = 'plates',
    raspberries = 'raspberries',
    users = 'users',
    login = 'login',
    warnings = 'warnings',
    zones = 'zones',
}

export enum LogAction {
    create = 'create',
    get = 'get',
    update = 'update',
    delete = 'delete',
}

export default interface ILog {
    _id?: string;
    user: IUser['_id'];
    ip: string;
    action: LogAction;
    category: string;
    description: string;
    newElement?: object;
    oldElement?: object;
    createdAt?: Date;
    updatedAt?: Date;
}
