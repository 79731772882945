import React, { useEffect, useState } from 'react';
import Header from '../../components/header/Header';
import Menu from '../../components/menu/Menu';
import IUser, { Role } from '../../services/User/User.interface';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as Lang from '../../i18n/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IOption, {
    RestrictionMode,
} from '../../services/Option/Option.interface';
import * as Option from '../../services/Option/Option';
import { useForm } from 'react-hook-form';
import Loader from '../../components/Loader';
import { toast } from 'react-toastify';
import SettingModal from './components/SettingModal';
import SettingsTable from './components/SettingsTable';
import ErrorField from '../../components/form/ErrorField';
import { useNavigate } from 'react-router-dom';
import * as URL from '../../routes/URL';

interface SettingsLayoutProps {
    user?: IUser;
}

type settingsForm = {
    refreshTime: string;
    maxTimeFailure: string;
    emailAlert: string;
    restrictionMode: RestrictionMode;
    gmailUser: string;
    gmailPassword: string;
    timeBetweenEvents: string;
    panelsLine1: string;
    panelsLine2: string;
    panelsLine3: string;
    mapCoordinates: string;
    termsOfUse: string;
    passwordExpirationTime: string;
    dangerousGoodsWeb: string;
    dangerousGoodsEmail: string;
    dangerousGoodsEmails: string;
    dangerousGoodsCustomEmail: string;
    oppositeDirectionWeb: string;
    oppositeDirectionEmail: string;
    oppositeDirectionEmails: string;
    oppositeDirectionCustomEmail: string;
    listWeb: string;
    listEmail: string;
    whiteListCustomEmail: string;
    blackListCustomEmail: string;
    environmentalCustomEmail: string;
    outOfTimeCustomEmail: string;
    eventsDeletionTime: string;
    sanctionsDeletionTime: string;
    facesPixelation: string;
    framePixelation: string;
};

const SettingsLayout = ({ user }: SettingsLayoutProps): JSX.Element => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState<IOption[]>([]);
    const [optionsDescription, setOptionsDescription] = useState<IOption[]>([]);
    const [showSettingModal, setShowSettingModal] = useState(false);
    const [settingModal, setSettingModal] = useState<IOption | false>();
    const [fetchOptions, setFetchOptions] = useState(true);

    const triggerOptionFetch = () => setFetchOptions((t) => !t);

    const langObj: { [key: string]: string } = {};

    for (const key of Object.keys(Lang)) langObj[key] = key;

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm<settingsForm>();

    const handleSubmitOptions = async (data: settingsForm): Promise<void> => {
        setLoading(true);

        const promises = [];

        for (const [name, value] of Object.entries(data)) {
            const foundOption = options.find(
                (option: IOption) => option.name === name
            );

            if (foundOption) {
                if (
                    [
                        'dangerousGoodsWeb',
                        'dangerousGoodsEmail',
                        'oppositeDirectionWeb',
                        'oppositeDirectionEmail',
                        'listWeb',
                        'listEmail',
                        'facesPixelation',
                        'framePixelation',
                    ].includes(foundOption.name)
                )
                    foundOption.value = value || 'false';
                else foundOption.value = value;
                promises.push(Option.updateOption(foundOption));
            } else {
                const newOption: IOption = {
                    name,
                    value,
                };
                promises.push(Option.createOption(newOption));
            }
        }

        const results = await Promise.all(promises);

        let done = true;

        for (const result of results) {
            if (!result) done = false;
        }

        if (done) toast.success(t(Lang.SAVED_SUCCESSFULLY));
        else toast.error(t(Lang.FAILED_TO_SAVE));

        setLoading(false);
        triggerOptionFetch();
    };

    const handleOpenSettingModal = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        const button: HTMLButtonElement = event.currentTarget;

        const id = button.value;

        if (id) {
            const option = options.find((option) => option._id === id);
            setSettingModal(option);
        } else setSettingModal(undefined);

        setShowSettingModal(true);
    };

    const handleCloseSettingModal = (): void => {
        setSettingModal(false);
        setShowSettingModal(false);
    };

    const handleChangeRestriction = (
        event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        const textLine3 = '';
        /*for (const label of [
            'zeroLabel',
            'EcoLabel',
            'BLabel',
            'CLabel',
            'withoutLabel',
            'foreignLabel',
        ]) {
            if (getValues(label as keyof settingsForm) === 'true') {
                textLine3 += `${t(
                    langObj[label.replace('Label', '').toUpperCase()]
                )}, `;
            }
        }*/

        switch (event.target.value) {
            case RestrictionMode.environmental:
                setValue('panelsLine1', 'Restricción Medioambiental');
                setValue(
                    'panelsLine2',
                    'Las siguientes etiquetas no pueden acceder'
                );
                setValue('panelsLine3', textLine3);
                break;
            case RestrictionMode.timeControl_Environmental:
                setValue('panelsLine1', 'Restricción Medioambiental y Horaria');
                setValue(
                    'panelsLine2',
                    'Las siguientes etiquetas no pueden acceder'
                );
                setValue('panelsLine3', textLine3);
                break;
            case RestrictionMode.timeControl:
                setValue('panelsLine1', 'Restricción Horaria');
                setValue('panelsLine2', '');
                setValue('panelsLine3', '');
                break;
            case RestrictionMode.permanent:
                setValue(
                    'panelsLine1',
                    'Restricción mediante listas de control'
                );
                setValue('panelsLine2', '');
                setValue('panelsLine3', '');
        }
    };

    useEffect(() => {
        if (user?.role !== Role.admin) navigate(URL.BASE);
    }, []);

    useEffect(() => {
        const loadOptions = async () => {
            const options = await Option.getOptions();

            if (options) {
                for (const option of options) {
                    if (
                        [
                            'dangerousGoodsWeb',
                            'dangerousGoodsEmail',
                            'oppositeDirectionWeb',
                            'oppositeDirectionEmail',
                            'listWeb',
                            'listEmail',
                            'facesPixelation',
                            'framePixelation',
                        ].includes(option.name)
                    ) {
                        if (option.value === 'false') option.value = '';

                        setValue(
                            option.name as keyof settingsForm,
                            option.value
                        );
                    }
                    setValue(option.name as keyof settingsForm, option.value);
                }
                setOptions(options);
                const optionsDescription: IOption[] = options.filter(
                    (option) => option.name === 'descriptionLists'
                );
                setOptionsDescription(optionsDescription);
            }
        };

        loadOptions();
    }, [fetchOptions]);

    return (
        <>
            <Header user={user} />
            <Row className="h-100">
                <Col xs="3" xl="2" className="p-0 sidebar d-none d-xl-block">
                    {user ? (
                        <Menu currentPage={t(Lang.SETTINGS)} user={user} />
                    ) : (
                        ''
                    )}
                </Col>
                <Col xs="12" xl="10" className="p-3 pe-xl-4">
                    <Row className="p-3">
                        <Col xs="12" xl="6" className="mb-3">
                            <Form onSubmit={handleSubmit(handleSubmitOptions)}>
                                <Form.Group
                                    className="mb-3"
                                    controlId="refreshTime"
                                >
                                    <Form.Label>
                                        {t(Lang.REFRESH_TIME)}
                                    </Form.Label>
                                    <Form.Control
                                        type="number"
                                        min={5}
                                        max={3600}
                                        className={
                                            errors.refreshTime
                                                ? 'border-2 border-danger'
                                                : ''
                                        }
                                        {...register('refreshTime', {
                                            min: {
                                                value: 5,
                                                message: t(Lang.MIN_FIELD, {
                                                    field: t(Lang.REFRESH_TIME),
                                                    value: 5,
                                                }),
                                            },
                                            max: {
                                                value: 3600,
                                                message: t(Lang.MAX_FIELD, {
                                                    field: t(Lang.REFRESH_TIME),
                                                    value: 3600,
                                                }),
                                            },
                                        })}
                                    />
                                </Form.Group>
                                {errors.refreshTime && (
                                    <ErrorField
                                        message={errors.refreshTime.message}
                                    />
                                )}
                                <Form.Group
                                    className="mb-3"
                                    controlId="maxTimeFailure"
                                >
                                    <Form.Label>
                                        {t(Lang.MAX_TIME_FAILURE)}
                                    </Form.Label>
                                    <Form.Control
                                        type="number"
                                        min={5}
                                        max={10080}
                                        className={
                                            errors.maxTimeFailure
                                                ? 'border-2 border-danger'
                                                : ''
                                        }
                                        {...register('maxTimeFailure', {
                                            min: {
                                                value: 5,
                                                message: t(Lang.MIN_FIELD, {
                                                    field: t(
                                                        Lang.MAX_TIME_FAILURE
                                                    ),
                                                    value: 5,
                                                }),
                                            },
                                            max: {
                                                value: 10080,
                                                message: t(Lang.MAX_FIELD, {
                                                    field: t(
                                                        Lang.MAX_TIME_FAILURE
                                                    ),
                                                    value: 10080,
                                                }),
                                            },
                                        })}
                                    />
                                </Form.Group>
                                {errors.maxTimeFailure && (
                                    <ErrorField
                                        message={errors.maxTimeFailure.message}
                                    />
                                )}
                                <Form.Group
                                    className="mb-3"
                                    controlId="timeBetweenEvents"
                                >
                                    <Form.Label>
                                        {t(Lang.TIME_BETWEEEN_EVENTS)}
                                    </Form.Label>
                                    <Form.Control
                                        type="number"
                                        min={1}
                                        max={3600}
                                        className={
                                            errors.timeBetweenEvents
                                                ? 'border-2 border-danger'
                                                : ''
                                        }
                                        {...register('timeBetweenEvents', {
                                            min: {
                                                value: 1,
                                                message: t(Lang.MIN_FIELD, {
                                                    field: t(
                                                        Lang.TIME_BETWEEEN_EVENTS
                                                    ),
                                                    value: 1,
                                                }),
                                            },
                                            max: {
                                                value: 3600,
                                                message: t(Lang.MAX_FIELD, {
                                                    field: t(
                                                        Lang.TIME_BETWEEEN_EVENTS
                                                    ),
                                                    value: 3600,
                                                }),
                                            },
                                        })}
                                    />
                                </Form.Group>
                                {errors.timeBetweenEvents && (
                                    <ErrorField
                                        message={
                                            errors.timeBetweenEvents.message
                                        }
                                    />
                                )}
                                <Form.Group
                                    className="mb-3"
                                    controlId="emailAlert"
                                >
                                    <Form.Label>
                                        {t(
                                            Lang.EMAIL_TO_RECEIVE_TECHNICAL_ALERTS
                                        )}
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        maxLength={4096}
                                        className={
                                            errors.emailAlert
                                                ? 'border-2 border-danger'
                                                : ''
                                        }
                                        {...register('emailAlert', {
                                            maxLength: {
                                                value: 4096,
                                                message: t(
                                                    Lang.MAX_LENGTH_FIELD,
                                                    {
                                                        field: t(
                                                            Lang.EMAIL_TO_RECEIVE_TECHNICAL_ALERTS
                                                        ),
                                                        value: 4096,
                                                    }
                                                ),
                                            },
                                        })}
                                    />
                                </Form.Group>
                                {errors.emailAlert && (
                                    <ErrorField
                                        message={errors.emailAlert.message}
                                    />
                                )}
                                <Form.Group
                                    className="mb-3"
                                    controlId="mapCoordinates"
                                >
                                    <Form.Label>
                                        {t(Lang.MAP_COORDINATES)}
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        className={
                                            errors.mapCoordinates
                                                ? 'border-2 border-danger'
                                                : ''
                                        }
                                        {...register('mapCoordinates', {
                                            maxLength: {
                                                value: 128,
                                                message: t(
                                                    Lang.MAX_LENGTH_FIELD,
                                                    {
                                                        field: t(
                                                            Lang.MAP_COORDINATES
                                                        ),
                                                        value: 128,
                                                    }
                                                ),
                                            },
                                        })}
                                    />
                                </Form.Group>
                                {errors.mapCoordinates && (
                                    <ErrorField
                                        message={errors.mapCoordinates.message}
                                    />
                                )}
                                <Form.Group
                                    className="mb-3"
                                    controlId="restrictionMode"
                                >
                                    <Form.Label>
                                        {t(Lang.RESTRICTION_MODE)}
                                    </Form.Label>
                                    <Form.Select
                                        className={
                                            errors.restrictionMode
                                                ? 'border-2 border-danger'
                                                : ''
                                        }
                                        {...register('restrictionMode')}
                                        onChange={handleChangeRestriction}
                                    >
                                        {Object.keys(RestrictionMode).map(
                                            (mode: string) => {
                                                return (
                                                    <option
                                                        key={mode}
                                                        value={mode}
                                                    >
                                                        {t(
                                                            langObj[
                                                                mode.toUpperCase()
                                                            ]
                                                        )}
                                                    </option>
                                                );
                                            }
                                        )}
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t(Lang.PANELS)}</Form.Label>
                                    <Row className="border rounded py-3 mx-0">
                                        <Col>
                                            <Form.Control
                                                type="text"
                                                id="panelsLine1"
                                                placeholder={`${t(
                                                    Lang.LINE
                                                )} 1`}
                                                className="mb-1"
                                                {...register('panelsLine1')}
                                            />
                                            <Form.Control
                                                type="text"
                                                id="panelsLine2"
                                                placeholder={`${t(
                                                    Lang.LINE
                                                )} 2`}
                                                className="mb-1"
                                                {...register('panelsLine2')}
                                            />
                                            <Form.Control
                                                type="text"
                                                id="panelsLine3"
                                                placeholder={`${t(
                                                    Lang.LINE
                                                )} 3`}
                                                className="mb-1"
                                                {...register('panelsLine3')}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Row>
                                    <Col>
                                        <Form.Label>
                                            {t(Lang.GMAIL_EMAIL_CONFIGURATION)}
                                        </Form.Label>
                                    </Col>
                                </Row>
                                <Row className="mb-3 border rounded py-3 mx-0">
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>
                                                {t(Lang.USER)}
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                id="gmailUser"
                                                maxLength={256}
                                                {...register('gmailUser', {
                                                    maxLength: {
                                                        value: 256,
                                                        message: t(
                                                            Lang.MAX_LENGTH_FIELD,
                                                            {
                                                                field: t(
                                                                    Lang.USER
                                                                ),
                                                                value: 256,
                                                            }
                                                        ),
                                                    },
                                                })}
                                            />
                                        </Form.Group>
                                        {errors.gmailUser && (
                                            <ErrorField
                                                message={
                                                    errors.gmailUser.message
                                                }
                                            />
                                        )}
                                        <Form.Group>
                                            <Form.Label>
                                                {t(Lang.PASSWORD)}
                                            </Form.Label>
                                            <Form.Control
                                                type="password"
                                                id="gmailPassword"
                                                maxLength={128}
                                                {...register('gmailPassword', {
                                                    maxLength: {
                                                        value: 128,
                                                        message: t(
                                                            Lang.MAX_LENGTH_FIELD,
                                                            {
                                                                field: t(
                                                                    Lang.PASSWORD
                                                                ),
                                                                value: 128,
                                                            }
                                                        ),
                                                    },
                                                })}
                                            />
                                        </Form.Group>
                                        {errors.gmailPassword && (
                                            <ErrorField
                                                message={
                                                    errors.gmailPassword.message
                                                }
                                            />
                                        )}
                                    </Col>
                                </Row>
                                <Form.Group
                                    controlId="termsOfUse"
                                    className="mb-3"
                                >
                                    <Form.Label>
                                        {t(Lang.TERMS_OF_USE)}
                                    </Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        maxLength={4000}
                                        rows={10}
                                        {...register('termsOfUse', {
                                            maxLength: {
                                                value: 4000,
                                                message: t(
                                                    Lang.MAX_LENGTH_FIELD,
                                                    {
                                                        field: t(
                                                            Lang.TERMS_OF_USE
                                                        ),
                                                        value: 4000,
                                                    }
                                                ),
                                            },
                                        })}
                                    />
                                </Form.Group>
                                {errors.termsOfUse && (
                                    <ErrorField
                                        message={errors.termsOfUse.message}
                                    />
                                )}
                                <Form.Group
                                    controlId="passwordExpirationTime"
                                    className="mb-3"
                                >
                                    <Form.Label>
                                        {t(Lang.PASSWORD_EXPIRATION_TIME)}
                                    </Form.Label>
                                    <Form.Control
                                        type="number"
                                        min={0}
                                        max={60}
                                        {...register('passwordExpirationTime', {
                                            min: {
                                                value: 0,
                                                message: t(Lang.MIN_FIELD, {
                                                    field: t(
                                                        Lang.PASSWORD_EXPIRATION_TIME
                                                    ),
                                                    value: 0,
                                                }),
                                            },
                                            max: {
                                                value: 60,
                                                message: t(Lang.MAX_FIELD, {
                                                    field: t(
                                                        Lang.PASSWORD_EXPIRATION_TIME
                                                    ),
                                                    value: 60,
                                                }),
                                            },
                                        })}
                                    />
                                </Form.Group>
                                {errors.passwordExpirationTime && (
                                    <ErrorField
                                        message={
                                            errors.passwordExpirationTime
                                                .message
                                        }
                                    />
                                )}
                                <Form.Group
                                    controlId="eventsDeletionTime"
                                    className="mb-3"
                                >
                                    <Form.Label>
                                        {t(Lang.EVENTS_DELETION_TIME)}
                                    </Form.Label>
                                    <Form.Control
                                        type="number"
                                        min={0}
                                        max={300}
                                        {...register('eventsDeletionTime', {
                                            min: {
                                                value: 0,
                                                message: t(Lang.MIN_FIELD, {
                                                    field: t(
                                                        Lang.EVENTS_DELETION_TIME
                                                    ),
                                                    value: 0,
                                                }),
                                            },
                                            max: {
                                                value: 300,
                                                message: t(Lang.MAX_FIELD, {
                                                    field: t(
                                                        Lang.EVENTS_DELETION_TIME
                                                    ),
                                                    value: 300,
                                                }),
                                            },
                                        })}
                                    />
                                </Form.Group>
                                {errors.eventsDeletionTime && (
                                    <ErrorField
                                        message={
                                            errors.eventsDeletionTime.message
                                        }
                                    />
                                )}
                                <Form.Group
                                    controlId="sanctionsDeletionTime"
                                    className="mb-3"
                                >
                                    <Form.Label>
                                        {t(Lang.SANCTIONS_DELETION_TIME)}
                                    </Form.Label>
                                    <Form.Control
                                        type="number"
                                        min={0}
                                        max={300}
                                        {...register('sanctionsDeletionTime', {
                                            min: {
                                                value: 0,
                                                message: t(Lang.MIN_FIELD, {
                                                    field: t(
                                                        Lang.SANCTIONS_DELETION_TIME
                                                    ),
                                                    value: 0,
                                                }),
                                            },
                                            max: {
                                                value: 300,
                                                message: t(Lang.MAX_FIELD, {
                                                    field: t(
                                                        Lang.SANCTIONS_DELETION_TIME
                                                    ),
                                                    value: 300,
                                                }),
                                            },
                                        })}
                                    />
                                </Form.Group>
                                {errors.sanctionsDeletionTime && (
                                    <ErrorField
                                        message={
                                            errors.sanctionsDeletionTime.message
                                        }
                                    />
                                )}
                                <Form.Group className="mb-3">
                                    <Form.Label>
                                        {t(Lang.ALERTS)}{' '}
                                        {t(Lang.DANGEROUSGOODS)}
                                    </Form.Label>
                                    <Row className="border rounded py-3 mx-0">
                                        <Col>
                                            <Row className="mb-3">
                                                <Col xs="6">
                                                    <Form.Check
                                                        type="checkbox"
                                                        id="dangerousGoodsWeb"
                                                        label={t(
                                                            Lang.WEB_ALERT
                                                        )}
                                                        {...register(
                                                            'dangerousGoodsWeb'
                                                        )}
                                                    />
                                                </Col>
                                                <Col xs="6">
                                                    <Form.Check
                                                        type="checkbox"
                                                        id="dangerousGoodsEmail"
                                                        label={t(
                                                            Lang.EMAIL_ALERT
                                                        )}
                                                        {...register(
                                                            'dangerousGoodsEmail'
                                                        )}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Col>
                                                    <Form.Label>
                                                        {t(
                                                            Lang.SEMICOLON_SEPARATED_EMAILS
                                                        )}
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        maxLength={4096}
                                                        {...register(
                                                            'dangerousGoodsEmails',
                                                            {
                                                                maxLength: {
                                                                    value: 4096,
                                                                    message: t(
                                                                        Lang.MAX_LENGTH_FIELD,
                                                                        {
                                                                            field: t(
                                                                                Lang.SEMICOLON_SEPARATED_EMAILS
                                                                            ),
                                                                            value: 4096,
                                                                        }
                                                                    ),
                                                                },
                                                            }
                                                        )}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col>
                                                    <Form.Label>
                                                        {t(Lang.EMAIL_ALERT)}{' '}
                                                        {t(Lang.CUSTOM_TEXT)}
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        maxLength={4096}
                                                        placeholder="%kemler, %camera"
                                                        {...register(
                                                            'dangerousGoodsCustomEmail',
                                                            {
                                                                maxLength: {
                                                                    value: 4096,
                                                                    message: t(
                                                                        Lang.MAX_LENGTH_FIELD,
                                                                        {
                                                                            field: t(
                                                                                Lang.CUSTOM_TEXT
                                                                            ),
                                                                            value: 4096,
                                                                        }
                                                                    ),
                                                                },
                                                            }
                                                        )}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>
                                        {t(Lang.ALERTS)}{' '}
                                        {t(Lang.OPPOSITEDIRECTION)}
                                    </Form.Label>
                                    <Row className="border rounded py-3 mx-0">
                                        <Col>
                                            <Row className="mb-3">
                                                <Col xs="6">
                                                    <Form.Check
                                                        type="checkbox"
                                                        id="oppositeDirectionWeb"
                                                        label={t(
                                                            Lang.WEB_ALERT
                                                        )}
                                                        {...register(
                                                            'oppositeDirectionWeb'
                                                        )}
                                                    />
                                                </Col>
                                                <Col xs="6">
                                                    <Form.Check
                                                        type="checkbox"
                                                        id="oppositeDirectionEmail"
                                                        label={t(
                                                            Lang.EMAIL_ALERT
                                                        )}
                                                        {...register(
                                                            'oppositeDirectionEmail'
                                                        )}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Col>
                                                    <Form.Label>
                                                        {t(
                                                            Lang.SEMICOLON_SEPARATED_EMAILS
                                                        )}
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        maxLength={4096}
                                                        {...register(
                                                            'oppositeDirectionEmails',
                                                            {
                                                                maxLength: {
                                                                    value: 4096,
                                                                    message: t(
                                                                        Lang.MAX_LENGTH_FIELD,
                                                                        {
                                                                            field: t(
                                                                                Lang.SEMICOLON_SEPARATED_EMAILS
                                                                            ),
                                                                            value: 4096,
                                                                        }
                                                                    ),
                                                                },
                                                            }
                                                        )}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Form.Label>
                                                        {t(Lang.EMAIL_ALERT)}{' '}
                                                        {t(Lang.CUSTOM_TEXT)}
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        maxLength={4096}
                                                        placeholder="%plate, %camera"
                                                        {...register(
                                                            'oppositeDirectionCustomEmail',
                                                            {
                                                                maxLength: {
                                                                    value: 4096,
                                                                    message: t(
                                                                        Lang.MAX_LENGTH_FIELD,
                                                                        {
                                                                            field: t(
                                                                                Lang.CUSTOM_TEXT
                                                                            ),
                                                                            value: 4096,
                                                                        }
                                                                    ),
                                                                },
                                                            }
                                                        )}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>
                                        {t(Lang.ALERTS)} {t(Lang.LISTS)}
                                    </Form.Label>
                                    <Row className="border rounded py-3 mx-0">
                                        <Col>
                                            <Row className="mb-3">
                                                <Col xs="6">
                                                    <Form.Check
                                                        type="checkbox"
                                                        id="listWeb"
                                                        label={t(
                                                            Lang.WEB_ALERT
                                                        )}
                                                        {...register('listWeb')}
                                                    />
                                                </Col>
                                                <Col xs="6">
                                                    <Form.Check
                                                        type="checkbox"
                                                        id="listEmail"
                                                        label={t(
                                                            Lang.EMAIL_ALERT
                                                        )}
                                                        {...register(
                                                            'listEmail'
                                                        )}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Col>
                                                    <Form.Label>
                                                        {t(Lang.EMAIL_ALERT)}{' '}
                                                        {t(Lang.WHITELIST)}{' '}
                                                        {t(Lang.CUSTOM_TEXT)}
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        maxLength={4096}
                                                        placeholder="%plate, %list, %camera"
                                                        {...register(
                                                            'whiteListCustomEmail',
                                                            {
                                                                maxLength: {
                                                                    value: 4096,
                                                                    message: t(
                                                                        Lang.MAX_LENGTH_FIELD,
                                                                        {
                                                                            field: t(
                                                                                Lang.CUSTOM_TEXT
                                                                            ),
                                                                            value: 4096,
                                                                        }
                                                                    ),
                                                                },
                                                            }
                                                        )}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Col>
                                                    <Form.Label>
                                                        {t(Lang.EMAIL_ALERT)}{' '}
                                                        {t(Lang.BLACKLIST)}{' '}
                                                        {t(Lang.CUSTOM_TEXT)}
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        maxLength={4096}
                                                        placeholder="%plate, %list, %camera"
                                                        {...register(
                                                            'blackListCustomEmail',
                                                            {
                                                                maxLength: {
                                                                    value: 4096,
                                                                    message: t(
                                                                        Lang.MAX_LENGTH_FIELD,
                                                                        {
                                                                            field: t(
                                                                                Lang.CUSTOM_TEXT
                                                                            ),
                                                                            value: 4096,
                                                                        }
                                                                    ),
                                                                },
                                                            }
                                                        )}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Col>
                                                    <Form.Label>
                                                        {t(Lang.EMAIL_ALERT)}{' '}
                                                        {t(Lang.ENVIRONMENTAL)}{' '}
                                                        {t(Lang.CUSTOM_TEXT)}
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        maxLength={4096}
                                                        placeholder="%plate, %list, %camera, %label"
                                                        {...register(
                                                            'environmentalCustomEmail',
                                                            {
                                                                maxLength: {
                                                                    value: 4096,
                                                                    message: t(
                                                                        Lang.MAX_LENGTH_FIELD,
                                                                        {
                                                                            field: t(
                                                                                Lang.CUSTOM_TEXT
                                                                            ),
                                                                            value: 4096,
                                                                        }
                                                                    ),
                                                                },
                                                            }
                                                        )}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Form.Label>
                                                        {t(Lang.EMAIL_ALERT)}{' '}
                                                        {t(Lang.OUTOFTIME)}{' '}
                                                        {t(Lang.CUSTOM_TEXT)}
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        maxLength={4096}
                                                        placeholder="%plate, %list, %camera"
                                                        {...register(
                                                            'outOfTimeCustomEmail',
                                                            {
                                                                maxLength: {
                                                                    value: 4096,
                                                                    message: t(
                                                                        Lang.MAX_LENGTH_FIELD,
                                                                        {
                                                                            field: t(
                                                                                Lang.CUSTOM_TEXT
                                                                            ),
                                                                            value: 4096,
                                                                        }
                                                                    ),
                                                                },
                                                            }
                                                        )}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>
                                        {t(Lang.IMAGES_PIXELATION)}
                                    </Form.Label>
                                    <Row className="border rounded py-3 mx-0">
                                        <Col>
                                            <Form.Check
                                                type="checkbox"
                                                id="facesPixelation"
                                                label={t(Lang.FACES)}
                                                {...register('facesPixelation')}
                                            />
                                        </Col>
                                        <Col>
                                            <Form.Check
                                                type="checkbox"
                                                id="framePixelation"
                                                label={t(Lang.FRAME)}
                                                {...register('framePixelation')}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Button
                                    type="submit"
                                    variant="primary"
                                    className="btn-modal"
                                >
                                    {loading ? (
                                        <Loader
                                            loading={loading}
                                            size={25}
                                            color="#FFFFFF"
                                        />
                                    ) : (
                                        t(Lang.SAVE)
                                    )}
                                </Button>
                            </Form>
                        </Col>
                        <Col xs="12" xl="6">
                            <Row>
                                <Col>
                                    <Form.Label>
                                        {t(Lang.LIST_DESCRIPTIONS)}
                                    </Form.Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Button
                                        variant="link"
                                        className="btn-add"
                                        onClick={handleOpenSettingModal}
                                    >
                                        <FontAwesomeIcon
                                            icon={['fas', 'plus']}
                                            size="2x"
                                            fixedWidth
                                        />
                                    </Button>
                                    <SettingModal
                                        show={showSettingModal}
                                        handleClose={handleCloseSettingModal}
                                        reloadOptions={triggerOptionFetch}
                                        option={settingModal}
                                    />
                                </Col>
                            </Row>
                            {optionsDescription.length ? (
                                <Row>
                                    <Col>
                                        <SettingsTable
                                            options={optionsDescription}
                                            handleEditOption={
                                                handleOpenSettingModal
                                            }
                                            reloadOptions={triggerOptionFetch}
                                        />
                                    </Col>
                                </Row>
                            ) : (
                                <Loader
                                    loading={loading}
                                    size={100}
                                    color="#0d6efd"
                                />
                            )}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};

export default SettingsLayout;
