import axios, { AxiosResponse } from 'axios';
import * as Auth from '../Authentication';
import baseURL from '../BaseURL';
import ISensorEvent from './SensorEvent.interface';
import { AnalyticsSensorForm } from '../../pages/Analytics/AnalyticsLayout';

const apiURL = `${baseURL}/sensorevents`;

export const createSensorEvent = async (
    sensorEvent: ISensorEvent
): Promise<ISensorEvent | false> => {
    try {
        const response: AxiosResponse = await axios.post(apiURL, sensorEvent, {
            headers: Auth.authHeader(),
        });

        if (response.data) {
            const createdSensorEvent: ISensorEvent = response.data;
            return createdSensorEvent;
        } else return false;
    } catch (error) {
        console.error(error);
        return false;
    }
};

export const getSensorEvents = async (
    filter?: AnalyticsSensorForm
): Promise<ISensorEvent[] | false> => {
    try {
        const response: AxiosResponse = await axios.get(apiURL, {
            headers: Auth.authHeader(),
            params: filter,
        });

        if (response.data) {
            const sensorEvents: ISensorEvent[] = response.data;
            return sensorEvents;
        } else return false;
    } catch (error) {
        console.error(error);
        return false;
    }
};

export const getSensorEvent = async (
    id: string
): Promise<ISensorEvent | false> => {
    try {
        const response: AxiosResponse = await axios.get(`${apiURL}/${id}`, {
            headers: Auth.authHeader(),
        });

        if (response.data) {
            const sensorEvent: ISensorEvent = response.data;
            return sensorEvent;
        } else return false;
    } catch (error) {
        console.error(error);
        return false;
    }
};

export const updateSensorEvent = async (
    sensorEvent: ISensorEvent
): Promise<ISensorEvent | false> => {
    try {
        const response: AxiosResponse = await axios.put(
            `${apiURL}/${sensorEvent._id}`,
            sensorEvent,
            { headers: Auth.authHeader() }
        );

        if (response.data) {
            const updatedSensorEvent: ISensorEvent = response.data;
            return updatedSensorEvent;
        } else return false;
    } catch (error) {
        console.error(error);
        return false;
    }
};

export const deleteSensorEvent = async (
    id: string
): Promise<ISensorEvent | false> => {
    try {
        const response: AxiosResponse = await axios.delete(`${apiURL}/${id}`, {
            headers: Auth.authHeader(),
        });

        if (response.data) {
            const deletedSensorEvent: ISensorEvent = response.data;
            return deletedSensorEvent;
        } else return false;
    } catch (error) {
        console.error(error);
        return false;
    }
};
