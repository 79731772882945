import { Modal, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as Lang from '../../../i18n/constants';
import { useEffect, useState } from 'react';
import IEvent from '../../../services/Event/Event.interface';
import * as Event from '../../../services/Event/Event';
import ICamera from '../../../services/Camera/Camera.interface';
import config from '../../../configuration';
import IAlert from '../../../services/Alert/Alert.interface';
import moment from 'moment';

interface AlertModalProps {
    show: boolean;
    handleClose: () => void;
    alert: IAlert;
    cameras: ICamera[];
}

const AlertModal = ({ show, handleClose, alert, cameras }: AlertModalProps) => {
    const { t } = useTranslation();
    const [event, setEvent] = useState<IEvent>();

    const langObj: { [key: string]: string } = {};

    for (const key of Object.keys(Lang)) langObj[key] = key;

    const protocol = config.frontend.protocol;

    const camera = cameras.filter((camera) => camera._id === alert.camera);

    useEffect(() => {
        const loadEvent = async () => {
            if (alert.event) {
                const event = await Event.getEvent(alert.event);

                if (event) setEvent(event);
            }
        };

        loadEvent();
    }, []);

    return (
        <Modal show={show} onHide={handleClose} dialogClassName="large-modal">
            <Modal.Header closeButton className="border-0">
                <Modal.Title>{t(Lang.VIEW_ALERT)}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="mb-3">
                    <Col>
                        <label>
                            {t(Lang.PLATE)}: {alert.plate}
                        </label>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <label>
                            {t(Lang.CAMERA)}:{' '}
                            {camera.length ? camera[0].name : ''}
                        </label>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <label>
                            {t(Lang.DIRECTION)}:{' '}
                            {t(langObj[alert.direction.toUpperCase()])}
                        </label>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <label>
                            {t(Lang.DATE)}:{' '}
                            {moment(alert.createdAt).format(
                                'DD/MM/YYYY HH:mm:ss'
                            )}
                        </label>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <label>
                            {t(Lang.REASON)}:{' '}
                            {t(langObj[alert.reason.toUpperCase()])}
                        </label>
                    </Col>
                </Row>
                {event ? (
                    <>
                        {event.imagePlateURL && event.imagePlateURL.length ? (
                            <Row className="mb-3">
                                <Col xs="6">
                                    <img
                                        src={`${protocol}://${config.backend.host}:${config.backend.port}/${event.imagePlateURL}`}
                                        className="w-100 card"
                                    />
                                </Col>
                            </Row>
                        ) : (
                            ''
                        )}
                        {event.imageOCRURL && event.imageOCRURL.length ? (
                            <Row className="mb-3">
                                <Col xs="6">
                                    <img
                                        src={`${protocol}://${config.backend.host}:${config.backend.port}/${event.imageOCRURL}`}
                                        className="w-100 card"
                                    />
                                </Col>
                            </Row>
                        ) : (
                            ''
                        )}
                        {event.imagePixelatedURL &&
                        event.imagePixelatedURL.length ? (
                            <Row className="mb-3">
                                <Col xs="6">
                                    <img
                                        src={`${protocol}://${config.backend.host}:${config.backend.port}/${event.imagePixelatedURL}`}
                                        className="w-100 card"
                                    />
                                </Col>
                            </Row>
                        ) : (
                            ''
                        )}
                        {event.imageContextURL &&
                        event.imageContextURL.length ? (
                            <Row className="mb-3">
                                <Col xs="12">
                                    <img
                                        src={`${protocol}://${config.backend.host}:${config.backend.port}/${event.imageContextURL}`}
                                        className="w-100 card"
                                    />
                                </Col>
                            </Row>
                        ) : (
                            ''
                        )}
                    </>
                ) : (
                    ''
                )}
            </Modal.Body>
        </Modal>
    );
};

export default AlertModal;
