import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import Header from '../../components/header/Header';
import Menu from '../../components/menu/Menu';
import IUser, { Role } from '../../services/User/User.interface';
import * as User from '../../services/User/User';
import UsersTable from './components/UsersTable';
import { useTranslation } from 'react-i18next';
import * as Lang from '../../i18n/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UserModal from './components/UserModal';
import Loader from '../../components/Loader';
import { useNavigate } from 'react-router-dom';
import * as URL from '../../routes/URL';

interface UsersLayoutProps {
    user?: IUser;
}

const UsersLayout = ({ user }: UsersLayoutProps): JSX.Element => {
    const navigate = useNavigate();

    const [users, setUsers] = useState<IUser[]>([]);
    const [loading, setLoading] = useState(true);

    const [showUserModal, setShowUserModal] = useState(false);
    const [userModal, setUserModal] = useState<IUser | false>();

    const [fetchUsers, setFetchUsers] = useState(true);

    const { t } = useTranslation();

    const triggerUserFetch = () => setFetchUsers((t) => !t);

    const handleOpenUserModal = (
        event: React.MouseEvent<HTMLButtonElement>
    ): void => {
        const button: HTMLButtonElement = event.currentTarget;

        const id = button.value;

        if (id) {
            const user = users.find((user) => user._id === id);
            setUserModal(user);
        } else setUserModal(undefined);

        setShowUserModal(true);
    };

    const handleCloseUserModal = (): void => {
        setUserModal(false);
        setShowUserModal(false);
    };

    useEffect(() => {
        if (user?.role !== Role.admin) navigate(URL.BASE);
    }, []);

    useEffect(() => {
        const loadUsers = async () => {
            const users = await User.getUsers();
            if (users) {
                setUsers(users);
                setLoading(false);
            }
        };

        loadUsers();
    }, [fetchUsers]);

    return (
        <>
            <Header user={user} />
            <Row className="h-100">
                <Col xs="3" xl="2" className="p-0 sidebar d-none d-xl-block">
                    {user ? (
                        <Menu currentPage={t(Lang.USERS)} user={user} />
                    ) : (
                        ''
                    )}
                </Col>
                <Col xs="12" xl="10" className="p-3 pe-xl-4">
                    <Row>
                        <Col>
                            <Button
                                variant="link"
                                className="btn-add"
                                onClick={handleOpenUserModal}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'plus']}
                                    size="2x"
                                    fixedWidth
                                />
                            </Button>
                            <UserModal
                                show={showUserModal}
                                handleClose={handleCloseUserModal}
                                reloadUsers={triggerUserFetch}
                                user={userModal}
                            />
                        </Col>
                    </Row>
                    {users.length ? (
                        <Row>
                            <Col>
                                <UsersTable
                                    users={users}
                                    handleEditUser={handleOpenUserModal}
                                    reloadUsers={triggerUserFetch}
                                />
                            </Col>
                        </Row>
                    ) : (
                        <Loader loading={loading} size={100} color="#0d6efd" />
                    )}
                </Col>
            </Row>
        </>
    );
};

export default UsersLayout;
