import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import LoginLayout from './pages/Login/LoginLayout';
import RecoveryLayout from './pages/Recovery/RecoveryLayout';
import HomeLayout from './pages/Home/HomeLayout';
import UsersLayout from './pages/Users/UsersLayout';
import ZonesLayout from './pages/Zones/ZonesLayout';
import CamerasLayout from './pages/Cameras/CamerasLayout';
import PanelsLayout from './pages/Panels/PanelsLayout';
import SettingsLayout from './pages/Settings/SettingsLayout';
import WhiteListsLayout from './pages/WhiteLists/WhiteListsLayout';
import WhitePlatesListLayout from './pages/WhiteLists/WhitePlatesListLayout';
import BlackListsLayout from './pages/BlackLists/BlackListsLayout';
import BlackPlatesListLayout from './pages/BlackLists/BlackPlatesListLayout';
import EventsLayout from './pages/Events/EventsLayout';
import AlertsLayout from './pages/Alerts/AlertsLayout';
import WarningsLayout from './pages/Warnings/WarningsLayout';
import RealTimeLayout from './pages/RealTime/RealTimeLayout';
import ProfileLayout from './pages/Profile/ProfileLayout';
import SanctionsLayout from './pages/Sanctions/SanctionsLayout';
import AnalyticsLayout from './pages/Analytics/AnalyticsLayout';
import LogLayout from './pages/Log/LogLayout';
import SensorsTypeLayout from './pages/SensorsType/SensorsTypeLayout';
import SensorsLayout from './pages/Sensors/SensorsLayout';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './components/auth/AuthContext';
import { AuthWrapper } from './components/auth/AuthWrapper';
import * as URL from './routes/URL';

library.add(fas, far, fab);

const App = (): JSX.Element => {
    return (
        <AuthProvider>
            <BrowserRouter>
                <Routes>
                    <Route path={URL.ROOT} element={<LoginLayout />} />
                    <Route path={URL.RECOVERY} element={<RecoveryLayout />} />
                    <Route
                        path={URL.HOME}
                        element={
                            <AuthWrapper>
                                <HomeLayout />
                            </AuthWrapper>
                        }
                    />
                    <Route
                        path={URL.USERS}
                        element={
                            <AuthWrapper>
                                <UsersLayout />
                            </AuthWrapper>
                        }
                    />
                    <Route
                        path={URL.ZONES}
                        element={
                            <AuthWrapper>
                                <ZonesLayout />
                            </AuthWrapper>
                        }
                    />
                    <Route
                        path={URL.CAMERAS}
                        element={
                            <AuthWrapper>
                                <CamerasLayout />
                            </AuthWrapper>
                        }
                    />
                    <Route
                        path={URL.PANELS}
                        element={
                            <AuthWrapper>
                                <PanelsLayout />
                            </AuthWrapper>
                        }
                    />
                    <Route
                        path={URL.SETTINGS}
                        element={
                            <AuthWrapper>
                                <SettingsLayout />
                            </AuthWrapper>
                        }
                    />
                    <Route
                        path={URL.WHITE_LISTS}
                        element={
                            <AuthWrapper>
                                <WhiteListsLayout />
                            </AuthWrapper>
                        }
                    />

                    <Route
                        path={`${URL.WHITE_LISTS}/:id`}
                        element={
                            <AuthWrapper>
                                <WhitePlatesListLayout />
                            </AuthWrapper>
                        }
                    />

                    <Route
                        path={URL.BLACK_LISTS}
                        element={
                            <AuthWrapper>
                                <BlackListsLayout />
                            </AuthWrapper>
                        }
                    />

                    <Route
                        path={`${URL.BLACK_LISTS}/:id`}
                        element={
                            <AuthWrapper>
                                <BlackPlatesListLayout />
                            </AuthWrapper>
                        }
                    />
                    <Route
                        path={URL.REGISTRY}
                        element={
                            <AuthWrapper>
                                <EventsLayout />
                            </AuthWrapper>
                        }
                    />
                    <Route
                        path={URL.ALERTS}
                        element={
                            <AuthWrapper>
                                <AlertsLayout />
                            </AuthWrapper>
                        }
                    />

                    <Route
                        path={URL.WARNINGS}
                        element={
                            <AuthWrapper>
                                <WarningsLayout />
                            </AuthWrapper>
                        }
                    />

                    <Route
                        path={URL.REAL_TIME}
                        element={
                            <AuthWrapper>
                                <RealTimeLayout />
                            </AuthWrapper>
                        }
                    />

                    <Route
                        path={URL.PROFILE}
                        element={
                            <AuthWrapper>
                                <ProfileLayout />
                            </AuthWrapper>
                        }
                    />
                    <Route
                        path={URL.SANCTIONS}
                        element={
                            <AuthWrapper>
                                <SanctionsLayout />
                            </AuthWrapper>
                        }
                    />

                    <Route
                        path={URL.ANALYTICS}
                        element={
                            <AuthWrapper>
                                <AnalyticsLayout />
                            </AuthWrapper>
                        }
                    />
                    <Route
                        path={URL.LOG}
                        element={
                            <AuthWrapper>
                                <LogLayout />
                            </AuthWrapper>
                        }
                    />
                    <Route
                        path={URL.SENSOR_TYPES}
                        element={
                            <AuthWrapper>
                                <SensorsTypeLayout />
                            </AuthWrapper>
                        }
                    />
                    <Route
                        path={URL.SENSORS}
                        element={
                            <AuthWrapper>
                                <SensorsLayout />
                            </AuthWrapper>
                        }
                    />
                </Routes>
            </BrowserRouter>
        </AuthProvider>
    );
};

export default App;
