import axios, { AxiosResponse } from 'axios';
import * as Auth from '../Authentication';
import baseURL from '../BaseURL';
import ILog from './Log.interface';
import { LogFilterForm } from '../../pages/Log/components/LogFilters';

const apiURL = `${baseURL}/logs`;

export const getLogs = async (
    filter?: LogFilterForm
): Promise<ILog[] | false> => {
    try {
        const response: AxiosResponse = await axios.get(apiURL, {
            headers: Auth.authHeader(),
            params: filter,
        });

        if (response.data) {
            const logs: ILog[] = response.data;
            return logs;
        } else return false;
    } catch (error) {
        console.error(error);
        return false;
    }
};

export const getLogsCount = async (
    filter?: LogFilterForm
): Promise<number | false> => {
    try {
        const response: AxiosResponse = await axios.get(`${apiURL}`, {
            headers: Auth.authHeader(),
            params: { ...filter, count: true },
        });

        if (response.data) {
            const logsCount: number = response.data;
            return logsCount;
        } else return false;
    } catch (error) {
        console.error(error);
        return false;
    }
};

export const getLog = async (id: string): Promise<ILog | false> => {
    try {
        const response: AxiosResponse = await axios.get(`${apiURL}/${id}`, {
            headers: Auth.authHeader(),
        });

        if (response.data) {
            const log: ILog = response.data;
            return log;
        } else return false;
    } catch (error) {
        console.error(error);
        return false;
    }
};
