import {
    Modal,
    Form,
    InputGroup,
    FormControl,
    Tooltip,
    OverlayTrigger,
    Button,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import * as Lang from '../../../i18n/constants';
import { useForm } from 'react-hook-form';
import { useEffect, useState, useRef } from 'react';
import ErrorField from '../../../components/form/ErrorField';
import Loader from '../../../components/Loader';
import { toast } from 'react-toastify';
import IPlate from '../../../services/Plate/Plate.interface';
import * as Plate from '../../../services/Plate/Plate';
import IList from '../../../services/List/List.interface';
import * as List from '../../../services/List/List';

interface PlateForm {
    _id?: string;
    plate: string;
    description?: string;
    observations?: string;
    brand?: string;
    model?: string;
    color?: string;
    list: string;
}

interface BlackPlateModalProps {
    show: boolean;
    handleClose: () => void;
    reloadPlates: () => void;
    plate?: IPlate | false;
    list: IList;
}

const BlackPlateModal = ({
    show,
    handleClose,
    reloadPlates,
    plate,
    list,
}: BlackPlateModalProps): JSX.Element => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [changedPlate, setChangedPlate] = useState<IPlate>();
    const inputPlateRef = useRef<HTMLInputElement | null>(null);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm<PlateForm>();

    const inputPlateRegister = register('plate', {
        required: t(Lang.REQUIRED_FIELD, {
            field: t(Lang.PLATE),
        }) as string,
        maxLength: {
            value: 32,
            message: t(Lang.MAX_LENGTH_FIELD, {
                field: t(Lang.PLATE),
                value: 32,
            }),
        },
    });

    const handleCreatePlate = async (data: PlateForm): Promise<void> => {
        setLoading(true);

        const plate: IPlate = {
            plate: data.plate,
            description: data.description,
            observations: data.observations,
            brand: data.brand,
            model: data.model,
            color: data.color,
            list: list._id,
        };

        let sure = true;

        if (changedPlate && changedPlate.list) {
            const listChanged = await List.getList(changedPlate.list);

            if (listChanged) {
                sure = confirm(
                    t(Lang.CONFIRM_SAVE_PLATE, { oldList: listChanged, list })
                );
            }
        }

        if (sure) {
            const createdPlate = await Plate.createPlate(plate);

            if (createdPlate) {
                toast.success(
                    t(Lang.PLATE_ADDED_SUCCESSFULLY, { plate: createdPlate })
                );
                reloadPlates();
            } else toast.error(t(Lang.ERROR_ADDING_PLATE, { plate }));
        }

        setLoading(false);

        handleClose();
    };

    const handleUpdatePlate = async (data: PlateForm): Promise<void> => {
        setLoading(true);

        const plate: IPlate = {
            _id: data._id,
            plate: data.plate,
            description: data.description,
            observations: data.observations,
            brand: data.brand,
            model: data.model,
            color: data.color,
            list: list._id,
        };

        let sure = true;

        if (changedPlate && changedPlate.list) {
            const listChanged = await List.getList(changedPlate.list);

            if (listChanged) {
                sure = confirm(
                    t(Lang.CONFIRM_SAVE_PLATE, { oldList: listChanged, list })
                );
            }
        }

        if (sure) {
            const updatedPlate = await Plate.updatePlate(plate);

            if (updatedPlate) {
                toast.success(
                    t(Lang.PLATE_UPDATED_SUCCESSFULLY, { plate: updatedPlate })
                );
                reloadPlates();
            } else toast.error(t(Lang.ERROR_UPDATING_PLATE, { plate }));
        }

        setLoading(false);

        handleClose();
    };

    const handleChangePlate = async () => {
        if (inputPlateRef.current) {
            const platesFound = await Plate.getPlates(
                undefined,
                inputPlateRef.current.value
            );

            if (platesFound && platesFound.length) {
                reset(platesFound[0]);
                setChangedPlate(platesFound[0]);
            }
        }
    };

    useEffect(() => {
        if (plate) reset(plate);
        else
            reset({
                plate: '',
                description: '',
                observations: '',
                brand: '',
                model: '',
                color: '',
                list: list._id as string,
            });
    }, [plate]);

    return (
        <Modal show={show} onHide={handleClose}>
            <Form
                onSubmit={
                    plate || changedPlate
                        ? handleSubmit(handleUpdatePlate)
                        : handleSubmit(handleCreatePlate)
                }
            >
                <Modal.Header closeButton className="border-0">
                    <Modal.Title>
                        {plate
                            ? t(Lang.EDIT_PLATE, { plate })
                            : changedPlate
                            ? t(Lang.EDIT_PLATE, { plate: changedPlate })
                            : t(Lang.ADD_PLATE)}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        <small>{t(Lang.REQUIRED_MESSAGE)}</small>
                    </p>
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.PLATE)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.plate
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'font']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <FormControl
                            id="plate"
                            type="text"
                            maxLength={32}
                            placeholder={'* ' + t(Lang.PLATE)}
                            aria-label={t(Lang.PLATE)}
                            aria-describedby={t(Lang.PLATE)}
                            className={
                                errors.plate ? 'border-2 border-danger' : ''
                            }
                            {...inputPlateRegister}
                            onChange={(e) => {
                                inputPlateRegister.onChange(e);
                                handleChangePlate();
                            }}
                            ref={(e: unknown) => {
                                inputPlateRegister.ref(e);
                                inputPlateRef.current = e as HTMLInputElement;
                            }}
                        />
                    </InputGroup>
                    {errors.plate && (
                        <ErrorField message={errors.plate.message} />
                    )}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.DESCRIPTION)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.description
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'align-left']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <FormControl
                            id="description"
                            as="textarea"
                            maxLength={512}
                            rows={2}
                            placeholder={t(Lang.DESCRIPTION)}
                            aria-label={t(Lang.DESCRIPTION)}
                            aria-describedby={t(Lang.DESCRIPTION)}
                            className={
                                errors.description
                                    ? 'border-2 border-danger'
                                    : ''
                            }
                            {...register('description', {
                                maxLength: {
                                    value: 512,
                                    message: t(Lang.MAX_LENGTH_FIELD, {
                                        field: t(Lang.DESCRIPTION),
                                        value: 512,
                                    }),
                                },
                            })}
                        />
                    </InputGroup>
                    {errors.description && (
                        <ErrorField message={errors.description.message} />
                    )}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.OBSERVATIONS)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.observations
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'align-left']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <FormControl
                            id="observations"
                            as="textarea"
                            maxLength={512}
                            rows={3}
                            placeholder={t(Lang.OBSERVATIONS)}
                            aria-label={t(Lang.OBSERVATIONS)}
                            aria-describedby={t(Lang.OBSERVATIONS)}
                            className={
                                errors.observations
                                    ? 'border-2 border-danger'
                                    : ''
                            }
                            {...register('observations', {
                                maxLength: {
                                    value: 512,
                                    message: t(Lang.MAX_LENGTH_FIELD, {
                                        field: t(Lang.OBSERVATIONS),
                                        value: 512,
                                    }),
                                },
                            })}
                        />
                    </InputGroup>
                    {errors.observations && (
                        <ErrorField message={errors.observations.message} />
                    )}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.BRAND)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.brand
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'industry']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <FormControl
                            id="brand"
                            type="text"
                            maxLength={128}
                            placeholder={t(Lang.BRAND)}
                            aria-label={t(Lang.BRAND)}
                            aria-describedby={t(Lang.BRAND)}
                            className={
                                errors.brand ? 'border-2 border-danger' : ''
                            }
                            {...register('brand', {
                                maxLength: {
                                    value: 128,
                                    message: t(Lang.MAX_LENGTH_FIELD, {
                                        field: t(Lang.BRAND),
                                        value: 128,
                                    }),
                                },
                            })}
                        />
                    </InputGroup>
                    {errors.brand && (
                        <ErrorField message={errors.brand.message} />
                    )}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.MODEL)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.model
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'cubes']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <FormControl
                            id="model"
                            type="text"
                            maxLength={128}
                            placeholder={t(Lang.MODEL)}
                            aria-label={t(Lang.MODEL)}
                            aria-describedby={t(Lang.MODEL)}
                            className={
                                errors.model ? 'border-2 border-danger' : ''
                            }
                            {...register('model', {
                                maxLength: {
                                    value: 128,
                                    message: t(Lang.MAX_LENGTH_FIELD, {
                                        field: t(Lang.MODEL),
                                        value: 128,
                                    }),
                                },
                            })}
                        />
                    </InputGroup>
                    {errors.model && (
                        <ErrorField message={errors.model.message} />
                    )}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.COLOR)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.color
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'palette']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <FormControl
                            id="color"
                            type="text"
                            maxLength={64}
                            placeholder={t(Lang.COLOR)}
                            aria-label={t(Lang.COLOR)}
                            aria-describedby={t(Lang.COLOR)}
                            className={
                                errors.color ? 'border-2 border-danger' : ''
                            }
                            {...register('color', {
                                maxLength: {
                                    value: 64,
                                    message: t(Lang.MAX_LENGTH_FIELD, {
                                        field: t(Lang.COLOR),
                                        value: 64,
                                    }),
                                },
                            })}
                        />
                    </InputGroup>
                    {errors.color && (
                        <ErrorField message={errors.color.message} />
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t(Lang.CANCEL)}
                    </Button>
                    <Button
                        type="submit"
                        variant="primary"
                        id="_id"
                        className="btn-modal"
                        {...register('_id')}
                    >
                        {loading ? (
                            <Loader
                                loading={loading}
                                size={25}
                                color="#FFFFFF"
                            />
                        ) : (
                            t(Lang.SAVE)
                        )}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default BlackPlateModal;
