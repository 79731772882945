import axios, { AxiosResponse } from 'axios';
import * as Auth from '../Authentication';
import baseURL from '../BaseURL';
import ICamera from './Camera.interface';

const apiURL = `${baseURL}/cameras`;

export const createCamera = async (
    camera: ICamera
): Promise<ICamera | false> => {
    try {
        const response: AxiosResponse = await axios.post(apiURL, camera, {
            headers: Auth.authHeader(),
        });

        if (response.data) {
            const createdCamera: ICamera = response.data;
            return createdCamera;
        } else return false;
    } catch (error) {
        console.error(error);
        return false;
    }
};

export const getCameras = async (zone?: string): Promise<ICamera[] | false> => {
    try {
        const response: AxiosResponse = await axios.get(apiURL, {
            headers: Auth.authHeader(),
            params: { zone },
        });

        if (response.data) {
            const cameras: ICamera[] = response.data;
            return cameras;
        } else return false;
    } catch (error) {
        console.error(error);
        return false;
    }
};

export const getCamera = async (id: string): Promise<ICamera | false> => {
    try {
        const response: AxiosResponse = await axios.get(`${apiURL}/${id}`, {
            headers: Auth.authHeader(),
        });

        if (response.data) {
            const camera: ICamera = response.data;
            return camera;
        } else return false;
    } catch (error) {
        console.error(error);
        return false;
    }
};

export const updateCamera = async (
    camera: ICamera
): Promise<ICamera | false> => {
    try {
        const response: AxiosResponse = await axios.put(
            `${apiURL}/${camera._id}`,
            camera,
            { headers: Auth.authHeader() }
        );

        if (response.data) {
            const updatedCamera: ICamera = response.data;
            return updatedCamera;
        } else return false;
    } catch (error) {
        console.error(error);
        return false;
    }
};

export const deleteCamera = async (id: string): Promise<ICamera | false> => {
    try {
        const response: AxiosResponse = await axios.delete(`${apiURL}/${id}`, {
            headers: Auth.authHeader(),
        });

        if (response.data) {
            const deletedCamera: ICamera = response.data;
            return deletedCamera;
        } else return false;
    } catch (error) {
        console.error(error);
        return false;
    }
};
