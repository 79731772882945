import {
    MapContainer,
    TileLayer,
    Marker,
    Popup,
    Polygon,
    LayersControl,
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { LatLngExpression, divIcon, LeafletEvent } from 'leaflet';
import { renderToStaticMarkup } from 'react-dom/server';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Map.css';
import { Row, Col } from 'react-bootstrap';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { useTranslation } from 'react-i18next';
import * as Lang from '../../../i18n/constants';
import { useEffect, useState } from 'react';
import IZone from '../../../services/Zone/Zone.interface';
import { DeviceStatus } from '../../../services/Camera/Camera.interface';

export interface Device {
    name: string;
    description?: string;
    position: LatLngExpression;
    type: string;
    status: DeviceStatus;
}

export enum DeviceType {
    camera = 'camera',
    raspberry = 'raspberry',
    panel = 'panel',
    sensor = 'sensor',
}

interface MapProps {
    devices: Device[];
    zones: IZone[];
    coordinates: string;
}

const Map = ({ devices, coordinates, zones }: MapProps): JSX.Element => {
    const { t } = useTranslation();
    const [mapCoordinates, setMapCoordinates] = useState<LatLngExpression>();

    useEffect(() => {
        const coordinatesArray = coordinates.split(',');

        if (coordinatesArray && coordinatesArray.length === 2) {
            setMapCoordinates([
                parseFloat(coordinatesArray[0]),
                parseFloat(coordinatesArray[1]),
            ]);
        }
    }, []);

    const activeLayer = localStorage.getItem('layer');

    return (
        <>
            <Row className="h-75 mb-3">
                <Col className="pt-3 px-lg-3">
                    {mapCoordinates ? (
                        <MapContainer
                            center={mapCoordinates}
                            zoom={13}
                            className="w-100 h-100"
                        >
                            <LayersControl>
                                <LayersControl.BaseLayer
                                    checked={
                                        activeLayer === 'STREETS' ||
                                        !activeLayer
                                    }
                                    name={t(Lang.STREETS)}
                                >
                                    <TileLayer
                                        url="http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"
                                        maxZoom={20}
                                        id="STREETS"
                                        subdomains={[
                                            'mt0',
                                            'mt1',
                                            'mt2',
                                            'mt3',
                                        ]}
                                        eventHandlers={{
                                            add: (e: LeafletEvent) => {
                                                localStorage.setItem(
                                                    'layer',
                                                    e.target.options.id
                                                );
                                            },
                                        }}
                                    />
                                </LayersControl.BaseLayer>
                                <LayersControl.BaseLayer
                                    checked={activeLayer === 'HYBRID'}
                                    name={t(Lang.HYBRID)}
                                >
                                    <TileLayer
                                        url="http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}"
                                        maxZoom={20}
                                        id="HYBRID"
                                        subdomains={[
                                            'mt0',
                                            'mt1',
                                            'mt2',
                                            'mt3',
                                        ]}
                                        eventHandlers={{
                                            add: (e: LeafletEvent) => {
                                                localStorage.setItem(
                                                    'layer',
                                                    e.target.options.id
                                                );
                                            },
                                        }}
                                    />
                                </LayersControl.BaseLayer>
                                <LayersControl.BaseLayer
                                    checked={activeLayer === 'SATELLITE'}
                                    name={t(Lang.SATELLITE)}
                                >
                                    <TileLayer
                                        url="http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
                                        maxZoom={20}
                                        id="SATELLITE"
                                        subdomains={[
                                            'mt0',
                                            'mt1',
                                            'mt2',
                                            'mt3',
                                        ]}
                                        eventHandlers={{
                                            add: (e: LeafletEvent) => {
                                                localStorage.setItem(
                                                    'layer',
                                                    e.target.options.id
                                                );
                                            },
                                        }}
                                    />
                                </LayersControl.BaseLayer>
                                <LayersControl.BaseLayer
                                    checked={activeLayer === 'TERRAIN'}
                                    name={t(Lang.TERRAIN)}
                                >
                                    <TileLayer
                                        url="http://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}"
                                        maxZoom={20}
                                        id="TERRAIN"
                                        subdomains={[
                                            'mt0',
                                            'mt1',
                                            'mt2',
                                            'mt3',
                                        ]}
                                        eventHandlers={{
                                            add: (e: LeafletEvent) => {
                                                localStorage.setItem(
                                                    'layer',
                                                    e.target.options.id
                                                );
                                            },
                                        }}
                                    />
                                </LayersControl.BaseLayer>
                            </LayersControl>

                            {zones.map((zone) => {
                                const positions: LatLngExpression[] = [];
                                if (zone.coordinates) {
                                    const positionsArray =
                                        zone.coordinates.split(';');

                                    for (const position of positionsArray) {
                                        const coord = position.split(',');

                                        if (coord.length === 2) {
                                            positions.push([
                                                parseFloat(coord[0]),
                                                parseFloat(coord[1]),
                                            ]);
                                        }
                                    }
                                    return (
                                        <Polygon
                                            key={zone._id}
                                            pathOptions={{ color: zone.color }}
                                            positions={positions}
                                        />
                                    );
                                }
                            })}
                            {devices.map((device: Device, index) => {
                                let figure: IconName = 'location-dot';
                                let color = 'text-primary';

                                if (device.status === DeviceStatus.online)
                                    color = 'text-success';

                                if (device.status === DeviceStatus.offline)
                                    color = 'text-danger';

                                if (device.status === DeviceStatus.receiving)
                                    color = 'text-warning';

                                switch (device.type) {
                                    case DeviceType.camera:
                                        figure = 'video';
                                        break;
                                    case DeviceType.raspberry:
                                        figure = 'memory';
                                        break;
                                    case DeviceType.panel:
                                        figure = 'chalkboard';
                                        break;
                                    case DeviceType.sensor:
                                        figure = 'wind';
                                        break;
                                }

                                const icon = (
                                    <FontAwesomeIcon
                                        icon={['fas', figure]}
                                        size="2x"
                                        className={color}
                                    />
                                );

                                const iconMarkup = renderToStaticMarkup(icon);

                                const customMarkerIcon = divIcon({
                                    html: iconMarkup,
                                });

                                return (
                                    <Marker
                                        key={index}
                                        position={device.position}
                                        icon={customMarkerIcon}
                                    >
                                        <Popup>
                                            {device.name} <br />
                                            {device.description}
                                        </Popup>
                                    </Marker>
                                );
                            })}
                        </MapContainer>
                    ) : (
                        ''
                    )}
                </Col>
            </Row>
            <Row className="justify-content-center px-3">
                <Col
                    xs="4"
                    sm="3"
                    md="2"
                    className="align-items-center text-center"
                >
                    <FontAwesomeIcon
                        icon={['fas', 'video']}
                        size="2x"
                        className="text-primary me-3 align-middle"
                    />
                    <span className="align-middle">{t(Lang.CAMERAS)}</span>
                </Col>
                <Col
                    xs="4"
                    sm="3"
                    md="2"
                    className="align-items-center text-center"
                >
                    <FontAwesomeIcon
                        icon={['fas', 'chalkboard']}
                        size="2x"
                        className="text-primary me-3 align-middle"
                    />
                    <span className="align-middle">{t(Lang.PANELS)}</span>
                </Col>
                <Col
                    xs="4"
                    sm="3"
                    md="2"
                    className="align-items-center text-center"
                >
                    <FontAwesomeIcon
                        icon={['fas', 'wind']}
                        size="2x"
                        className="text-primary me-3 align-middle"
                    />
                    <span className="align-middle">{t(Lang.SENSORS)}</span>
                </Col>
            </Row>
        </>
    );
};

export default Map;
