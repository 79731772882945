import axios, { AxiosResponse } from 'axios';
import * as Auth from '../Authentication';
import baseURL from '../BaseURL';
import IList from './List.interface';

const apiURL = `${baseURL}/lists`;

export const createList = async (list: IList): Promise<IList | false> => {
    try {
        const response: AxiosResponse = await axios.post(apiURL, list, {
            headers: Auth.authHeader(),
        });

        if (response.data) {
            const createdList: IList = response.data;
            return createdList;
        } else return false;
    } catch (error) {
        console.error(error);
        return false;
    }
};

export const getLists = async (): Promise<IList[] | false> => {
    try {
        const response: AxiosResponse = await axios.get(apiURL, {
            headers: Auth.authHeader(),
        });

        if (response.data) {
            const lists: IList[] = response.data;
            return lists;
        } else return false;
    } catch (error) {
        console.error(error);
        return false;
    }
};

export const getList = async (id: string): Promise<IList | false> => {
    try {
        const response: AxiosResponse = await axios.get(`${apiURL}/${id}`, {
            headers: Auth.authHeader(),
        });

        if (response.data) {
            const list: IList = response.data;
            return list;
        } else return false;
    } catch (error) {
        console.error(error);
        return false;
    }
};

export const updateList = async (list: IList): Promise<IList | false> => {
    try {
        const response: AxiosResponse = await axios.put(
            `${apiURL}/${list._id}`,
            list,
            { headers: Auth.authHeader() }
        );

        if (response.data) {
            const updatedList: IList = response.data;
            return updatedList;
        } else return false;
    } catch (error) {
        console.error(error);
        return false;
    }
};

export const deleteList = async (id: string): Promise<IList | false> => {
    try {
        const response: AxiosResponse = await axios.delete(`${apiURL}/${id}`, {
            headers: Auth.authHeader(),
        });

        if (response.data) {
            const deletedList: IList = response.data;
            return deletedList;
        } else return false;
    } catch (error) {
        console.error(error);
        return false;
    }
};
