import IUser from '../User/User.interface';

export enum DeviceType {
    camera = 'camera',
    raspberry = 'raspberry',
    panel = 'panel',
    sensor = 'sensor',
}

export enum warningReason {
    connected = 'connected',
    disconnected = 'disconnected',
}

export default interface Warning {
    _id?: string;
    device: string;
    type: DeviceType;
    reason: warningReason;
    users?: IUser['_id'][];
    createdAt?: Date;
    updatedAt?: Date;
}
