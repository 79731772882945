export const BASE = '';

export const ROOT = BASE + '/';
export const HOME = BASE + '/home';
export const PROFILE = BASE + '/profile';
export const REAL_TIME = BASE + '/realtime';
export const ANALYTICS = BASE + '/analytics';
export const SANCTIONS = BASE + '/sanctions';
export const WHITE_LISTS = BASE + '/whitelists';
export const BLACK_LISTS = BASE + '/blacklists';
export const LOG = BASE + '/log';
export const USERS = BASE + '/users';
export const ZONES = BASE + '/zones';
export const CAMERAS = BASE + '/cameras';
export const RASPBERRIES = BASE + '/raspberries';
export const PANELS = BASE + '/panels';
export const SETTINGS = BASE + '/settings';
export const REGISTRY = BASE + '/events';
export const ALERTS = BASE + '/alerts';
export const WARNINGS = BASE + '/warnings';
export const RECOVERY = BASE + '/recovery';
export const SENSORS = BASE + '/sensors';
export const SENSOR_TYPES = BASE + '/sensortypes';
