import axios, { AxiosResponse } from 'axios';
import * as Auth from '../Authentication';
import baseURL from '../BaseURL';
import ISensorType from './SensorType.interface';

const apiURL = `${baseURL}/sensortypes`;

export const createSensorType = async (
    sensorType: ISensorType
): Promise<ISensorType | false> => {
    try {
        const response: AxiosResponse = await axios.post(apiURL, sensorType, {
            headers: Auth.authHeader(),
        });

        if (response.data) {
            const createdSensorType: ISensorType = response.data;
            return createdSensorType;
        } else return false;
    } catch (error) {
        console.error(error);
        return false;
    }
};

export const getSensorTypes = async (): Promise<ISensorType[] | false> => {
    try {
        const response: AxiosResponse = await axios.get(apiURL, {
            headers: Auth.authHeader(),
        });

        if (response.data) {
            const sensorsType: ISensorType[] = response.data;
            return sensorsType;
        } else return false;
    } catch (error) {
        console.error(error);
        return false;
    }
};

export const getSensorType = async (
    id: string
): Promise<ISensorType | false> => {
    try {
        const response: AxiosResponse = await axios.get(`${apiURL}/${id}`, {
            headers: Auth.authHeader(),
        });

        if (response.data) {
            const sensorType: ISensorType = response.data;
            return sensorType;
        } else return false;
    } catch (error) {
        console.error(error);
        return false;
    }
};

export const updateSensorType = async (
    sensorType: ISensorType
): Promise<ISensorType | false> => {
    try {
        const response: AxiosResponse = await axios.put(
            `${apiURL}/${sensorType._id}`,
            sensorType,
            { headers: Auth.authHeader() }
        );

        if (response.data) {
            const updatedSensorType: ISensorType = response.data;
            return updatedSensorType;
        } else return false;
    } catch (error) {
        console.error(error);
        return false;
    }
};

export const deleteSensorType = async (
    id: string
): Promise<ISensorType | false> => {
    try {
        const response: AxiosResponse = await axios.delete(`${apiURL}/${id}`, {
            headers: Auth.authHeader(),
        });

        if (response.data) {
            const deletedSensorType: ISensorType = response.data;
            return deletedSensorType;
        } else return false;
    } catch (error) {
        console.error(error);
        return false;
    }
};
