import React, { useEffect, useState } from 'react';
import Header from '../../components/header/Header';
import Menu from '../../components/menu/Menu';
import IUser, { Role } from '../../services/User/User.interface';
import * as User from '../../services/User/User';
import { Row, Col, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as Lang from '../../i18n/constants';
import Loader from '../../components/Loader';
import ILog from '../../services/Log/Log.interface';
import { LogFilterForm } from './components/LogFilters';
import * as Log from '../../services/Log/Log';
import LogFilters from './components/LogFilters';
import LogTable from './components/LogTable';
import Pagination from '../../components/CustomPagination';
import { useNavigate } from 'react-router-dom';
import * as URL from '../../routes/URL';
import LogExportCSV from './components/LogExportCSV';

interface LogLayoutProps {
    user?: IUser;
}

const LogLayout = ({ user }: LogLayoutProps): JSX.Element => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [logs, setLogs] = useState<ILog[]>([]);
    const [allLogs, setAllLogs] = useState<ILog[]>([]);
    const [fetchLogs, setFetchLogs] = useState(true);
    const [totalLogs, setTotalLogs] = useState<number>();
    const [users, setUsers] = useState<IUser[]>([]);
    const [filter, setFilter] = useState<LogFilterForm>();
    const [limit, setLimit] = useState(50);
    const [page, setPage] = useState(1);

    const triggerLogFetch = () => setFetchLogs((t) => !t);

    const handleFilter = (data: LogFilterForm) => {
        const log: LogFilterForm = {};
        Object.entries(data).map(([index, value]) => {
            if (value !== '') log[index as keyof LogFilterForm] = value;
        });
        setFilter(log);
        triggerLogFetch();
    };

    const handleChangeLimit = (
        event: React.ChangeEvent<HTMLSelectElement>
    ): void => {
        setLimit(parseInt(event.target.value));
        setPage(1);
        triggerLogFetch();
    };

    const handleClickPage = (nPage: number) => {
        setPage(nPage);
        triggerLogFetch();
    };

    useEffect(() => {
        if (user?.role !== Role.admin) navigate(URL.BASE);
    }, []);

    useEffect(() => {
        const load = async () => {
            const users = await User.getUsers();

            if (users) setUsers(users);

            /*const logsCount = await Log.getLogsCount(filter);

            if (logsCount) setTotalLogs(logsCount);*/

            const aLogs = await Log.getLogs({ ...filter });

            if (aLogs) {
                setTotalLogs(aLogs.length);
                setAllLogs(aLogs);
            }

            const logs = await Log.getLogs({ ...filter, limit, page });

            if (logs) setLogs(logs);

            setLoading(false);
        };
        load();
    }, [fetchLogs]);

    return (
        <>
            <Header user={user} />
            <Row className="h-100">
                <Col xs="3" xl="2" className="p-0 sidebar d-none d-xl-block">
                    {user ? <Menu currentPage={t(Lang.LOG)} user={user} /> : ''}
                </Col>
                <Col xs="12" xl="10" className="p-3 pe-xl-4">
                    {allLogs.length ? (
                        <Row className="mb-3">
                            <Col>
                                <LogExportCSV logs={allLogs} />
                            </Col>
                        </Row>
                    ) : (
                        ''
                    )}
                    {users.length ? (
                        <Row className="mb-3">
                            <Col>
                                <LogFilters
                                    users={users}
                                    handleFilter={handleFilter}
                                />
                            </Col>
                        </Row>
                    ) : (
                        ''
                    )}
                    {logs.length && users ? (
                        <>
                            <Row className="mb-3">
                                <Col>
                                    <LogTable logs={logs} users={users} />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="6" xl="2" className="mb-3">
                                    <Form.Label>
                                        {t(Lang.ITEMS_PER_PAGE, {
                                            item: t(Lang.LOG),
                                        })}
                                    </Form.Label>
                                </Col>
                                <Col xs="6" xl="1">
                                    <Form.Select
                                        defaultValue={limit}
                                        onChange={handleChangeLimit}
                                    >
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="50">50</option>
                                    </Form.Select>
                                </Col>
                                {totalLogs && totalLogs > limit ? (
                                    <Col xs="12" xl="9">
                                        <Pagination
                                            totalPages={Math.ceil(
                                                totalLogs / limit
                                            )}
                                            currentPage={page}
                                            pageClicked={handleClickPage}
                                        />
                                    </Col>
                                ) : (
                                    ''
                                )}
                            </Row>
                        </>
                    ) : (
                        <Loader loading={loading} size={100} color="#0d6efd" />
                    )}
                </Col>
            </Row>
        </>
    );
};

export default LogLayout;
