import {
    Modal,
    Form,
    InputGroup,
    FormControl,
    Tooltip,
    OverlayTrigger,
    Button,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import * as Lang from '../../../i18n/constants';
import IUser, { Role } from '../../../services/User/User.interface';
import { useForm } from 'react-hook-form';
import { useEffect, useRef, useState } from 'react';
import ErrorField from '../../../components/form/ErrorField';
import Loader from '../../../components/Loader';
import { toast } from 'react-toastify';
import * as User from '../../../services/User/User';

export interface UserForm extends IUser {
    repeatPassword: string;
    password: string;
    blocked: boolean;
}

interface UserModalProps {
    show: boolean;
    handleClose: () => void;
    reloadUsers: () => void;
    user?: IUser | false;
}

const UserModal = ({
    show,
    handleClose,
    reloadUsers,
    user,
}: UserModalProps): JSX.Element => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const passwordRef = useRef<HTMLInputElement | null>(null);
    const repeatPasswordRef = useRef<HTMLInputElement | null>(null);

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        reset,
    } = useForm<UserForm>();

    const passwordRegister = register('password', {
        required: user
            ? false
            : (t(Lang.REQUIRED_FIELD, {
                  field: t(Lang.PASSWORD),
              }) as string),
        minLength: {
            value: 8,
            message: t(Lang.MIN_LENGTH_FIELD, {
                field: t(Lang.PASSWORD),
                value: 8,
            }),
        },
        maxLength: {
            value: 256,
            message: t(Lang.MAX_LENGTH_FIELD, {
                field: t(Lang.PASSWORD),
                value: 256,
            }),
        },
    });

    const repeatPasswordRegister = register('repeatPassword', {
        required: user
            ? false
            : (t(Lang.REQUIRED_FIELD, {
                  field: t(Lang.REPEAT_PASSWORD),
              }) as string),
        validate: (value) => {
            if (value.length || password.current)
                return (
                    value === password.current ||
                    (t(Lang.PASSWORDS_DO_NOT_MATCH) as string)
                );
            else return true;
        },
    });

    const password = useRef({});

    password.current = watch('password', '');

    const handleCreateUser = async (data: UserForm): Promise<void> => {
        setLoading(true);

        const user: IUser = {
            username: data.username,
            password: data.password,
            email: data.email,
            firstname: data.firstname,
            lastname: data.lastname,
            role: data.role,
            phone: data.phone,
            twoFactorEnabled: data.twoFactorEnabled,
            loginAttempts: data.blocked ? 6 : 0,
        };

        const createdUser = await User.createUser(user);

        if (createdUser) {
            toast.success(t(Lang.USER_CREATED_SUCCESSFULLY, { user }));
            reloadUsers();
        } else toast.error(t(Lang.ERROR_CREATING_USER, { user }));

        setLoading(false);

        handleClose();
    };

    const handleUpdateUser = async (data: UserForm): Promise<void> => {
        setLoading(true);

        const user: IUser = {
            _id: data._id,
            username: data.username,
            email: data.email,
            firstname: data.firstname,
            lastname: data.lastname,
            role: data.role,
            phone: data.phone,
            twoFactorEnabled: data.twoFactorEnabled,
            loginAttempts: data.blocked ? 6 : 0,
        };

        if (data.password?.length) user.password = data.password;

        const updatedUser = await User.updateUser(user);

        if (updatedUser) {
            toast.success(t(Lang.USER_UPDATED_SUCCESSFULLY, { user }));
            reloadUsers();
        } else toast.error(t(Lang.ERROR_UPDATING_USER, { user }));

        setLoading(false);
        handleClose();
    };

    const handleHideShowPassword = (
        event: React.MouseEvent<HTMLButtonElement>
    ): void => {
        if (
            event.currentTarget.className === 'p-0 text-secondary btn btn-link'
        ) {
            event.currentTarget.className = 'p-0 text-primary btn btn-link';

            if (event.currentTarget.value === 'password' && passwordRef.current)
                passwordRef.current.type = 'text';

            if (
                event.currentTarget.value === 'repeatPassword' &&
                repeatPasswordRef.current
            )
                repeatPasswordRef.current.type = 'text';
        } else {
            event.currentTarget.className = 'p-0 text-secondary btn btn-link';

            if (event.currentTarget.value === 'password' && passwordRef.current)
                passwordRef.current.type = 'password';

            if (
                event.currentTarget.value === 'repeatPassword' &&
                repeatPasswordRef.current
            )
                repeatPasswordRef.current.type = 'password';
        }
    };

    useEffect(() => {
        if (user) {
            let blocked = false;

            if (user.loginAttempts) blocked = user.loginAttempts > 5;

            reset({ ...user, blocked });
        } else
            reset({
                _id: '',
                username: '',
                password: '',
                repeatPassword: '',
                email: '',
                firstname: '',
                lastname: '',
                role: Role.admin,
                phone: '',
                twoFactorEnabled: false,
                blocked: false,
            });
    }, [user]);

    return (
        <Modal show={show} onHide={handleClose}>
            <Form
                onSubmit={
                    user
                        ? handleSubmit(handleUpdateUser)
                        : handleSubmit(handleCreateUser)
                }
            >
                <Modal.Header closeButton className="border-0">
                    <Modal.Title>
                        {user ? t(Lang.EDIT_USER, { user }) : t(Lang.ADD_USER)}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        <small>{t(Lang.REQUIRED_MESSAGE)}</small>
                    </p>
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.USERNAME)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.username
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'user']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <FormControl
                            id="username"
                            type="text"
                            maxLength={128}
                            placeholder={'* ' + t(Lang.USERNAME)}
                            aria-label={t(Lang.USERNAME)}
                            aria-describedby={t(Lang.USERNAME)}
                            className={
                                errors.username ? 'border-2 border-danger' : ''
                            }
                            {...register('username', {
                                required: t(Lang.REQUIRED_FIELD, {
                                    field: t(Lang.USERNAME),
                                }) as string,
                                maxLength: {
                                    value: 128,
                                    message: t(Lang.MAX_LENGTH_FIELD, {
                                        field: t(Lang.USERNAME),
                                        value: 128,
                                    }),
                                },
                            })}
                        />
                    </InputGroup>
                    {errors.username && (
                        <ErrorField message={errors.username.message} />
                    )}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                                <Tooltip>
                                    {user
                                        ? t(Lang.CHANGE_PASSWORD)
                                        : t(Lang.PASSWORD)}
                                </Tooltip>
                            }
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.password
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'key']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>

                        <FormControl
                            id="password"
                            type="password"
                            minLength={8}
                            maxLength={256}
                            placeholder={
                                user
                                    ? t(Lang.CHANGE_PASSWORD)
                                    : '* ' + t(Lang.PASSWORD)
                            }
                            aria-label={
                                user
                                    ? t(Lang.CHANGE_PASSWORD)
                                    : t(Lang.PASSWORD)
                            }
                            aria-describedby={
                                user
                                    ? t(Lang.CHANGE_PASSWORD)
                                    : t(Lang.PASSWORD)
                            }
                            className={
                                errors.password ? 'border-2 border-danger' : ''
                            }
                            {...passwordRegister}
                            ref={(e: unknown) => {
                                passwordRegister.ref(e);
                                passwordRef.current = e as HTMLInputElement;
                            }}
                        />
                        <InputGroup.Text>
                            <Button
                                variant="link"
                                className="p-0 text-secondary"
                                value="password"
                                onClick={handleHideShowPassword}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'eye']}
                                    viewBox="0 0 512 512"
                                />
                            </Button>
                        </InputGroup.Text>
                    </InputGroup>
                    {errors.password && (
                        <ErrorField message={errors.password.message} />
                    )}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                                <Tooltip>{t(Lang.REPEAT_PASSWORD)}</Tooltip>
                            }
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.repeatPassword
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'key']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>

                        <FormControl
                            id="repeatPassword"
                            type="password"
                            placeholder={
                                user
                                    ? t(Lang.REPEAT_PASSWORD)
                                    : '* ' + t(Lang.REPEAT_PASSWORD)
                            }
                            aria-label={t(Lang.REPEAT_PASSWORD)}
                            aria-describedby={t(Lang.REPEAT_PASSWORD)}
                            className={
                                errors.repeatPassword
                                    ? 'border-2 border-danger'
                                    : ''
                            }
                            {...repeatPasswordRegister}
                            ref={(e: unknown) => {
                                repeatPasswordRegister.ref(e);
                                repeatPasswordRef.current =
                                    e as HTMLInputElement;
                            }}
                        />
                        <InputGroup.Text>
                            <Button
                                variant="link"
                                className="p-0 text-secondary"
                                value="repeatPassword"
                                onClick={handleHideShowPassword}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'eye']}
                                    viewBox="0 0 512 512"
                                />
                            </Button>
                        </InputGroup.Text>
                    </InputGroup>
                    {errors.repeatPassword && (
                        <ErrorField message={errors.repeatPassword.message} />
                    )}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.EMAIL)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.email
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'envelope']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>

                        <FormControl
                            id="email"
                            type="email"
                            placeholder={'* ' + t(Lang.EMAIL)}
                            aria-label={t(Lang.EMAIL)}
                            aria-describedby={t(Lang.EMAIL)}
                            className={
                                errors.email ? 'border-2 border-danger' : ''
                            }
                            {...register('email', {
                                required: t(Lang.REQUIRED_FIELD, {
                                    field: t(Lang.EMAIL),
                                }) as string,
                                maxLength: {
                                    value: 256,
                                    message: t(Lang.MAX_LENGTH_FIELD, {
                                        field: t(Lang.EMAIL),
                                        value: 256,
                                    }),
                                },
                            })}
                        ></FormControl>
                    </InputGroup>
                    {errors.email && (
                        <ErrorField message={errors.email.message} />
                    )}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.FIRSTNAME)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.firstname
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'user']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>

                        <FormControl
                            id="firstname"
                            type="text"
                            maxLength={128}
                            placeholder={'* ' + t(Lang.FIRSTNAME)}
                            aria-label={t(Lang.FIRSTNAME)}
                            aria-describedby={t(Lang.FIRSTNAME)}
                            className={
                                errors.firstname ? 'border-2 border-danger' : ''
                            }
                            {...register('firstname', {
                                required: t(Lang.REQUIRED_FIELD, {
                                    field: t(Lang.FIRSTNAME),
                                }) as string,
                                maxLength: {
                                    value: 128,
                                    message: t(Lang.MAX_LENGTH_FIELD, {
                                        field: t(Lang.FIRSTNAME),
                                        value: 128,
                                    }),
                                },
                            })}
                        ></FormControl>
                    </InputGroup>
                    {errors.firstname && (
                        <ErrorField message={errors.firstname.message} />
                    )}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.LASTNAME)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.lastname
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'user']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>

                        <FormControl
                            id="lastname"
                            type="text"
                            maxLength={256}
                            placeholder={t(Lang.LASTNAME)}
                            aria-label={t(Lang.LASTNAME)}
                            aria-describedby={t(Lang.LASTNAME)}
                            className={
                                errors.lastname ? 'border-2 border-danger' : ''
                            }
                            {...register('lastname', {
                                maxLength: {
                                    value: 256,
                                    message: t(Lang.MAX_LENGTH_FIELD, {
                                        field: t(Lang.LASTNAME),
                                        value: 256,
                                    }),
                                },
                            })}
                        ></FormControl>
                    </InputGroup>
                    {errors.lastname && (
                        <ErrorField message={errors.lastname.message} />
                    )}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.ROLE)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.role
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'user-lock']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>

                        <Form.Select
                            id="role"
                            className={
                                errors.role ? 'border-2 border-danger' : ''
                            }
                            {...register('role', {
                                required: t(Lang.REQUIRED_FIELD, {
                                    field: t(Lang.ROLE),
                                }) as string,
                            })}
                        >
                            {Object.keys(Role).map((role) => {
                                return (
                                    <option key={role} value={role}>
                                        {role}
                                    </option>
                                );
                            })}
                        </Form.Select>
                    </InputGroup>
                    {errors.role && (
                        <ErrorField message={errors.role.message} />
                    )}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.PHONE)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.phone
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'phone']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>

                        <FormControl
                            id="phone"
                            type="tel"
                            maxLength={64}
                            placeholder={t(Lang.PHONE)}
                            aria-label={t(Lang.PHONE)}
                            aria-describedby={t(Lang.PHONE)}
                            className={
                                errors.phone ? 'border-2 border-danger' : ''
                            }
                            {...register('phone', {
                                maxLength: {
                                    value: 64,
                                    message: t(Lang.MAX_LENGTH_FIELD, {
                                        field: t(Lang.PHONE),
                                        value: 64,
                                    }),
                                },
                            })}
                        ></FormControl>
                    </InputGroup>
                    {errors.phone && (
                        <ErrorField message={errors.phone.message} />
                    )}
                    <Form.Group className="mb-3" controlId="twoFactorEnabled">
                        <Form.Label>{t(Lang.TWO_FACTOR_ENABLED)}</Form.Label>
                        <Form.Check
                            type="switch"
                            {...register('twoFactorEnabled')}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="blocked">
                        <Form.Label>{t(Lang.ACCOUNT_STATUS)}</Form.Label>
                        <Form.Check type="switch" {...register('blocked')} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t(Lang.CANCEL)}
                    </Button>
                    <Button
                        type="submit"
                        variant="primary"
                        id="_id"
                        className="btn-modal"
                        {...register('_id')}
                    >
                        {loading ? (
                            <Loader
                                loading={loading}
                                size={25}
                                color="#FFFFFF"
                            />
                        ) : (
                            t(Lang.SAVE)
                        )}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default UserModal;
