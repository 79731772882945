import React, { useEffect, useState } from 'react';
import Header from '../../components/header/Header';
import Menu from '../../components/menu/Menu';
import IUser from '../../services/User/User.interface';
import { Row, Col, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as Lang from '../../i18n/constants';
import Loader from '../../components/Loader';
import IZone from '../../services/Zone/Zone.interface';
import * as Zone from '../../services/Zone/Zone';
import ICamera from '../../services/Camera/Camera.interface';
import * as Camera from '../../services/Camera/Camera';
import EventsFilter, { EventsFilterForm } from './components/EventsFilter';
import IEvent from '../../services/Event/Event.interface';
import * as Event from '../../services/Event/Event';
import EventsTable from './components/EventsTable';
import EventModal from './components/EventsModal';
import EventsExportCSV from './components/EventsExportCSV';
import Pagination from '../../components/CustomPagination';

interface EventsLayoutProps {
    user?: IUser;
}

const EventsLayout = ({ user }: EventsLayoutProps): JSX.Element => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [zones, setZones] = useState<IZone[]>([]);
    const [zoneSelected, setZoneSelected] = useState<string | undefined>();
    const [cameras, setCameras] = useState<ICamera[]>([]);
    const [events, setEvents] = useState<IEvent[]>([]);
    const [allEvents, setAllEvents] = useState<IEvent[]>([]);
    const [totalEvents, setTotalEvents] = useState<number>();
    const [fetchEvents, setFetchEvents] = useState(true);
    const [filter, setFilter] = useState<EventsFilterForm>();
    const [eventModal, setEventModal] = useState<IEvent | false>();
    const [showEventModal, setShowEventModal] = useState(false);
    const [limit, setLimit] = useState(50);
    const [page, setPage] = useState(1);

    const triggerEventFetch = () => setFetchEvents((t) => !t);

    const handleChangeZone = (
        event: React.ChangeEvent<HTMLSelectElement>
    ): void => {
        setZoneSelected(event.target.value);
        setPage(1);
        triggerEventFetch();
    };

    const handleFilter = (data: EventsFilterForm) => {
        const event: EventsFilterForm = {};
        Object.entries(data).map(([index, value]) => {
            if (value !== '') {
                if (index === 'cameras') event[index] = [value];
                else event[index as keyof EventsFilterForm] = value;
            }
        });
        setFilter(event);
        setPage(1);
        triggerEventFetch();
    };

    const handleOpenEventModal = (
        event: React.MouseEvent<HTMLButtonElement>
    ): void => {
        const button: HTMLButtonElement = event.currentTarget;

        const id = button.value;

        if (id) {
            const event = events.find((event) => event._id === id);
            setEventModal(event);
        } else setEventModal(undefined);

        setShowEventModal(true);
    };

    const handleCloseEventModal = (): void => {
        setEventModal(false);
        setShowEventModal(false);
    };

    const handleChangeLimit = (
        event: React.ChangeEvent<HTMLSelectElement>
    ): void => {
        setLimit(parseInt(event.target.value));
        setPage(1);
        triggerEventFetch();
    };

    const handleClickPage = (nPage: number) => {
        setPage(nPage);
        triggerEventFetch();
    };

    useEffect(() => {
        const load = async () => {
            const zones = await Zone.getZones();

            if (zones && zones.length) {
                setZones(zones);

                if (!zoneSelected) setZoneSelected(zones[0]._id);

                const zoneId = zoneSelected || zones[0]._id || '';

                const cameras = await Camera.getCameras(zoneId);

                if (cameras) setCameras(cameras);

                const aEvents = await Event.getEvents({
                    ...filter,
                    zone: zoneId,
                });

                if (aEvents) {
                    setTotalEvents(aEvents.length);
                    setAllEvents(aEvents);
                }

                const events = await Event.getEvents({
                    ...filter,
                    zone: zoneId,
                    limit,
                    page,
                });

                if (events) setEvents(events);

                setLoading(false);
            }
            setLoading(false);
        };
        load();
    }, [fetchEvents]);

    return (
        <>
            <Header user={user} />
            <Row className="h-100">
                <Col xs="3" xl="2" className="p-0 sidebar d-none d-xl-block">
                    {user ? (
                        <Menu currentPage={t(Lang.REGISTRY)} user={user} />
                    ) : (
                        ''
                    )}
                </Col>
                <Col xs="12" xl="10" className="p-3 pe-xl-4">
                    <Row className="mb-5">
                        {zones.length ? (
                            <Col xs="12" xl="4">
                                <Form.Group as={Row}>
                                    <Col xs="12">
                                        <Form.Select
                                            onChange={handleChangeZone}
                                        >
                                            {zones.map((zone) => {
                                                return (
                                                    <option
                                                        key={zone._id}
                                                        value={zone._id}
                                                    >
                                                        {zone.name}
                                                    </option>
                                                );
                                            })}
                                        </Form.Select>
                                    </Col>
                                </Form.Group>
                            </Col>
                        ) : (
                            ''
                        )}
                    </Row>
                    {cameras.length ? (
                        <>
                            {allEvents.length ? (
                                <Row className="mb-3">
                                    <Col>
                                        <EventsExportCSV
                                            events={allEvents}
                                            cameras={cameras}
                                        />
                                    </Col>
                                </Row>
                            ) : (
                                ''
                            )}
                            <Row className="mb-3">
                                <Col>
                                    <EventsFilter
                                        cameras={cameras}
                                        handleFilter={handleFilter}
                                    />
                                    {zoneSelected ? (
                                        <EventModal
                                            show={showEventModal}
                                            handleClose={handleCloseEventModal}
                                            reloadEvents={triggerEventFetch}
                                            event={eventModal}
                                            cameras={cameras}
                                            zoneId={zoneSelected}
                                        />
                                    ) : (
                                        ''
                                    )}
                                </Col>
                            </Row>
                        </>
                    ) : (
                        ''
                    )}
                    {events.length && cameras.length ? (
                        <>
                            <Row className="mb-3">
                                <Col>
                                    <EventsTable
                                        events={events}
                                        cameras={cameras}
                                        handleEditEvent={handleOpenEventModal}
                                        reloadEvents={triggerEventFetch}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="6" xl="2" className="mb-3">
                                    <Form.Label>
                                        {t(Lang.ITEMS_PER_PAGE, {
                                            item: t(Lang.REGISTRY),
                                        })}
                                    </Form.Label>
                                </Col>
                                <Col xs="6" xl="1">
                                    <Form.Select
                                        defaultValue={limit}
                                        onChange={handleChangeLimit}
                                    >
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="50">50</option>
                                    </Form.Select>
                                </Col>
                                {totalEvents && totalEvents > limit ? (
                                    <Col xs="12" xl="9">
                                        <Pagination
                                            totalPages={Math.ceil(
                                                totalEvents / limit
                                            )}
                                            currentPage={page}
                                            pageClicked={handleClickPage}
                                        />
                                    </Col>
                                ) : (
                                    ''
                                )}
                            </Row>
                        </>
                    ) : (
                        <Loader loading={loading} size={100} color="#0d6efd" />
                    )}
                </Col>
            </Row>
        </>
    );
};

export default EventsLayout;
