import { Table, Row, Col, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as Lang from '../../../i18n/constants';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from '../../../components/Loader';
import IPlate from '../../../services/Plate/Plate.interface';
import * as Plate from '../../../services/Plate/Plate';

interface BlackPlatesTableProps {
    plates: IPlate[];
    handleEditPlate: (event: React.MouseEvent<HTMLButtonElement>) => void;
    reloadPlates: () => void;
}

const BlackPlatesTable = ({
    plates,
    handleEditPlate,
    reloadPlates,
}: BlackPlatesTableProps) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState('');

    const handleDeletePlate = async (
        event: React.MouseEvent<HTMLButtonElement>
    ): Promise<void> => {
        event.preventDefault();

        const button: HTMLButtonElement = event.currentTarget;
        const id = button.value;

        setLoading(id);

        const deletedPlate = await Plate.deletePlate(id);

        if (deletedPlate) {
            toast.success(
                t(Lang.PLATE_REMOVED_SUCCESSFULLY, { plate: deletedPlate })
            );
            reloadPlates();
        } else
            toast.error(t(Lang.ERROR_REMOVING_PLATE, { plate: deletedPlate }));

        setLoading('');
    };

    return (
        <Row>
            <Col>
                <Table responsive striped hover variant="primary">
                    <thead>
                        <tr>
                            <th></th>
                            <th>{t(Lang.PLATE)}</th>
                            <th>{t(Lang.DESCRIPTION)}</th>
                            <th>{t(Lang.BRAND)}</th>
                            <th>{t(Lang.MODEL)}</th>
                            <th>{t(Lang.COLOR)}</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {plates.map((plate: IPlate) => {
                            return (
                                <tr key={plate._id}>
                                    <td></td>
                                    <td>{plate.plate}</td>
                                    <td>{plate.description}</td>
                                    <td>{plate.brand}</td>
                                    <td>{plate.model}</td>
                                    <td>{plate.color}</td>
                                    <td>
                                        <Button
                                            variant="link"
                                            value={plate._id}
                                            onClick={handleEditPlate}
                                        >
                                            <FontAwesomeIcon
                                                icon={['fas', 'pen-to-square']}
                                                size="2x"
                                                fixedWidth
                                            />
                                        </Button>
                                    </td>
                                    <td>
                                        <Button
                                            variant="link"
                                            className="btn-delete"
                                            value={plate._id}
                                            onClick={handleDeletePlate}
                                        >
                                            {loading === plate._id ? (
                                                <Loader
                                                    loading={true}
                                                    size={25}
                                                    color="#dc3545"
                                                />
                                            ) : (
                                                <FontAwesomeIcon
                                                    icon={['fas', 'trash-can']}
                                                    size="2x"
                                                    fixedWidth
                                                />
                                            )}
                                        </Button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </Col>
        </Row>
    );
};

export default BlackPlatesTable;
