import ICamera from '../../../services/Camera/Camera.interface';
import {
    Row,
    Col,
    Form,
    InputGroup,
    OverlayTrigger,
    Tooltip,
    Button,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as Lang from '../../../i18n/constants';
import { useForm } from 'react-hook-form';
import { typeEvent } from '../../../services/Event/Event.interface';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EventsFilterForm } from '../../Events/components/EventsFilter';

interface SanctionsFilterProps {
    cameras?: ICamera[];
    handleFilter: (data: EventsFilterForm) => void;
}

const SanctionsFilter = ({
    cameras,
    handleFilter,
}: SanctionsFilterProps): JSX.Element => {
    const { t } = useTranslation();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<EventsFilterForm>();

    const langObj: { [key: string]: string } = {};

    for (const key of Object.keys(Lang)) langObj[key] = key;

    return (
        <Form onSubmit={handleSubmit(handleFilter)}>
            <Row>
                {cameras ? (
                    <Col xs="12" sm="4" md="3" lg="2" className="mb-3">
                        <InputGroup>
                            <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip>{t(Lang.CAMERA)}</Tooltip>}
                            >
                                <InputGroup.Text
                                    className={`text-white ${
                                        errors.camera
                                            ? 'bg-danger border-2 border-danger'
                                            : 'bg-primary'
                                    }`}
                                >
                                    <FontAwesomeIcon
                                        icon={['fas', 'video']}
                                        viewBox="0 0 512 512"
                                        fixedWidth
                                    />
                                </InputGroup.Text>
                            </OverlayTrigger>
                            <Form.Select
                                id="cameraFilter"
                                {...register('camera')}
                                defaultValue=""
                            >
                                <option value="">{t(Lang.FEMALE_ALL)}</option>
                                {cameras.map((camera) => {
                                    return (
                                        <option
                                            key={camera._id}
                                            value={camera._id}
                                        >
                                            {camera.name}
                                        </option>
                                    );
                                })}
                            </Form.Select>
                        </InputGroup>
                    </Col>
                ) : (
                    ''
                )}
                <Col xs="12" sm="4" md="3" lg="2" className="mb-3">
                    <InputGroup>
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.START)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.start
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'hourglass-start']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <Form.Control
                            id="startFilter"
                            type="datetime-local"
                            {...register('start')}
                        />
                    </InputGroup>
                </Col>
                <Col xs="12" sm="4" md="3" lg="2" className="mb-3">
                    <InputGroup>
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.END)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.end
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'hourglass-end']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <Form.Control
                            id="endFilter"
                            type="datetime-local"
                            {...register('end')}
                        />
                    </InputGroup>
                </Col>
                <Col sm="4" md="3" lg="2" className="mb-3">
                    <InputGroup>
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                                <Tooltip>{t(Lang.TYPE_OF_REGISTRY)}</Tooltip>
                            }
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.type
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'rectangle-list']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <Form.Select id="typeFilter" {...register('type')}>
                            <option value="">{t(Lang.FEMALE_ALL)}</option>
                            <option value={typeEvent.sanctioned}>
                                {t(Lang.SANCTIONED)}
                            </option>
                            <option value={typeEvent.possibleInfringement}>
                                {t(Lang.POSSIBLEINFRINGEMENT)}
                            </option>
                        </Form.Select>
                    </InputGroup>
                </Col>
                <Col sm="4" md="3" lg="2" className="mb-3">
                    <InputGroup>
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                                <Tooltip>{t(Lang.SENT_SANCTION_DATE)}</Tooltip>
                            }
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.sentSanctionDate
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'calendar-days']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <Form.Control
                            id="sentSanctionDateFilter"
                            type="date"
                            {...register('sentSanctionDate')}
                        />
                    </InputGroup>
                </Col>
                <Col sm="4" md="3" lg="2">
                    <Button variant="primary" type="submit">
                        {t(Lang.FILTER)}
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

export default SanctionsFilter;
