import { NavDropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as Lang from '../../i18n/constants';

const LanguageSelector = (): JSX.Element => {
    const { t, i18n } = useTranslation();

    const handleSelect = (eventKey: string | null) => {
        if (eventKey) i18n.changeLanguage(eventKey);
    };

    const ENElement: JSX.Element = (
        <>
            <img src="/img/flags/en.png" />
            <span className="ms-2 d-none d-md-inline">{t(Lang.ENGLISH)}</span>
        </>
    );
    const ESElement: JSX.Element = (
        <>
            <img src="/img/flags/es.png" />
            <span className="ms-2 d-none d-md-inline">{t(Lang.SPANISH)}</span>
        </>
    );

    const VAElement: JSX.Element = (
        <>
            <img src="/img/flags/vl.png" />
            <span className="ms-2 d-none d-md-inline">{t(Lang.VALENCIAN)}</span>
        </>
    );

    const currentLanguage: string = i18n.language.substring(0, 2);

    const otherLanguages = ['es', 'en', 'va'].filter(
        (language) => language !== currentLanguage
    );

    const elementByLanguage = (language: string): JSX.Element => {
        let resultElement: JSX.Element = ENElement;

        switch (language) {
            case 'es':
                resultElement = ESElement;
                break;
            case 'en':
                resultElement = ENElement;
                break;
            case 'va':
                resultElement = VAElement;
                break;
        }

        return resultElement;
    };

    return (
        <NavDropdown
            className="me-3 pt-2"
            title={elementByLanguage(currentLanguage)}
            onSelect={handleSelect}
        >
            {otherLanguages.map((language, index) => {
                return (
                    <NavDropdown.Item key={index} eventKey={language}>
                        {elementByLanguage(language)}
                    </NavDropdown.Item>
                );
            })}
        </NavDropdown>
    );
};

export default LanguageSelector;
