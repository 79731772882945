import { Table, Row, Col, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as Lang from '../../../i18n/constants';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from '../../../components/Loader';
import ISensor from '../../../services/Sensor/Sensor.interface';
import * as Sensor from '../../../services/Sensor/Sensor';
import ISensorType from '../../../services/SensorType/SensorType.interface';

interface SensorsTableProps {
    sensors: ISensor[];
    handleEditSensor: (event: React.MouseEvent<HTMLButtonElement>) => void;
    reloadSensors: () => void;
    sensorsType: ISensorType[];
}

const SensorsTable = ({
    sensors,
    handleEditSensor,
    reloadSensors,
    sensorsType,
}: SensorsTableProps): JSX.Element => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState('');

    const handleDeleteSensor = async (
        event: React.MouseEvent<HTMLButtonElement>
    ): Promise<void> => {
        event.preventDefault();
        const button: HTMLButtonElement = event.currentTarget;
        const id = button.value;

        setLoading(id);

        const deletedSensor = await Sensor.deleteSensor(id);

        if (deletedSensor) {
            toast.success(
                t(Lang.SENSOR_DELETED_SUCCESSFULLY, {
                    sensor: deletedSensor,
                })
            );
            reloadSensors();
        } else
            toast.error(
                t(Lang.ERROR_DELETING_SENSOR, {
                    sensor: deletedSensor,
                })
            );

        setLoading('');
    };

    return (
        <Row>
            <Col>
                <Table striped hover variant="primary">
                    <thead>
                        <tr>
                            <th></th>
                            <th>{t(Lang.NAME)}</th>
                            <th>{t(Lang.SENSOR_TYPE)}</th>
                            <th>{t(Lang.LOCATION)}</th>
                            <th>{t(Lang.COORDINATES)}</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {sensors.map((sensor: ISensor) => {
                            const sensorType = sensorsType.filter(
                                (sensorType) => sensorType._id === sensor.type
                            );
                            return (
                                <tr key={sensor._id}>
                                    <td></td>
                                    <td>{sensor.name}</td>
                                    <td>
                                        {sensorType.length
                                            ? sensorType[0].name
                                            : t(Lang.NONE)}
                                    </td>
                                    <td>{sensor.location}</td>
                                    <td>{sensor.coordinates}</td>
                                    <td>
                                        <Button
                                            variant="link"
                                            value={sensor._id}
                                            onClick={handleEditSensor}
                                        >
                                            <FontAwesomeIcon
                                                icon={['fas', 'pen-to-square']}
                                                size="2x"
                                                fixedWidth
                                            />
                                        </Button>
                                    </td>
                                    <td>
                                        <Button
                                            variant="link"
                                            className="btn-delete"
                                            value={sensor._id}
                                            onClick={handleDeleteSensor}
                                        >
                                            {loading === sensor._id ? (
                                                <Loader
                                                    loading={true}
                                                    size={25}
                                                    color="#dc3545"
                                                />
                                            ) : (
                                                <FontAwesomeIcon
                                                    icon={['fas', 'trash-can']}
                                                    size="2x"
                                                    fixedWidth
                                                />
                                            )}
                                        </Button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </Col>
        </Row>
    );
};

export default SensorsTable;
