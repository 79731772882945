import {
    Modal,
    Form,
    InputGroup,
    FormControl,
    Tooltip,
    OverlayTrigger,
    Button,
    Row,
    Col,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import * as Lang from '../../../i18n/constants';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { useEffect, useState, useRef } from 'react';
import ErrorField from '../../../components/form/ErrorField';
import Loader from '../../../components/Loader';
import { toast } from 'react-toastify';
import IPlate from '../../../services/Plate/Plate.interface';
import { Days } from '../../../services/Plate/Day.interface';
import ITimeZone from '../../../services/Plate/TimeZone.interface';
import ICalendar from '../../../services/Plate/Calendar.interface';
import * as Plate from '../../../services/Plate/Plate';
import IList from '../../../services/List/List.interface';
import TimePicker from 'react-time-picker';
import moment from 'moment';
import * as List from '../../../services/List/List';

interface Day {
    name: string;
    active: boolean;
    timeZone1: ITimeZone;
    timeZone2: ITimeZone;
}

interface PlateForm {
    _id?: string;
    plate: string;
    description?: string;
    observations?: string;
    brand?: string;
    model?: string;
    color?: string;
    owner?: string;
    days?: Day[];
    dateOfExpiry?: string;
    list: string;
}

interface WhitePlateModalProps {
    show: boolean;
    handleClose: () => void;
    reloadPlates: () => void;
    plate?: IPlate | false;
    list: IList;
}

const WhitePlateModal = ({
    show,
    handleClose,
    reloadPlates,
    plate,
    list,
}: WhitePlateModalProps): JSX.Element => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const inputPlateRef = useRef<HTMLInputElement | null>(null);
    const [changedPlate, setChangedPlate] = useState<IPlate>();

    const langObj: { [key: string]: string } = {};

    for (const key of Object.keys(Lang)) langObj[key] = key;

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        control,
        setValue,
    } = useForm<PlateForm>();

    const { fields } = useFieldArray({
        control,
        name: 'days',
    });

    const inputPlateRegister = register('plate', {
        required: t(Lang.REQUIRED_FIELD, {
            field: t(Lang.PLATE),
        }) as string,
        maxLength: {
            value: 32,
            message: t(Lang.MAX_LENGTH_FIELD, {
                field: t(Lang.PLATE),
                value: 32,
            }),
        },
    });

    const handleCreatePlate = async (data: PlateForm): Promise<void> => {
        setLoading(true);

        const calendar: ICalendar = {
            days: [],
            dateOfExpiry: moment(data.dateOfExpiry, 'YYYY-MM-DD').toDate(),
        };

        if (data.days)
            for (const day of data.days)
                if (day.active)
                    calendar.days.push({
                        day: day.name as Days,
                        timeZone1: {
                            start: day.timeZone1.start
                                ? moment(day.timeZone1.start, 'H:mm').format(
                                      'HH:mm'
                                  )
                                : '',
                            end: day.timeZone1.end
                                ? moment(day.timeZone1.end, 'H:mm').format(
                                      'HH:mm'
                                  )
                                : '',
                        },
                        timeZone2: {
                            start: day.timeZone2.start
                                ? moment(day.timeZone2.start, 'H:mm').format(
                                      'HH:MM'
                                  )
                                : '',
                            end: day.timeZone2.end
                                ? moment(day.timeZone2.end, 'H:mm').format(
                                      'HH:mm'
                                  )
                                : '',
                        },
                    });

        const plate: IPlate = {
            plate: data.plate,
            description: data.description,
            observations: data.observations,
            brand: data.brand,
            model: data.model,
            color: data.color,
            owner: data.owner,
            calendar,
            list: list._id,
        };

        let sure = true;

        if (changedPlate && changedPlate.list) {
            const listChanged = await List.getList(changedPlate.list);

            if (listChanged) {
                sure = confirm(
                    t(Lang.CONFIRM_SAVE_PLATE, { oldList: listChanged, list })
                );
            }
        }

        if (sure) {
            const createdPlate = await Plate.createPlate(plate);

            if (createdPlate) {
                toast.success(
                    t(Lang.PLATE_ADDED_SUCCESSFULLY, { plate: createdPlate })
                );
                reloadPlates();
            } else toast.error(t(Lang.ERROR_ADDING_PLATE, { plate }));
        }

        setLoading(false);

        handleClose();
    };

    const handleUpdatePlate = async (data: PlateForm): Promise<void> => {
        setLoading(true);

        const calendar: ICalendar = {
            days: [],
            dateOfExpiry: moment(data.dateOfExpiry, 'YYYY-MM-DD').toDate(),
        };

        if (data.days)
            for (const day of data.days) {
                if (day.active)
                    calendar.days.push({
                        day: day.name as Days,
                        timeZone1: {
                            start: day.timeZone1.start
                                ? moment(day.timeZone1.start, 'H:mm').format(
                                      'HH:mm'
                                  )
                                : '',
                            end: day.timeZone1.end
                                ? moment(day.timeZone1.end, 'H:mm').format(
                                      'HH:mm'
                                  )
                                : '',
                        },
                        timeZone2: {
                            start: day.timeZone2.start
                                ? moment(day.timeZone2.start, 'H:mm').format(
                                      'HH:mm'
                                  )
                                : '',
                            end: day.timeZone2.end
                                ? moment(day.timeZone2.end, 'H:mm').format(
                                      'HH:mm'
                                  )
                                : '',
                        },
                    });
            }

        const plate: IPlate = {
            _id: data._id,
            plate: data.plate,
            description: data.description,
            observations: data.observations,
            brand: data.brand,
            model: data.model,
            color: data.color,
            owner: data.owner,
            calendar,
            list: list._id,
        };

        let sure = true;

        if (changedPlate && changedPlate.list) {
            const listChanged = await List.getList(changedPlate.list);

            if (listChanged) {
                sure = confirm(
                    t(Lang.CONFIRM_SAVE_PLATE, { oldList: listChanged, list })
                );
            }
        }

        if (sure) {
            const updatedPlate = await Plate.updatePlate(plate);

            if (updatedPlate) {
                toast.success(
                    t(Lang.PLATE_UPDATED_SUCCESSFULLY, { plate: updatedPlate })
                );
                reloadPlates();
            } else toast.error(t(Lang.ERROR_UPDATING_PLATE, { plate }));
        }
        handleClose();
        setLoading(false);
    };

    const handleChangePlate = async () => {
        if (inputPlateRef.current) {
            const daysForm: Day[] = [];

            const platesFound = await Plate.getPlates(
                undefined,
                inputPlateRef.current.value
            );

            if (platesFound && platesFound.length) {
                const plateFound = platesFound[0];

                let loadedPlate: PlateForm = {
                    _id: plateFound._id,
                    plate: plateFound.plate,
                    description: plateFound.description,
                    observations: plateFound.observations,
                    brand: plateFound.brand,
                    model: plateFound.model,
                    color: plateFound.color,
                    owner: plateFound.owner,
                    dateOfExpiry: moment(
                        plateFound.calendar?.dateOfExpiry
                    ).format('YYYY-MM-DD'),
                    list: plateFound.list as string,
                };

                Object.keys(Days).map((item) => {
                    if (plateFound.calendar) {
                        const found = plateFound.calendar.days.find(
                            (day) => day.day == item
                        );

                        if (found) {
                            daysForm.push({
                                name: item,
                                active: true,
                                timeZone1: {
                                    start: found.timeZone1?.start as string,
                                    end: found.timeZone1?.end as string,
                                },
                                timeZone2: {
                                    start: found.timeZone2?.start as string,
                                    end: found.timeZone2?.end as string,
                                },
                            });
                        } else
                            daysForm.push({
                                name: item,
                                active: false,
                                timeZone1: { start: '', end: '' },
                                timeZone2: { start: '', end: '' },
                            });
                    }
                });

                loadedPlate = { ...loadedPlate, days: daysForm };

                reset(loadedPlate);

                setChangedPlate(plateFound);
            }
        }
    };

    const handleApplyAllDays = () => {
        const timeZone1: ITimeZone = { start: '', end: '' };
        const timeZone2: ITimeZone = { start: '', end: '' };

        for (const field of fields) {
            if (
                (field.timeZone1.start && field.timeZone1.end) ||
                (field.timeZone2.start && field.timeZone2.end)
            ) {
                if (field.timeZone1.start && field.timeZone1.end) {
                    timeZone1.start = field.timeZone1.start;
                    timeZone1.end = field.timeZone1.end;
                }

                if (field.timeZone2.start && field.timeZone2.end) {
                    timeZone2.start = field.timeZone2.start;
                    timeZone2.end = field.timeZone2.end;
                }

                break;
            }
        }

        for (const [index, field] of Object.entries(fields))
            setValue(`days.${parseInt(index)}`, {
                name: field.name,
                active: true,
                timeZone1: { start: timeZone1.start, end: timeZone1.end },
                timeZone2: { start: timeZone2.start, end: timeZone2.end },
            });
    };

    useEffect(() => {
        const daysForm: Day[] = [];

        if (plate) {
            let loadedPlate: PlateForm = {
                _id: plate._id,
                plate: plate.plate,
                description: plate.description,
                observations: plate.observations,
                brand: plate.brand,
                model: plate.model,
                color: plate.color,
                owner: plate.owner,
                dateOfExpiry: plate.calendar?.dateOfExpiry
                    ? moment(plate.calendar?.dateOfExpiry).format('YYYY-MM-DD')
                    : '',
                list: plate.list as string,
            };

            Object.keys(Days).map((item) => {
                if (plate.calendar) {
                    const found = plate.calendar.days.find(
                        (day) => day.day == item
                    );

                    if (found) {
                        daysForm.push({
                            name: item,
                            active: true,
                            timeZone1: {
                                start: found.timeZone1?.start as string,
                                end: found.timeZone1?.end as string,
                            },
                            timeZone2: {
                                start: found.timeZone2?.start as string,
                                end: found.timeZone2?.end as string,
                            },
                        });
                    } else
                        daysForm.push({
                            name: item,
                            active: false,
                            timeZone1: { start: '', end: '' },
                            timeZone2: { start: '', end: '' },
                        });
                }
            });

            loadedPlate = { ...loadedPlate, days: daysForm };

            reset(loadedPlate);
        } else {
            let loadedPlate: PlateForm = {
                plate: '',
                description: '',
                observations: '',
                brand: '',
                model: '',
                color: '',
                owner: '',
                dateOfExpiry: moment(new Date()).format('YYYY-MM-DD'),
                list: list._id as string,
            };

            Object.keys(Days).map((item) => {
                daysForm.push({
                    name: item,
                    active: false,
                    timeZone1: { start: '', end: '' },
                    timeZone2: { start: '', end: '' },
                });
            });

            loadedPlate = { ...loadedPlate, days: daysForm };

            reset(loadedPlate);
        }
    }, [plate]);

    return (
        <Modal show={show} onHide={handleClose}>
            <Form
                onSubmit={
                    plate || changedPlate
                        ? handleSubmit(handleUpdatePlate)
                        : handleSubmit(handleCreatePlate)
                }
            >
                <Modal.Header closeButton className="border-0">
                    <Modal.Title>
                        {plate
                            ? t(Lang.EDIT_PLATE, { plate })
                            : changedPlate
                            ? t(Lang.EDIT_PLATE, { plate: changedPlate })
                            : t(Lang.ADD_PLATE)}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        <small>{t(Lang.REQUIRED_MESSAGE)}</small>
                    </p>
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.PLATE)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.plate
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'font']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <FormControl
                            id="plate"
                            type="text"
                            maxLength={32}
                            placeholder={'* ' + t(Lang.PLATE)}
                            aria-label={t(Lang.PLATE)}
                            aria-describedby={t(Lang.PLATE)}
                            className={
                                errors.plate ? 'border-2 border-danger' : ''
                            }
                            {...inputPlateRegister}
                            onChange={(e) => {
                                inputPlateRegister.onChange(e);
                                handleChangePlate();
                            }}
                            ref={(e: unknown) => {
                                inputPlateRegister.ref(e);
                                inputPlateRef.current = e as HTMLInputElement;
                            }}
                        />
                    </InputGroup>
                    {errors.plate && (
                        <ErrorField message={errors.plate.message} />
                    )}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.DESCRIPTION)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.description
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'align-left']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <FormControl
                            id="description"
                            as="textarea"
                            maxLength={512}
                            rows={2}
                            placeholder={t(Lang.DESCRIPTION)}
                            aria-label={t(Lang.DESCRIPTION)}
                            aria-describedby={t(Lang.DESCRIPTION)}
                            className={
                                errors.description
                                    ? 'border-2 border-danger'
                                    : ''
                            }
                            {...register('description', {
                                maxLength: {
                                    value: 512,
                                    message: t(Lang.MAX_LENGTH_FIELD, {
                                        field: t(Lang.DESCRIPTION),
                                        value: 512,
                                    }),
                                },
                            })}
                        />
                    </InputGroup>
                    {errors.description && (
                        <ErrorField message={errors.description.message} />
                    )}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.OBSERVATIONS)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.observations
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'align-left']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <FormControl
                            id="observations"
                            as="textarea"
                            maxLength={512}
                            rows={3}
                            placeholder={t(Lang.OBSERVATIONS)}
                            aria-label={t(Lang.OBSERVATIONS)}
                            aria-describedby={t(Lang.OBSERVATIONS)}
                            className={
                                errors.observations
                                    ? 'border-2 border-danger'
                                    : ''
                            }
                            {...register('observations', {
                                maxLength: {
                                    value: 512,
                                    message: t(Lang.MAX_LENGTH_FIELD, {
                                        field: t(Lang.OBSERVATIONS),
                                        value: 512,
                                    }),
                                },
                            })}
                        />
                    </InputGroup>
                    {errors.observations && (
                        <ErrorField message={errors.observations.message} />
                    )}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.BRAND)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.brand
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'industry']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <FormControl
                            id="brand"
                            type="text"
                            maxLength={128}
                            placeholder={t(Lang.BRAND)}
                            aria-label={t(Lang.BRAND)}
                            aria-describedby={t(Lang.BRAND)}
                            className={
                                errors.brand ? 'border-2 border-danger' : ''
                            }
                            {...register('brand', {
                                maxLength: {
                                    value: 128,
                                    message: t(Lang.MAX_LENGTH_FIELD, {
                                        field: t(Lang.BRAND),
                                        value: 128,
                                    }),
                                },
                            })}
                        />
                    </InputGroup>
                    {errors.brand && (
                        <ErrorField message={errors.brand.message} />
                    )}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.MODEL)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.model
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'cubes']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <FormControl
                            id="model"
                            type="text"
                            maxLength={128}
                            placeholder={t(Lang.MODEL)}
                            aria-label={t(Lang.MODEL)}
                            aria-describedby={t(Lang.MODEL)}
                            className={
                                errors.model ? 'border-2 border-danger' : ''
                            }
                            {...register('model', {
                                maxLength: {
                                    value: 128,
                                    message: t(Lang.MAX_LENGTH_FIELD, {
                                        field: t(Lang.MODEL),
                                        value: 128,
                                    }),
                                },
                            })}
                        />
                    </InputGroup>
                    {errors.model && (
                        <ErrorField message={errors.model.message} />
                    )}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.COLOR)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.color
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'palette']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <FormControl
                            id="color"
                            type="text"
                            maxLength={64}
                            placeholder={t(Lang.COLOR)}
                            aria-label={t(Lang.COLOR)}
                            aria-describedby={t(Lang.COLOR)}
                            className={
                                errors.color ? 'border-2 border-danger' : ''
                            }
                            {...register('color', {
                                maxLength: {
                                    value: 64,
                                    message: t(Lang.MAX_LENGTH_FIELD, {
                                        field: t(Lang.COLOR),
                                        value: 64,
                                    }),
                                },
                            })}
                        />
                    </InputGroup>
                    {errors.color && (
                        <ErrorField message={errors.color.message} />
                    )}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.OWNER)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.owner
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'user-tie']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <FormControl
                            id="owner"
                            type="text"
                            maxLength={256}
                            placeholder={t(Lang.OWNER)}
                            aria-label={t(Lang.OWNER)}
                            aria-describedby={t(Lang.OWNER)}
                            className={
                                errors.owner ? 'border-2 border-danger' : ''
                            }
                            {...register('owner', {
                                maxLength: {
                                    value: 256,
                                    message: t(Lang.MAX_LENGTH_FIELD, {
                                        field: t(Lang.OWNER),
                                        value: 256,
                                    }),
                                },
                            })}
                        />
                    </InputGroup>
                    {errors.owner && (
                        <ErrorField message={errors.owner.message} />
                    )}
                    <Form.Label>{t(Lang.CALENDAR)}</Form.Label>
                    <Row className="mb-3">
                        <Col>
                            <Button
                                variant="primary"
                                onClick={handleApplyAllDays}
                            >
                                {t(Lang.APPLY_ALL)}
                            </Button>
                        </Col>
                    </Row>
                    {fields.map((item, index) => {
                        return (
                            <div key={index} className="mb-3 card p-3">
                                <Row className="mb-1">
                                    <Col>
                                        <Form.Check
                                            id={item.name}
                                            type="checkbox"
                                            label={t(
                                                langObj[item.name.toUpperCase()]
                                            )}
                                            {...register(
                                                `days.${index}.active`
                                            )}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-1">
                                    <Col className="d-flex align-items-center">
                                        {t(Lang.AM)}
                                    </Col>
                                    <Col>
                                        <Controller
                                            name={`days.${index}.timeZone1.start`}
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                                <TimePicker
                                                    value={field.value}
                                                    format="HH:mm"
                                                    onChange={(e) =>
                                                        field.onChange(e)
                                                    }
                                                />
                                            )}
                                        />
                                    </Col>
                                    <Col>
                                        <Controller
                                            name={`days.${index}.timeZone1.end`}
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                                <TimePicker
                                                    value={field.value}
                                                    format="HH:mm"
                                                    onChange={(e) =>
                                                        field.onChange(e)
                                                    }
                                                />
                                            )}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="d-flex align-items-center">
                                        {t(Lang.PM)}
                                    </Col>
                                    <Col>
                                        <Controller
                                            name={`days.${index}.timeZone2.start`}
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                                <TimePicker
                                                    value={field.value}
                                                    format="HH:mm"
                                                    onChange={(e) =>
                                                        field.onChange(e)
                                                    }
                                                />
                                            )}
                                        />
                                    </Col>
                                    <Col>
                                        <Controller
                                            name={`days.${index}.timeZone2.end`}
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                                <TimePicker
                                                    value={field.value}
                                                    format="HH:mm"
                                                    onChange={(e) =>
                                                        field.onChange(e)
                                                    }
                                                />
                                            )}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        );
                    })}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                                <Tooltip>{t(Lang.DATE_OF_EXPIRY)}</Tooltip>
                            }
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.dateOfExpiry
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'calendar-days']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <FormControl
                            id="dateOfExpiry"
                            type="date"
                            aria-label={t(Lang.DATE_OF_EXPIRY)}
                            aria-describedby={t(Lang.DATE_OF_EXPIRY)}
                            className={
                                errors.dateOfExpiry
                                    ? 'border-2 border-danger'
                                    : ''
                            }
                            {...register('dateOfExpiry')}
                        />
                    </InputGroup>
                    {errors.dateOfExpiry && (
                        <ErrorField message={errors.dateOfExpiry.message} />
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t(Lang.CANCEL)}
                    </Button>
                    <Button
                        type="submit"
                        variant="primary"
                        id="_id"
                        className="btn-modal"
                        {...register('_id')}
                    >
                        {loading ? (
                            <Loader
                                loading={loading}
                                size={25}
                                color="#FFFFFF"
                            />
                        ) : (
                            t(Lang.SAVE)
                        )}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default WhitePlateModal;
