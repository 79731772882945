import IZone from '../Zone/Zone.interface';
import ICamera, { DeviceStatus } from '../Camera/Camera.interface';

export enum Protocol {
    DTPM = 'DTPM',
    HTTP = 'HTTP',
}

type ModelList = {
    models: string[];
};

export const Manufacturers: { [key: string]: ModelList } = {
    MP_Electronics: {
        models: ['DLE16.16C'],
    },
};

export type Line = {
    _id: string;
    text: string;
};

export enum PanelType {
    detection = 'detection',
    restriction = 'restriction',
}

export default interface IPanel {
    _id?: string;
    name: string;
    description?: string;
    location: string;
    coordinates: string;
    manufacturer: string;
    model: string;
    nLines: number;
    lines?: Line[];
    host: string;
    port: number;
    protocol: Protocol;
    zone: IZone['_id'];
    camera?: ICamera['_id'];
    alive?: Date;
    type: PanelType;
    status?: DeviceStatus;
    createdAt?: Date;
    updatedAt?: Date;
}
