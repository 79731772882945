import { Form, Row, Col, Button } from 'react-bootstrap';
import { useRef } from 'react';
import IList from '../../../services/List/List.interface';
import IPlate from '../../../services/Plate/Plate.interface';
import ICalendar from '../../../services/Plate/Calendar.interface';
import IDay, { Days } from '../../../services/Plate/Day.interface';
import * as Plate from '../../../services/Plate/Plate';
import { useForm } from 'react-hook-form';
import * as Papa from 'papaparse';
import { toast } from 'react-toastify';
import * as Lang from '../../../i18n/constants';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

interface IPlateCSV {
    plate: string;
    description: string;
    observations: string;
    brand: string;
    model: string;
    color: string;
    owner: string;
    Monday_timeZone1_start: string;
    Monday_timeZone1_end: string;
    Monday_timeZone2_start: string;
    Monday_timeZone2_end: string;
    Tuesday_timeZone1_start: string;
    Tuesday_timeZone1_end: string;
    Tuesday_timeZone2_start: string;
    Tuesday_timeZone2_end: string;
    Wednesday_timeZone1_start: string;
    Wednesday_timeZone1_end: string;
    Wednesday_timeZone2_start: string;
    Wednesday_timeZone2_end: string;
    Thursday_timeZone1_start: string;
    Thursday_timeZone1_end: string;
    Thursday_timeZone2_start: string;
    Thursday_timeZone2_end: string;
    Friday_timeZone1_start: string;
    Friday_timeZone1_end: string;
    Friday_timeZone2_start: string;
    Friday_timeZone2_end: string;
    Saturday_timeZone1_start: string;
    Saturday_timeZone1_end: string;
    Saturday_timeZone2_start: string;
    Saturday_timeZone2_end: string;
    Sunday_timeZone1_start: string;
    Sunday_timeZone1_end: string;
    Sunday_timeZone2_start: string;
    Sunday_timeZone2_end: string;
    dateOfExpiry: string;
}

interface ICSV extends IPlateCSV {
    [key: string]: string;
}

interface WhiteImportCSVProps {
    list: IList;
    reloadPlates: () => void;
}

interface WhiteImportForm {
    file: FileList;
    replace: boolean;
}

const WhiteImportCSV = ({ list, reloadPlates }: WhiteImportCSVProps) => {
    const { t } = useTranslation();
    const inputRef = useRef<HTMLInputElement | null>(null);
    const labelRef = useRef<HTMLLabelElement>(null);

    const { register, handleSubmit } = useForm<WhiteImportForm>();

    const inputFileRegister = register('file', {
        required: true,
    });

    const fileReader = new FileReader();

    const handleUpload = () => {
        inputRef.current?.click();
    };

    const handleChange = () => {
        if (inputRef.current && labelRef.current)
            labelRef.current.innerText = inputRef.current.value
                ? inputRef.current.value
                : t(Lang.IMPORT_CSV_FILE);
    };

    const handleInsertPlates = async (data: WhiteImportForm): Promise<void> => {
        if (data.file.length) {
            fileReader.onload = async (event) => {
                if (event.target) {
                    const csvOutput = event.target.result as string;
                    const dataParsed = Papa.parse<ICSV>(csvOutput, {
                        header: true,
                        delimiter: ';',
                    });
                    if (list._id) {
                        let isWhiteList = true;
                        const plates = dataParsed.data.map((plateParsed) => {
                            const days: IDay[] = [];
                            Object.keys(Days).map((day) => {
                                if (
                                    plateParsed.plate &&
                                    (plateParsed[`${day}_timeZone1_start`] ===
                                        undefined ||
                                        plateParsed[`${day}_timeZone1_end`] ===
                                            undefined ||
                                        plateParsed[
                                            `${day}_timeZone2_start`
                                        ] === undefined ||
                                        plateParsed[`${day}_timeZone2_end`] ===
                                            undefined)
                                )
                                    isWhiteList = false;

                                if (
                                    (plateParsed[`${day}_timeZone1_start`] !==
                                        '' &&
                                        plateParsed[`${day}_timeZone1_end`] !==
                                            '') ||
                                    (plateParsed[`${day}_timeZone2_start`] !==
                                        '' &&
                                        plateParsed[`${day}_timeZone2_end`])
                                ) {
                                    days.push({
                                        day: day as Days,
                                        timeZone1: {
                                            start: plateParsed[
                                                `${day}_timeZone1_start`
                                            ],
                                            end: plateParsed[
                                                `${day}_timeZone1_end`
                                            ],
                                        },
                                        timeZone2: {
                                            start: plateParsed[
                                                `${day}_timeZone2_start`
                                            ],
                                            end: plateParsed[
                                                `${day}_timeZone2_end`
                                            ],
                                        },
                                    });
                                }
                            });

                            const calendar: ICalendar = {
                                days,
                                dateOfExpiry: plateParsed.dateOfExpiry
                                    ? moment(
                                          plateParsed.dateOfExpiry,
                                          'DD/MM/YYYY'
                                      ).toDate()
                                    : undefined,
                            };

                            const plate: IPlate = {
                                plate: plateParsed.plate,
                                description: plateParsed.description,
                                observations: plateParsed.observations,
                                brand: plateParsed.brand,
                                model: plateParsed.model,
                                color: plateParsed.color,
                                owner: plateParsed.owner,
                                calendar,
                                list: list._id,
                            };

                            return plate;
                        });

                        if (isWhiteList) {
                            const insertedPlates = await Plate.insertPlates(
                                plates,
                                list._id,
                                data.replace
                            );
                            if (insertedPlates)
                                toast.success(
                                    t(Lang.SUCCESSFULLY_IMPORTED_CSV_FILE)
                                );
                            else toast.error(t(Lang.ERROR_IMPORTING_CSV_FILE));

                            reloadPlates();
                        } else
                            toast.error(
                                t(Lang.IMPORTED_CSV_FILE_IS_NOT_A_WHITELIST)
                            );
                    }
                }
            };

            fileReader.readAsText(data.file[0]);
        }
    };

    return (
        <Form onSubmit={handleSubmit(handleInsertPlates)}>
            <Form.Group
                controlId="importCSV"
                as={Row}
                className="d-flex align-items-center"
            >
                <Col xs="12" lg="9" className="d-flex align-items-center mb-3">
                    <Form.Label
                        className="my-auto border py-1 px-3 rounded-start text-truncate col-3 col-sm-6"
                        ref={labelRef}
                    >
                        {t(Lang.IMPORT_CSV_FILE)}
                    </Form.Label>
                    <Form.Control
                        type="file"
                        className="d-none"
                        accept=".csv"
                        {...inputFileRegister}
                        onChange={(e) => {
                            inputFileRegister.onChange(e);
                            handleChange();
                        }}
                        ref={(e: unknown) => {
                            inputFileRegister.ref(e);
                            inputRef.current = e as HTMLInputElement;
                        }}
                    />
                    <div className="border-2 border-end">
                        <Button
                            variant="primary"
                            onClick={handleUpload}
                            className="rounded-0 py-1 px-3"
                        >
                            {t(Lang.CHOOSE_FILE)}
                        </Button>
                    </div>
                    <div>
                        <Button
                            type="submit"
                            variant="primary"
                            className="rounded-0 rounded-end py-1 px-3"
                        >
                            {t(Lang.SEND)}
                        </Button>
                    </div>
                </Col>
                <Col xs="12" lg="3" className="d-flex align-items-center">
                    <Form.Check
                        type="checkbox"
                        label="Reemplazar"
                        {...register('replace')}
                    />
                </Col>
            </Form.Group>
        </Form>
    );
};

export default WhiteImportCSV;
