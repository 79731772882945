import IZone from '../Zone/Zone.interface';

export enum TypeList {
    black = 'black',
    white = 'white',
}

export default interface IList {
    _id?: string;
    name: string;
    description?: string;
    type: TypeList;
    zones: [IZone['_id']];
    enabled: boolean;
    webAlert: boolean;
    emailAlert: boolean;
    emailRecipients?: string[];
}
