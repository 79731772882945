import { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import Header from '../../components/header/Header';
import Menu from '../../components/menu/Menu';
import IUser from '../../services/User/User.interface';
import Map from './components/Map';
import * as Camera from '../../services/Camera/Camera';
import ICamera, { DeviceStatus } from '../../services/Camera/Camera.interface';
//import * as Raspberry from '../../services/Raspberry/Raspberry';
//import IRaspberry from '../../services/Raspberry/Raspberry.interface';
import * as Panel from '../../services/Panel/Panel';
import IPanel from '../../services/Panel/Panel.interface';
import { Device, DeviceType } from './components/Map';
import { LatLngTuple } from 'leaflet';
import { useTranslation } from 'react-i18next';
import * as Lang from '../../i18n/constants';
import * as Option from '../../services/Option/Option';
import * as Zone from '../../services/Zone/Zone';
import IZone from '../../services/Zone/Zone.interface';
import ISensor from '../../services/Sensor/Sensor.interface';
import * as Sensor from '../../services/Sensor/Sensor';

interface HomeLayoutProps {
    user?: IUser;
}

const HomeLayout = ({ user }: HomeLayoutProps): JSX.Element => {
    const [devices, setDevices] = useState<Device[]>([]);
    const [mapCoordinates, setMapCoordinates] = useState('');
    const [zones, setZones] = useState<IZone[]>([]);
    const [fetchDevices, setFetchDevices] = useState(true);

    const { t } = useTranslation();

    const triggerDeviceFetch = () => setFetchDevices((t) => !t);

    useEffect(() => {
        const newDevices: Device[] = [];

        const loadDevices = async () => {
            const cameras = await Camera.getCameras();

            if (cameras) {
                cameras.map((camera: ICamera) => {
                    const coordinates: string[] = camera.coordinates.split(',');

                    if (coordinates.length < 2) return;

                    const position: LatLngTuple = [
                        Number(coordinates[0]),
                        Number(coordinates[1]),
                    ];

                    const device: Device = {
                        name: camera.name,
                        description: camera.description,
                        position,
                        type: DeviceType.camera,
                        status: camera.status as DeviceStatus,
                    };

                    newDevices.push(device);
                });
            }

            /*const raspberries = await Raspberry.getRaspberries();

            if (raspberries) {
                raspberries.map((raspberry: IRaspberry) => {
                    const coordinates: string[] =
                        raspberry.coordinates.split(',');

                    if (coordinates.length < 2) return;

                    const position: LatLngTuple = [
                        Number(coordinates[0]),
                        Number(coordinates[1]),
                    ];

                    const device: Device = {
                        name: raspberry.name,
                        description: raspberry.description,
                        position,
                        type: DeviceType.raspberry,
                    };

                    newDevices.push(device);
                });
            }*/

            const panels = await Panel.getPanels();

            if (panels) {
                panels.map((panel: IPanel) => {
                    const coordinates: string[] = panel.coordinates.split(',');

                    if (coordinates.length < 2) return;

                    const position: LatLngTuple = [
                        Number(coordinates[0]),
                        Number(coordinates[1]),
                    ];

                    const device: Device = {
                        name: panel.name,
                        description: panel.description,
                        position,
                        type: DeviceType.panel,
                        status: panel.status as DeviceStatus,
                    };

                    newDevices.push(device);
                });
            }

            const sensors = await Sensor.getSensors();

            if (sensors) {
                sensors.map((sensor: ISensor) => {
                    const coordinates: string[] = sensor.coordinates.split(',');

                    if (coordinates.length < 2) return;

                    const position: LatLngTuple = [
                        Number(coordinates[0]),
                        Number(coordinates[1]),
                    ];

                    const device: Device = {
                        name: sensor.name,
                        description: sensor.description,
                        position,
                        type: DeviceType.sensor,
                        status: sensor.status as DeviceStatus,
                    };

                    newDevices.push(device);
                });
            }

            setDevices(newDevices);
        };
        loadDevices();
    }, [fetchDevices]);

    useEffect(() => {
        const loadCoordinates = async () => {
            const options = await Option.getOptions();

            if (options) {
                const coordinatesOption = options.filter(
                    (option) => option.name === 'mapCoordinates'
                );

                if (coordinatesOption && coordinatesOption.length)
                    setMapCoordinates(coordinatesOption[0].value);
            }
        };
        loadCoordinates();
    }, []);

    useEffect(() => {
        const loadZones = async () => {
            const zones = await Zone.getZones();

            if (zones) setZones(zones);
        };
        loadZones();
    }, []);

    useEffect(() => {
        setInterval(() => {
            triggerDeviceFetch();
        }, 10000);
    }, []);

    return (
        <>
            <Header user={user} />
            <Row className="h-100">
                <Col xs="3" xl="2" className="p-0 sidebar d-none d-xl-block">
                    {user ? (
                        <Menu currentPage={t(Lang.HOME)} user={user} />
                    ) : (
                        ''
                    )}
                </Col>
                <Col xs="12" xl="10" className="px-3">
                    {mapCoordinates && mapCoordinates.length ? (
                        <Map
                            devices={devices}
                            coordinates={mapCoordinates}
                            zones={zones}
                        />
                    ) : (
                        ''
                    )}
                </Col>
            </Row>
        </>
    );
};

export default HomeLayout;
