import React, { useEffect, useRef, useState } from 'react';
import Header from '../../components/header/Header';
import Menu from '../../components/menu/Menu';
import IUser, { Role } from '../../services/User/User.interface';
import * as User from '../../services/User/User';
import { Row, Col, Button, Form, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as Lang from '../../i18n/constants';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as Auth from '../../services/Authentication';
import ErrorField from '../../components/form/ErrorField';
import Loader from '../../components/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface ProfileForm extends IUser {
    repeatPassword: string;
    oldPassword: string;
    password: string;
}

interface ProfileLayoutProps {
    user?: IUser;
}

const ProfileLayout = ({ user }: ProfileLayoutProps) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const oldPasswordRef = useRef<HTMLInputElement | null>(null);
    const newPasswordRef = useRef<HTMLInputElement | null>(null);
    const repeatPasswordRef = useRef<HTMLInputElement | null>(null);

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        reset,
        setError,
    } = useForm<ProfileForm>();

    const oldPasswordRegister = register('oldPassword', {
        minLength: {
            value: 8,
            message: t(Lang.MIN_LENGTH_FIELD, {
                field: t(Lang.OLD_PASSWORD),
                value: 8,
            }),
        },
        maxLength: {
            value: 256,
            message: t(Lang.MAX_LENGTH_FIELD, {
                field: t(Lang.MAX_LENGTH_FIELD),
                value: 256,
            }),
        },
    });

    const newPasswordRegister = register('password', {
        minLength: {
            value: 8,
            message: t(Lang.MIN_LENGTH_FIELD, {
                field: t(Lang.NEW_PASSWORD),
                value: 8,
            }),
        },
        maxLength: {
            value: 256,
            message: t(Lang.MAX_LENGTH_FIELD, {
                field: t(Lang.NEW_PASSWORD),
                value: 256,
            }),
        },
    });

    const repeatPasswordRegister = register('repeatPassword', {
        required: user
            ? false
            : (t(Lang.REQUIRED_FIELD, {
                  field: t(Lang.REPEAT_PASSWORD),
              }) as string),
        validate: (value) => {
            if (value.length || password.current)
                return (
                    value === password.current ||
                    (t(Lang.PASSWORDS_DO_NOT_MATCH) as string)
                );
            else return true;
        },
    });

    const password = useRef({});

    password.current = watch('password', '');

    const handleSubmitProfile = async (data: ProfileForm): Promise<void> => {
        setLoading(true);

        const submitUser: IUser = {
            _id: data._id,
            username: data.username,
            email: data.email,
            firstname: data.firstname,
            lastname: data.lastname,
            role: data.role,
            phone: data.phone,
            twoFactorEnabled: data.twoFactorEnabled,
        };

        if (data.password || data.oldPassword) {
            if (data.oldPassword && data.password) {
                const passwordIsCorrect = await Auth.login(
                    data.username,
                    data.oldPassword
                );

                if (passwordIsCorrect.status === 401) {
                    setLoading(false);
                    return setError('oldPassword', {
                        message: t(Lang.INVALID_USERNAME_OR_PASSWORD),
                    });
                }

                if (passwordIsCorrect.status === 200)
                    submitUser.password = data.password;
            } else {
                if (data.password && !data.oldPassword) {
                    setLoading(false);
                    return setError('oldPassword', {
                        message: t(Lang.REQUIRED_FIELD, {
                            field: t(Lang.OLD_PASSWORD),
                        }),
                    });
                }

                if (data.oldPassword && !data.password) {
                    setLoading(false);
                    return setError('password', {
                        message: t(Lang.REQUIRED_FIELD, {
                            field: t(Lang.NEW_PASSWORD),
                        }),
                    });
                }
            }
        }

        const updatedUser = await User.updateUser(submitUser);

        if (updatedUser)
            toast.success(t(Lang.USER_UPDATED_SUCCESSFULLY, { user }));
        else toast.error(t(Lang.ERROR_UPDATING_USER, { user }));

        setLoading(false);
    };

    const handleHideShowPassword = (
        event: React.MouseEvent<HTMLButtonElement>
    ): void => {
        if (
            event.currentTarget.className === 'p-0 text-secondary btn btn-link'
        ) {
            event.currentTarget.className = 'p-0 text-primary btn btn-link';

            if (
                event.currentTarget.value === 'oldPassword' &&
                oldPasswordRef.current
            )
                oldPasswordRef.current.type = 'text';

            if (
                event.currentTarget.value === 'newPassword' &&
                newPasswordRef.current
            )
                newPasswordRef.current.type = 'text';

            if (
                event.currentTarget.value === 'repeatPassword' &&
                repeatPasswordRef.current
            )
                repeatPasswordRef.current.type = 'text';
        } else {
            event.currentTarget.className = 'p-0 text-secondary btn btn-link';

            if (
                event.currentTarget.value === 'oldPassword' &&
                oldPasswordRef.current
            )
                oldPasswordRef.current.type = 'password';

            if (
                event.currentTarget.value === 'newPassword' &&
                newPasswordRef.current
            )
                newPasswordRef.current.type = 'password';

            if (
                event.currentTarget.value === 'repeatPassword' &&
                repeatPasswordRef.current
            )
                repeatPasswordRef.current.type = 'password';
        }
    };

    useEffect(() => {
        if (user) reset(user);
        else
            reset({
                _id: '',
                username: '',
                password: '',
                oldPassword: '',
                repeatPassword: '',
                email: '',
                firstname: '',
                lastname: '',
                role: Role.admin,
                phone: '',
            });
    }, [user]);

    return (
        <>
            <Header user={user} />
            <Row className="h-100">
                <Col xs="3" xl="2" className="p-0 sidebar d-none d-lg-block">
                    {user ? (
                        <Menu currentPage={t(Lang.PROFILE)} user={user} />
                    ) : (
                        ''
                    )}
                </Col>
                <Col xs="9" xl="10" className="p-3 pe-xl-4">
                    <Row>
                        <Col xs="12" xl={{ span: 6, offset: 3 }}>
                            <Form onSubmit={handleSubmit(handleSubmitProfile)}>
                                <h3 className="mb-5">
                                    {t(Lang.MODIFY_PERSONAL_DATA)}
                                </h3>
                                <Form.Group
                                    className="mb-3"
                                    controlId="username"
                                >
                                    <Form.Label>{t(Lang.USERNAME)}</Form.Label>

                                    <Form.Control
                                        type="text"
                                        maxLength={128}
                                        placeholder={t(Lang.USERNAME)}
                                        aria-label={t(Lang.USERNAME)}
                                        aria-describedby={t(Lang.USERNAME)}
                                        className={
                                            errors.username
                                                ? 'border-2 border-danger'
                                                : ''
                                        }
                                        {...register('username', {
                                            required: t(Lang.REQUIRED_FIELD, {
                                                field: t(Lang.USERNAME),
                                            }) as string,
                                            maxLength: {
                                                value: 128,
                                                message: t(
                                                    Lang.MAX_LENGTH_FIELD,
                                                    {
                                                        field: t(Lang.USERNAME),
                                                        value: 128,
                                                    }
                                                ),
                                            },
                                        })}
                                    />
                                </Form.Group>
                                {errors.username && (
                                    <ErrorField
                                        message={errors.username.message}
                                    />
                                )}
                                <Form.Group
                                    className="mb-3"
                                    controlId="firstname"
                                >
                                    <Form.Label>{t(Lang.FIRSTNAME)}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        maxLength={128}
                                        placeholder={t(Lang.FIRSTNAME)}
                                        aria-label={t(Lang.FIRSTNAME)}
                                        aria-describedby={t(Lang.FIRSTNAME)}
                                        className={
                                            errors.firstname
                                                ? 'border-2 border-danger'
                                                : ''
                                        }
                                        {...register('firstname', {
                                            required: t(Lang.REQUIRED_FIELD, {
                                                field: t(Lang.FIRSTNAME),
                                            }) as string,
                                            maxLength: {
                                                value: 128,
                                                message: t(
                                                    Lang.MAX_LENGTH_FIELD,
                                                    {
                                                        field: t(
                                                            Lang.FIRSTNAME
                                                        ),
                                                        value: 128,
                                                    }
                                                ),
                                            },
                                        })}
                                    />
                                </Form.Group>
                                {errors.firstname && (
                                    <ErrorField
                                        message={errors.firstname.message}
                                    />
                                )}
                                <Form.Group
                                    className="mb-3"
                                    controlId="lastname"
                                >
                                    <Form.Label>{t(Lang.LASTNAME)}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        maxLength={256}
                                        placeholder={t(Lang.LASTNAME)}
                                        aria-label={t(Lang.LASTNAME)}
                                        aria-describedby={t(Lang.LASTNAME)}
                                        className={
                                            errors.lastname
                                                ? 'border-2 border-danger'
                                                : ''
                                        }
                                        {...register('lastname', {
                                            maxLength: {
                                                value: 256,
                                                message: t(
                                                    Lang.MAX_LENGTH_FIELD,
                                                    {
                                                        field: t(Lang.LASTNAME),
                                                        value: 256,
                                                    }
                                                ),
                                            },
                                        })}
                                    />
                                </Form.Group>
                                {errors.lastname && (
                                    <ErrorField
                                        message={errors.lastname.message}
                                    />
                                )}
                                <Form.Group className="mb-3" controlId="email">
                                    <Form.Label>{t(Lang.EMAIL)}</Form.Label>
                                    <Form.Control
                                        type="email"
                                        maxLength={256}
                                        placeholder={t(Lang.EMAIL)}
                                        aria-label={t(Lang.EMAIL)}
                                        aria-describedby={t(Lang.EMAIL)}
                                        className={
                                            errors.email
                                                ? 'border-2 border-danger'
                                                : ''
                                        }
                                        {...register('email', {
                                            required: t(Lang.REQUIRED_FIELD, {
                                                field: t(Lang.EMAIL),
                                            }) as string,
                                            maxLength: {
                                                value: 256,
                                                message: t(
                                                    Lang.MAX_LENGTH_FIELD,
                                                    {
                                                        field: t(Lang.EMAIL),
                                                        value: 256,
                                                    }
                                                ),
                                            },
                                        })}
                                    />
                                </Form.Group>
                                {errors.email && (
                                    <ErrorField
                                        message={errors.email.message}
                                    />
                                )}
                                <Form.Group className="mb-3" controlId="role">
                                    <Form.Label>{t(Lang.ROLE)}</Form.Label>
                                    <Form.Select
                                        className={
                                            errors.role
                                                ? 'border-2 border-danger'
                                                : ''
                                        }
                                        {...register('role')}
                                    >
                                        {Object.keys(Role).map((role) => {
                                            return (
                                                <option key={role} value={role}>
                                                    {role}
                                                </option>
                                            );
                                        })}
                                    </Form.Select>
                                </Form.Group>
                                {errors.role && (
                                    <ErrorField message={errors.role.message} />
                                )}
                                <Form.Group className="mb-3" controlId="phone">
                                    <Form.Label>{t(Lang.PHONE)}</Form.Label>
                                    <Form.Control
                                        type="tel"
                                        maxLength={64}
                                        placeholder={t(Lang.PHONE)}
                                        aria-label={t(Lang.PHONE)}
                                        aria-describedby={t(Lang.PHONE)}
                                        className={
                                            errors.phone
                                                ? 'border-2 border-danger'
                                                : ''
                                        }
                                        {...register('phone', {
                                            maxLength: {
                                                value: 64,
                                                message: t(
                                                    Lang.MAX_LENGTH_FIELD,
                                                    {
                                                        field: t(Lang.PHONE),
                                                        value: 64,
                                                    }
                                                ),
                                            },
                                        })}
                                    />
                                </Form.Group>
                                {errors.phone && (
                                    <ErrorField
                                        message={errors.phone.message}
                                    />
                                )}
                                <Form.Group
                                    className="mb-3"
                                    controlId="twoFactorEnabled"
                                >
                                    <Form.Label>
                                        {t(Lang.TWO_FACTOR_ENABLED)}
                                    </Form.Label>
                                    <Form.Check
                                        type="switch"
                                        {...register('twoFactorEnabled')}
                                    />
                                </Form.Group>
                                <h3 className="my-5">
                                    {t(Lang.CHANGE_PASSWORD)}
                                </h3>
                                <Form.Group
                                    className="mb-3"
                                    controlId="oldPassword"
                                >
                                    <Form.Label>
                                        {t(Lang.OLD_PASSWORD)}
                                    </Form.Label>
                                    <InputGroup>
                                        <Form.Control
                                            type="password"
                                            minLength={8}
                                            maxLength={256}
                                            placeholder={t(Lang.OLD_PASSWORD)}
                                            aria-label={t(Lang.OLD_PASSWORD)}
                                            aria-describedby={t(
                                                Lang.OLD_PASSWORD
                                            )}
                                            className={
                                                errors.oldPassword
                                                    ? 'border-2 border-danger'
                                                    : ''
                                            }
                                            {...oldPasswordRegister}
                                            ref={(e: unknown) => {
                                                oldPasswordRegister.ref(e);
                                                oldPasswordRef.current =
                                                    e as HTMLInputElement;
                                            }}
                                        />
                                        <InputGroup.Text>
                                            <Button
                                                variant="link"
                                                className="p-0 text-secondary"
                                                value="oldPassword"
                                                onClick={handleHideShowPassword}
                                            >
                                                <FontAwesomeIcon
                                                    icon={['fas', 'eye']}
                                                    viewBox="0 0 512 512"
                                                />
                                            </Button>
                                        </InputGroup.Text>
                                    </InputGroup>
                                </Form.Group>
                                {errors.oldPassword && (
                                    <ErrorField
                                        message={errors.oldPassword.message}
                                    />
                                )}
                                <Form.Group
                                    className="mb-3"
                                    controlId="password"
                                >
                                    <Form.Label>
                                        {t(Lang.NEW_PASSWORD)}
                                    </Form.Label>
                                    <InputGroup>
                                        <Form.Control
                                            type="password"
                                            minLength={8}
                                            maxLength={256}
                                            placeholder={t(Lang.NEW_PASSWORD)}
                                            aria-label={t(Lang.NEW_PASSWORD)}
                                            aria-describedby={t(
                                                Lang.NEW_PASSWORD
                                            )}
                                            className={
                                                errors.password
                                                    ? 'border-2 border-danger'
                                                    : ''
                                            }
                                            {...newPasswordRegister}
                                            ref={(e: unknown) => {
                                                newPasswordRegister.ref(e);
                                                newPasswordRef.current =
                                                    e as HTMLInputElement;
                                            }}
                                        />
                                        <InputGroup.Text>
                                            <Button
                                                variant="link"
                                                className="p-0 text-secondary"
                                                value="newPassword"
                                                onClick={handleHideShowPassword}
                                            >
                                                <FontAwesomeIcon
                                                    icon={['fas', 'eye']}
                                                    viewBox="0 0 512 512"
                                                />
                                            </Button>
                                        </InputGroup.Text>
                                    </InputGroup>
                                </Form.Group>
                                {errors.password && (
                                    <ErrorField
                                        message={errors.password.message}
                                    />
                                )}
                                <Form.Group
                                    className="mb-5"
                                    controlId="repeatPassword"
                                >
                                    <Form.Label>
                                        {t(Lang.REPEAT_PASSWORD)}
                                    </Form.Label>
                                    <InputGroup>
                                        <Form.Control
                                            type="password"
                                            minLength={8}
                                            maxLength={256}
                                            placeholder={
                                                user
                                                    ? t(Lang.REPEAT_PASSWORD)
                                                    : '* ' +
                                                      t(Lang.REPEAT_PASSWORD)
                                            }
                                            aria-label={t(Lang.REPEAT_PASSWORD)}
                                            aria-describedby={t(
                                                Lang.REPEAT_PASSWORD
                                            )}
                                            className={
                                                errors.repeatPassword
                                                    ? 'border-2 border-danger'
                                                    : ''
                                            }
                                            {...repeatPasswordRegister}
                                            ref={(e: unknown) => {
                                                repeatPasswordRegister.ref(e);
                                                repeatPasswordRef.current =
                                                    e as HTMLInputElement;
                                            }}
                                        />
                                        <InputGroup.Text>
                                            <Button
                                                variant="link"
                                                className="p-0 text-secondary"
                                                value="repeatPassword"
                                                onClick={handleHideShowPassword}
                                            >
                                                <FontAwesomeIcon
                                                    icon={['fas', 'eye']}
                                                    viewBox="0 0 512 512"
                                                />
                                            </Button>
                                        </InputGroup.Text>
                                    </InputGroup>
                                </Form.Group>
                                {errors.repeatPassword && (
                                    <ErrorField
                                        message={errors.repeatPassword.message}
                                    />
                                )}
                                <Button
                                    id="_id"
                                    type="submit"
                                    variant="primary"
                                    className="btn-modal"
                                    {...register('_id')}
                                >
                                    {loading ? (
                                        <Loader
                                            loading={loading}
                                            size={25}
                                            color="#FFFFFF"
                                        />
                                    ) : (
                                        t(Lang.SAVE)
                                    )}
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};

export default ProfileLayout;
