import { Row, Col, Button, Card, FormCheck } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import * as Lang from '../../i18n/constants';
import Loader from '../Loader';
import React, { useState } from 'react';
import IList, { TypeList } from '../../services/List/List.interface';
import * as List from '../../services/List/List';
import { Link } from 'react-router-dom';
import * as URL from '../../routes/URL';

interface ListElementProps {
    list: IList;
    handleEditList: (event: React.MouseEvent<HTMLButtonElement>) => void;
    reloadLists: () => void;
}

const ListElement = ({
    list,
    handleEditList,
    reloadLists,
}: ListElementProps): JSX.Element => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const handleDeleteList = async (): Promise<void> => {
        if (list._id) {
            setLoading(true);

            const deletedList = await List.deleteList(list._id);

            if (deletedList) {
                if (list.type === TypeList.white)
                    toast.success(
                        t(Lang.WHITE_LIST_DELETED_SUCCESSFULLY, {
                            list: deletedList,
                        })
                    );
                else
                    toast.success(
                        t(Lang.BLACK_LIST_DELETED_SUCCESSFULLY, {
                            list: deletedList,
                        })
                    );
                reloadLists();
            } else if (list.type === TypeList.white)
                toast.error(t(Lang.ERROR_DELETING_WHITE_LIST, { list }));
            else toast.error(t(Lang.ERROR_DELETING_BLACK_LIST, { list }));

            setLoading(false);
        }
    };

    const handleChange = async () => {
        const listUpdated = await List.updateList({
            ...list,
            enabled: !list.enabled,
        });

        if (listUpdated) {
            if (list.type === TypeList.white)
                toast.success(
                    t(Lang.WHITE_LIST_UPDATED_SUCCESSFULLY, {
                        list: listUpdated,
                    })
                );
            else
                toast.success(
                    t(Lang.BLACK_LIST_UPDATED_SUCCESSFULLY, {
                        list: listUpdated,
                    })
                );
            reloadLists();
        } else if (list.type === TypeList.white)
            toast.error(t(Lang.ERROR_UPDATING_WHITE_LIST, { list }));
        else toast.error(t(Lang.ERROR_UPDATING_BLACK_LIST, { list }));
    };

    return (
        <Row>
            <Col>
                <Card className="shadow border-0">
                    <Link
                        to={
                            list.type === TypeList.white
                                ? `${URL.WHITE_LISTS}/${list._id}`
                                : `${URL.BLACK_LISTS}/${list._id}`
                        }
                        className="text-decoration-none"
                    >
                        <Card.Body>
                            <Card.Title className="text-dark">
                                {list.name}
                            </Card.Title>
                            <Card.Subtitle className="text-muted">
                                {list.description}
                            </Card.Subtitle>
                        </Card.Body>
                    </Link>
                    <Card.Body className="border-2 border-top">
                        <Row>
                            <Col className="d-flex align-items-center" xs="6">
                                <FormCheck
                                    id={`enabled${list._id}`}
                                    type="switch"
                                    value={list._id}
                                    checked={list.enabled}
                                    onChange={handleChange}
                                    label={
                                        list.enabled
                                            ? t(Lang.ENABLED)
                                            : t(Lang.DISABLED)
                                    }
                                />
                            </Col>
                            <Col className="text-center" xs="3">
                                <Button
                                    variant="link"
                                    value={list._id}
                                    onClick={handleEditList}
                                >
                                    <FontAwesomeIcon
                                        icon={['fas', 'pen-to-square']}
                                        size="2x"
                                        fixedWidth
                                    />
                                </Button>
                            </Col>
                            <Col className="text-center" xs="3">
                                <Button
                                    variant="link"
                                    className="btn-delete"
                                    value={list._id}
                                    onClick={handleDeleteList}
                                >
                                    {loading ? (
                                        <Loader
                                            loading={true}
                                            size={25}
                                            color="#dc3545"
                                        />
                                    ) : (
                                        <FontAwesomeIcon
                                            icon={['fas', 'trash-can']}
                                            size="2x"
                                            fixedWidth
                                        />
                                    )}
                                </Button>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
};

export default ListElement;
