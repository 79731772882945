import { Table, Row, Col, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as Lang from '../../../i18n/constants';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from '../../../components/Loader';
import IOption from '../../../services/Option/Option.interface';
import * as Option from '../../../services/Option/Option';

interface SettingsTableProps {
    options: IOption[];
    handleEditOption: (event: React.MouseEvent<HTMLButtonElement>) => void;
    reloadOptions: () => void;
}

const SettingsTable = ({
    options,
    handleEditOption,
    reloadOptions,
}: SettingsTableProps): JSX.Element => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState('');

    const handleDeleteOption = async (
        event: React.MouseEvent<HTMLButtonElement>
    ): Promise<void> => {
        event.preventDefault();
        const button: HTMLButtonElement = event.currentTarget;
        const id = button.value;

        setLoading(id);

        const deletedOption = await Option.deleteOption(id);

        if (deletedOption) {
            setLoading('');
            toast.success(t(Lang.DESCRIPTION_DELETED_SUCCESSFULLY));
            reloadOptions();
        } else {
            setLoading('');
            toast.error(t(Lang.ERROR_DELETING_DESCRIPTION));
        }
    };

    return (
        <Row>
            <Col>
                <Table responsive striped hover variant="primary">
                    <thead>
                        <tr>
                            <th></th>
                            <th>{t(Lang.DESCRIPTION)}</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {options.map((option: IOption) => {
                            return (
                                <tr key={option._id}>
                                    <td></td>
                                    <td>{option.value}</td>
                                    <td>
                                        <Button
                                            variant="link"
                                            value={option._id}
                                            onClick={handleEditOption}
                                        >
                                            <FontAwesomeIcon
                                                icon={['fas', 'pen-to-square']}
                                                size="2x"
                                                fixedWidth
                                            />
                                        </Button>
                                    </td>
                                    <td>
                                        <Button
                                            variant="link"
                                            className="btn-delete"
                                            value={option._id}
                                            onClick={handleDeleteOption}
                                        >
                                            {loading === option._id ? (
                                                <Loader
                                                    loading={true}
                                                    size={25}
                                                    color="#dc3545"
                                                />
                                            ) : (
                                                <FontAwesomeIcon
                                                    icon={['fas', 'trash-can']}
                                                    size="2x"
                                                    fixedWidth
                                                />
                                            )}
                                        </Button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </Col>
        </Row>
    );
};

export default SettingsTable;
