import axios, { AxiosResponse } from 'axios';
import * as Auth from '../Authentication';
import baseURL from '../BaseURL';
import IEvent from './Event.interface';
import { EventsFilterForm } from '../../pages/Events/components/EventsFilter';

const apiURL = `${baseURL}/events`;

export const createEvent = async (event: IEvent): Promise<IEvent | false> => {
    try {
        const response: AxiosResponse = await axios.post(apiURL, event, {
            headers: Auth.authHeader(),
        });

        if (response.data) {
            const createdEvent: IEvent = response.data;
            return createdEvent;
        } else return false;
    } catch (error) {
        console.error(error);
        return false;
    }
};

export const getEvents = async (
    filter?: EventsFilterForm
): Promise<IEvent[] | false> => {
    try {
        const response: AxiosResponse = await axios.get(apiURL, {
            headers: Auth.authHeader(),
            params: filter,
        });

        if (response.data) {
            const events: IEvent[] = response.data;
            return events;
        } else return false;
    } catch (error) {
        console.error(error);
        return false;
    }
};

export const getEventsCount = async (
    filter?: EventsFilterForm
): Promise<number | false> => {
    try {
        const response: AxiosResponse = await axios.get(`${apiURL}`, {
            headers: Auth.authHeader(),
            params: { ...filter, count: true },
        });

        if (response.data) {
            const totalEvents: number = response.data;
            return totalEvents;
        } else return false;
    } catch (error) {
        console.error(error);
        return false;
    }
};

export const getEvent = async (id: string): Promise<IEvent | false> => {
    try {
        const response: AxiosResponse = await axios.get(`${apiURL}/${id}`, {
            headers: Auth.authHeader(),
        });

        if (response.data) {
            const event: IEvent = response.data;
            return event;
        } else return false;
    } catch (error) {
        console.error(error);
        return false;
    }
};

export const updateEvent = async (event: IEvent): Promise<IEvent | false> => {
    try {
        const response: AxiosResponse = await axios.put(
            `${apiURL}/${event._id}`,
            event,
            { headers: Auth.authHeader() }
        );

        if (response.data) {
            const updatedEvent: IEvent = response.data;
            return updatedEvent;
        } else return false;
    } catch (error) {
        console.error(error);
        return false;
    }
};

export const deleteEvent = async (id: string): Promise<IEvent | false> => {
    try {
        const response: AxiosResponse = await axios.delete(`${apiURL}/${id}`, {
            headers: Auth.authHeader(),
        });

        if (response.data) {
            const deletedEvent: IEvent = response.data;
            return deletedEvent;
        } else return false;
    } catch (error) {
        console.error(error);
        return false;
    }
};
