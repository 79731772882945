const config = {
    frontend: {
        protocol: process.env.REACT_APP_ZBE_FRONTEND_PROTOCOL || 'http',
        host: process.env.REACT_APP_ZBE_FRONTEND_HOST || '127.0.0.1',
        port: process.env.REACT_APP_ZBE_FRONTEND_PORT || 8001,
    },
    backend: {
        protocol: process.env.REACT_APP_ZBE_BACKEND_PROTOCOL || 'http',
        host: process.env.REACT_APP_ZBE_API_HOST || '127.0.0.1',
        port: process.env.REACT_APP_ZBE_API_PORT || 8000,
    },
    version: process.env.REACT_APP_VERSION || '0.5.3',
};

export default config;
