import React, { useEffect, useState } from 'react';
import Header from '../../components/header/Header';
import Menu from '../../components/menu/Menu';
import IUser, { Role } from '../../services/User/User.interface';
import { Row, Col, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as Lang from '../../i18n/constants';
import Loader from '../../components/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import * as URL from '../../routes/URL';
import ISensorType from '../../services/SensorType/SensorType.interface';
import * as SensorType from '../../services/SensorType/SensorType';
import SensorTypeModal from './components/SensorTypeModal';
import SensorsTypeTable from './components/SensorsTypeTable';

interface SensorsTypeLayoutProps {
    user?: IUser;
}

const SensorsTypeLayout = ({ user }: SensorsTypeLayoutProps): JSX.Element => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [sensorsType, setSensorsType] = useState<ISensorType[]>([]);
    const [showSensorTypeModal, setShowSensorTypeModal] = useState(false);
    const [sensorTypeModal, setSensorTypeModal] = useState<
        ISensorType | false
    >();
    const [fetchSensorsType, setFetchSensorsType] = useState(true);

    const triggerSensorTypeFetch = () => setFetchSensorsType((t) => !t);

    const handleOpenSensorTypeModal = (
        event: React.MouseEvent<HTMLButtonElement>
    ): void => {
        const button: HTMLButtonElement = event.currentTarget;

        const id = button.value;

        if (id) {
            const sensorType = sensorsType.find(
                (sensorType) => sensorType._id === id
            );
            setSensorTypeModal(sensorType);
        } else setSensorTypeModal(undefined);

        setShowSensorTypeModal(true);
    };

    const handleCloseSensorTypeModal = (): void => {
        setSensorTypeModal(false);
        setShowSensorTypeModal(false);
    };

    useEffect(() => {
        if (user?.role !== Role.admin) navigate(URL.BASE);
    }, []);

    useEffect(() => {
        const load = async () => {
            const sensorsType = await SensorType.getSensorTypes();

            if (sensorsType) setSensorsType(sensorsType);
            setLoading(false);
        };

        load();
    }, [fetchSensorsType]);

    return (
        <>
            <Header user={user} />
            <Row className="h-100">
                <Col xs="3" xl="2" className="p-0 sidebar d-none d-xl-block">
                    {user ? (
                        <Menu currentPage={t(Lang.SENSOR_TYPES)} user={user} />
                    ) : (
                        ''
                    )}
                </Col>
                <Col xs="12" xl="10" className="p-3 pe-xl-4">
                    <Row>
                        <Col>
                            <Button
                                variant="link"
                                className="btn-add"
                                onClick={handleOpenSensorTypeModal}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'plus']}
                                    size="2x"
                                    fixedWidth
                                />
                            </Button>
                            <SensorTypeModal
                                show={showSensorTypeModal}
                                handleClose={handleCloseSensorTypeModal}
                                reloadSensorsType={triggerSensorTypeFetch}
                                sensorType={sensorTypeModal}
                            />
                        </Col>
                    </Row>
                    {sensorsType.length ? (
                        <Row>
                            <Col>
                                <SensorsTypeTable
                                    sensorsType={sensorsType}
                                    handleEditSensorsType={
                                        handleOpenSensorTypeModal
                                    }
                                    reloadSensorsType={triggerSensorTypeFetch}
                                />
                            </Col>
                        </Row>
                    ) : (
                        <Loader loading={loading} size={100} color="#0d6efd" />
                    )}
                </Col>
            </Row>
        </>
    );
};

export default SensorsTypeLayout;
