import { useEffect, useState } from 'react';
import Header from '../../components/header/Header';
import Menu from '../../components/menu/Menu';
import IUser, { Role } from '../../services/User/User.interface';
import { Row, Col, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as Lang from '../../i18n/constants';
import Loader from '../../components/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Zone from '../../services/Zone/Zone';
import IZone from '../../services/Zone/Zone.interface';
import ZoneModal from './components/ZoneModal';
import ZonesTable from './components/ZonesTable';
import { useNavigate } from 'react-router-dom';
import * as URL from '../../routes/URL';

interface ZonesLayoutProps {
    user?: IUser;
}

const ZonesLayout = ({ user }: ZonesLayoutProps): JSX.Element => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [zones, setZones] = useState<IZone[]>([]);
    const [loading, setLoading] = useState(true);
    const [showZoneModal, setShowZoneModal] = useState(false);
    const [zoneModal, setZoneModal] = useState<IZone | false>();

    const [fetchZones, setFetchZones] = useState(true);

    const triggerZoneFetch = () => setFetchZones((t) => !t);

    const handleOpenZoneModal = (
        event: React.MouseEvent<HTMLButtonElement>
    ): void => {
        const button: HTMLButtonElement = event.currentTarget;

        const id = button.value;

        if (id) {
            const zone = zones.find((zone) => zone._id === id);
            setZoneModal(zone);
        } else setZoneModal(undefined);

        setShowZoneModal(true);
    };

    const handleCloseZoneModal = (): void => {
        setZoneModal(false);
        setShowZoneModal(false);
    };

    useEffect(() => {
        if (user?.role !== Role.admin) navigate(URL.BASE);
    }, []);

    useEffect(() => {
        const loadZones = async () => {
            const zones = await Zone.getZones();

            if (zones) {
                setZones(zones);
                setLoading(false);
            }
        };

        loadZones();
    }, [fetchZones]);

    return (
        <>
            <Header user={user} />
            <Row className="h-100">
                <Col xs="3" xl="2" className="p-0 sidebar d-none d-xl-block">
                    {user ? (
                        <Menu currentPage={t(Lang.ZONES)} user={user} />
                    ) : (
                        ''
                    )}
                </Col>
                <Col xs="12" xl="10" className="p-3 pe-xl-4">
                    <Row>
                        <Col>
                            <Button
                                variant="link"
                                className="btn-add"
                                onClick={handleOpenZoneModal}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'plus']}
                                    size="2x"
                                    fixedWidth
                                />
                            </Button>
                            <ZoneModal
                                show={showZoneModal}
                                handleClose={handleCloseZoneModal}
                                reloadZones={triggerZoneFetch}
                                zone={zoneModal}
                            />
                        </Col>
                    </Row>
                    {zones.length ? (
                        <Row>
                            <Col>
                                <ZonesTable
                                    zones={zones}
                                    handleEditZone={handleOpenZoneModal}
                                    reloadZones={triggerZoneFetch}
                                />
                            </Col>
                        </Row>
                    ) : (
                        <Loader loading={loading} size={100} color="#0d6efd" />
                    )}
                </Col>
            </Row>
        </>
    );
};

export default ZonesLayout;
