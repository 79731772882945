import {
    Modal,
    Form,
    InputGroup,
    FormControl,
    Tooltip,
    OverlayTrigger,
    Button,
    Row,
    Col,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import * as Lang from '../../../i18n/constants';
import { useForm, useFieldArray } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import ErrorField from '../../../components/form/ErrorField';
import Loader from '../../../components/Loader';
import { toast } from 'react-toastify';
import ISensorType, {
    ISensorParameter,
} from '../../../services/SensorType/SensorType.interface';
import * as SensorType from '../../../services/SensorType/SensorType';

interface ParameterNames {
    value: string;
}

interface ParameterUnits {
    value: string;
}

interface SensorTypeModalForm {
    _id?: string;
    name: string;
    description?: string;
    parameterNames: ParameterNames[];
    parameterUnits: ParameterUnits[];
}

interface SensorTypeModalProps {
    show: boolean;
    handleClose: () => void;
    reloadSensorsType: () => void;
    sensorType?: ISensorType | false;
}

const SensorTypeModal = ({
    show,
    handleClose,
    reloadSensorsType,
    sensorType,
}: SensorTypeModalProps): JSX.Element => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        reset,
    } = useForm<SensorTypeModalForm>();

    const {
        fields: fieldsNames,
        append: appendName,
        remove: removeName,
    } = useFieldArray({
        control,
        name: 'parameterNames',
    });

    const {
        fields: fieldsUnits,
        append: appendUnit,
        remove: removeUnit,
    } = useFieldArray({
        control,
        name: 'parameterUnits',
    });

    const handleCreateSensorType = async (
        data: SensorTypeModalForm
    ): Promise<void> => {
        setLoading(true);

        const parameters: ISensorParameter[] = [];

        for (let i = 0; i < data.parameterNames.length; i++) {
            parameters.push({
                name: data.parameterNames[i].value,
                units: data.parameterUnits[i].value,
            });
        }

        const sensorType: ISensorType = {
            name: data.name,
            description: data.description,
            parameters,
        };

        const createdSensorType = await SensorType.createSensorType(sensorType);

        if (createdSensorType) {
            toast.success(
                t(Lang.SENSOR_TYPE_CREATED_SUCCESSFULLY, {
                    sensorType,
                })
            );
            reloadSensorsType();
        } else
            toast.error(
                t(Lang.ERROR_CREATING_SENSOR_TYPE, {
                    sensorType,
                })
            );

        setLoading(false);

        handleClose();
    };

    const handleUpdateSensorType = async (
        data: SensorTypeModalForm
    ): Promise<void> => {
        setLoading(true);

        const parameters: ISensorParameter[] = [];

        for (let i = 0; i < data.parameterNames.length; i++) {
            parameters.push({
                name: data.parameterNames[i].value,
                units: data.parameterUnits[i].value,
            });
        }

        const sensorType: ISensorType = {
            _id: data._id,
            name: data.name,
            description: data.description,
            parameters,
        };

        const updatedSensorType = await SensorType.updateSensorType(sensorType);

        if (updatedSensorType) {
            toast.success(
                t(Lang.SENSOR_TYPE_UPDATED_SUCCESSFULLY, {
                    sensorType,
                })
            );
            reloadSensorsType();
        } else
            toast.error(
                t(Lang.ERROR_UPDATING_SENSOR_TYPE, {
                    sensorType,
                })
            );

        setLoading(false);

        handleClose();
    };

    const handleAddParameter = () => {
        appendName({ value: '' });
        appendUnit({ value: '' });
    };

    const handleRemoveParameter = () => {
        if (fieldsNames.length > 1 && fieldsUnits.length > 1) {
            removeName(fieldsNames.length - 1);
            removeUnit(fieldsUnits.length - 1);
        }
    };

    useEffect(() => {
        if (sensorType) {
            const parameterNames: ParameterNames[] = [];
            const parameterUnits: ParameterUnits[] = [];

            for (const parameter of sensorType.parameters) {
                parameterNames.push({ value: parameter.name });
                parameterUnits.push({ value: parameter.units });
            }

            reset({
                _id: sensorType._id,
                name: sensorType.name,
                description: sensorType.description,
                parameterNames,
                parameterUnits,
            });
        } else {
            reset({
                name: '',
                description: '',
                parameterNames: [{ value: '' }],
                parameterUnits: [{ value: '' }],
            });
        }
    }, [sensorType]);

    return (
        <Modal show={show} onHide={handleClose}>
            <Form
                onSubmit={
                    sensorType
                        ? handleSubmit(handleUpdateSensorType)
                        : handleSubmit(handleCreateSensorType)
                }
            >
                <Modal.Header closeButton className="border-0">
                    <Modal.Title>
                        {sensorType
                            ? t(Lang.EDIT_SENSOR_TYPE, {
                                  sensorType,
                              })
                            : t(Lang.ADD_SENSOR_TYPE)}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        <small>{t(Lang.REQUIRED_MESSAGE)}</small>
                    </p>
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.NAME)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.name
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'font']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <FormControl
                            id="name"
                            type="text"
                            maxLength={128}
                            placeholder={'* ' + t(Lang.NAME)}
                            aria-label={t(Lang.NAME)}
                            aria-describedby={t(Lang.NAME)}
                            className={
                                errors.name ? 'border-2 border-danger' : ''
                            }
                            {...register('name', {
                                required: t(Lang.REQUIRED_FIELD, {
                                    field: t(Lang.NAME),
                                }) as string,
                                maxLength: {
                                    value: 128,
                                    message: t(Lang.MAX_LENGTH_FIELD, {
                                        field: t(Lang.NAME),
                                        value: 128,
                                    }),
                                },
                            })}
                        />
                    </InputGroup>
                    {errors.name && (
                        <ErrorField message={errors.name.message} />
                    )}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.DESCRIPTION)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.description
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'align-left']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <FormControl
                            id="description"
                            as="textarea"
                            maxLength={512}
                            rows={5}
                            placeholder={t(Lang.DESCRIPTION)}
                            aria-label={t(Lang.DESCRIPTION)}
                            aria-describedby={t(Lang.DESCRIPTION)}
                            className={
                                errors.description
                                    ? 'border-2 border-danger'
                                    : ''
                            }
                            {...register('description', {
                                maxLength: {
                                    value: 512,
                                    message: t(Lang.MAX_LENGTH_FIELD, {
                                        field: t(Lang.DESCRIPTION),
                                        value: 512,
                                    }),
                                },
                            })}
                        />
                    </InputGroup>
                    {errors.description && (
                        <ErrorField message={errors.description.message} />
                    )}
                    <Row className="mb-3">
                        <Col>
                            <Button
                                variant="primary"
                                onClick={handleAddParameter}
                                className="w-100"
                            >
                                {t(Lang.ADD_PARAMETER)}
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {fieldsNames.map((fieldName, index) => (
                                <InputGroup key={fieldName.id} className="mb-3">
                                    <OverlayTrigger
                                        placement="right"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={
                                            <Tooltip>{t(Lang.NAME)}</Tooltip>
                                        }
                                    >
                                        <InputGroup.Text
                                            className={`text-white ${
                                                errors.parameterNames &&
                                                errors.parameterNames[index]
                                                    ? 'bg-danger border-2 border-danger'
                                                    : 'bg-primary'
                                            }`}
                                        >
                                            <FontAwesomeIcon
                                                icon={['fas', 'font']}
                                                viewBox="0 0 512 512"
                                                fixedWidth
                                            />
                                        </InputGroup.Text>
                                    </OverlayTrigger>
                                    <FormControl
                                        id={fieldName.id}
                                        type="text"
                                        maxLength={128}
                                        placeholder={`${t(Lang.NAME)} ${
                                            index + 1
                                        }`}
                                        aria-label={t(Lang.NAME)}
                                        aria-describedby={t(Lang.NAME)}
                                        className={
                                            errors.parameterNames &&
                                            errors.parameterNames[index]
                                                ? 'border-2 border-danger'
                                                : ''
                                        }
                                        {...register(
                                            `parameterNames.${index}.value`,
                                            {
                                                maxLength: {
                                                    value: 128,
                                                    message: t(
                                                        Lang.MAX_LENGTH_FIELD,
                                                        {
                                                            field: t(Lang.NAME),
                                                            value: 128,
                                                        }
                                                    ),
                                                },
                                            }
                                        )}
                                    />
                                </InputGroup>
                            ))}
                        </Col>
                        <Col>
                            {fieldsUnits.map((fieldUnit, index) => (
                                <InputGroup key={fieldUnit.id} className="mb-3">
                                    <OverlayTrigger
                                        placement="right"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={
                                            <Tooltip>{t(Lang.UNITS)}</Tooltip>
                                        }
                                    >
                                        <InputGroup.Text
                                            className={`text-white ${
                                                errors.parameterUnits &&
                                                errors.parameterUnits[index]
                                                    ? 'bg-danger border-2 border-danger'
                                                    : 'bg-primary'
                                            }`}
                                        >
                                            <FontAwesomeIcon
                                                icon={['fas', 'ruler']}
                                                viewBox="0 0 512 512"
                                                fixedWidth
                                            />
                                        </InputGroup.Text>
                                    </OverlayTrigger>
                                    <FormControl
                                        id={fieldUnit.id}
                                        type="text"
                                        maxLength={128}
                                        placeholder={`${t(Lang.UNITS)} ${
                                            index + 1
                                        }`}
                                        aria-label={t(Lang.UNITS)}
                                        aria-describedby={t(Lang.UNITS)}
                                        className={
                                            errors.parameterUnits &&
                                            errors.parameterUnits[index]
                                                ? 'border-2 border-danger'
                                                : ''
                                        }
                                        {...register(
                                            `parameterUnits.${index}.value`,
                                            {
                                                maxLength: {
                                                    value: 128,
                                                    message: t(
                                                        Lang.MAX_LENGTH_FIELD,
                                                        {
                                                            field: t(
                                                                Lang.UNITS
                                                            ),
                                                            value: 128,
                                                        }
                                                    ),
                                                },
                                            }
                                        )}
                                    />
                                </InputGroup>
                            ))}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button
                                variant="primary"
                                onClick={handleRemoveParameter}
                                className="w-100"
                            >
                                {t(Lang.REMOVE_PARAMETER)}
                            </Button>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t(Lang.CANCEL)}
                    </Button>
                    <Button
                        type="submit"
                        variant="primary"
                        id="_id"
                        className="btn-modal"
                        {...register('_id')}
                    >
                        {loading ? (
                            <Loader
                                loading={loading}
                                size={25}
                                color="#FFFFFF"
                            />
                        ) : (
                            t(Lang.SAVE)
                        )}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default SensorTypeModal;
